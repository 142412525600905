import { createContext, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { format } from 'date-fns'
import SweetAlert from 'react-bootstrap-sweetalert'
import { matchCurrencyToDecimal } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { calculateTotalDuration, ibanValidator } from 'shared/helpers/functions-helpers'
import { closeModal, openModal } from 'shared/helpers/modal-helper'
import { registerEraserHeadProject } from 'local_redux/actions/eraserHeadActions'
import { registerFinishedProject } from 'local_redux/actions/finishedProjectAction'
import { getAllFinishedProjects } from 'local_redux/actions/projectAction'
import { FINISHED_PROJECT_REGISTER_RESET } from 'local_redux/constants/finishedProjectConstants'
import { INVESTMENTS_BY_PROJECT_RESET } from 'local_redux/constants/investmentConstants'

export const LiquidatedProjectContext = createContext()

const styles = {
	buttonStyles: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
}

const useStyles = makeStyles(styles)

export const LiquidatedProjectProvider = ({ children }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const scrollRef = useRef(null)

	const liquidatedProjectInitialState = {
		interiorDesignExpenses: '',
		purchasePrice: '',
		maintenanceExpenses: '',
		acquisitionExpenses: '',
		constructionExpenses: '',
		totalInvestmentBeforeExpenses: '',
		findersManagementFee: '',
		totalInvestment: 0,
		netSellingPrice: 0,
		plusvalia: '',
		totalDuration: (finishDateUpdated, startDateUpdated) =>
			calculateTotalDuration(new Date(finishDateUpdated), new Date(startDateUpdated)),
		marketingExpenses: '',
		salePriceFinished: '',
		liquidityProvider: '',
		benefit: 0,
		reversion: 0,
		coInvestorTIR: 0,
		projectTIR: 0,
		profitMargin: 0,
		eraserHead: '',
		depositDate: new Date(),
		liquidateValidation: false,
	}

	const [projectList, setProjectList] = useState([])
	const [selectedProject, setSelectedProject] = useState(liquidatedProjectInitialState)
	const [investmentsBySelectedProject, setInvestmentsBySelectedProject] = useState([])
	const [errorUsers, setErrorsUsers] = useState(false)
	const [isBenefitComplete, setIsBenefitComplete] = useState('')
	const [alert, setAlert] = useState(null)
	const [liquidatedProjectModal, setLiquidatedProjectModal] = useState(false)
	const [liquidatedProjectModalInfo, setLiquidatedProjectModalInfo] = useState({})
	const [updateProjectModal, setUpdateProjectModal] = useState(false)
	const [updateProjectInfo, setUpdateProjectInfo] = useState({})
	const [liquidateValidationModal, setLiquidateValidationModal] = useState(false)

	const { loadingProjectFinishedList, projectFinishedListData } = useSelector((state) => state.projectFinishedList)
	const {
		successFinishedProjectRegister,
		errorFinishedProjectRegister,
		finishedProjectRegisterData,
		loadingFinishedProjectRegister,
	} = useSelector((state) => state.finishedProjectRegister)
	const { projectUpdateData, successProjectUpdate } = useSelector((state) => state.projectUpdate)

	//Get Finished Project List
	useEffect(() => {
		dispatch(getAllFinishedProjects())
	}, [])

	//Check Required fields and complete with default values
	useEffect(() => {
		let isButtonDisable = investmentsBySelectedProject.map((item) => {
			if (Number(item.benefit) === 0) {
				return true
			} else if (item.liquidationDeleted) {
				return true
			} else {
				return false
			}
		})

		setIsBenefitComplete(isButtonDisable.includes(true))
	}, [investmentsBySelectedProject])
	//Check IBAN number
	useEffect(() => {
		if (errorUsers) {
			setAlert(
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title='Atención'
					onConfirm={() => {
						setAlert(null)
						setErrorsUsers(false)
					}}
					confirmBtnCssClass={classes.buttonStyles}
				>
					<div style={{ textAlign: 'left' }}>
						No se pudo liquidar el proyecto por que algunos usuarios no poseen un IBAN correcto.
					</div>
				</SweetAlert>,
			)
		}
	}, [errorUsers])
	//Update project list after project modified
	useEffect(() => {
		let timeOut
		if (successProjectUpdate) {
			const updateProjectList = projectList.map((item) => {
				if (item.id === projectUpdateData._id) {
					item.projectCost = projectUpdateData.projectCost
					item.totalInvestment = projectUpdateData.totalInvestment
					item.admissibleCoinvestment = projectUpdateData.admissibleCoinvestment
					item.salePriceFinished = matchCurrencyToDecimal(projectUpdateData.projectSaleValueUpdated)
					item.finishDateDossier = projectUpdateData.finishDateDossier
					item.finishDateUpdated = projectUpdateData.finishDateUpdated
					item.startDateDossier = projectUpdateData.startDateDossier
					item.startDateUpdated = projectUpdateData.startDateUpdated
					item.projectSaleValueDossier = projectUpdateData.projectSaleValueDossier
					item.projectSaleValueUpdated = projectUpdateData.projectSaleValueUpdated
				}
				return item
			})
			setProjectList(updateProjectList)

			timeOut = setTimeout(() => {
				// eslint-disable-next-line no-use-before-define
				handleCloseUpdateProject()
			}, 2000)

			return () => clearTimeout(timeOut)
		}
	}, [successProjectUpdate])
	//Open modal after liquidation and update project list
	useEffect(() => {
		if (successFinishedProjectRegister) {
			const updateProjectListData = projectList.filter((item) => item.id !== finishedProjectRegisterData._id)
			setProjectList(updateProjectListData)
			openModal(finishedProjectRegisterData, setLiquidatedProjectModalInfo, setLiquidatedProjectModal)
		}
	}, [successFinishedProjectRegister])
	useEffect(() => {
		return () => dispatch({ type: FINISHED_PROJECT_REGISTER_RESET })
	}, [dispatch])

	//Reset State
	const resetState = () => {
		scrollRef.current?.scrollIntoView(true, { behavior: 'smooth' })
		setSelectedProject(liquidatedProjectInitialState)
		setInvestmentsBySelectedProject([])
		dispatch({ type: FINISHED_PROJECT_REGISTER_RESET })
		dispatch({ type: INVESTMENTS_BY_PROJECT_RESET })
	}
	//Close update project Modal
	const handleCloseUpdateProject = () => {
		closeModal(setUpdateProjectInfo, setUpdateProjectModal)
	}
	//Close liquidation Modal
	const handleCloseLiquidatedProjectModal = () => {
		closeModal(setLiquidatedProjectModalInfo, setLiquidatedProjectModal)
	}
	//Fill the draft project inputs
	const handleLiquidatedProjectOnBlur = (e) => {
		dispatch(registerEraserHeadProject({ projectId: selectedProject._id, [e.target.id]: e.target.value }))
	}

	const handleLiquidateValidation = () => {
		setLiquidateValidationModal(true)
	}
	//Submit finished project
	const handleSubmitData = (e) => {
		e.preventDefault()

		let errors = []
		let requiredFields = investmentsBySelectedProject.map((item) => {
			if (!item.investorProfile.postalCode) {
				item.investorProfile.postalCode = '28001'
			}
			if (!item.investorProfile.province) {
				item.investorProfile.province = 'Madrid'
			}
			if (!item.investorProfile.location) {
				item.investorProfile.location = 'Madrid'
			}
			if (!item.investorProfile.fiscalNationality) {
				item.investorProfile.fiscalNationality = 'España'
			}
			if ((item.liquidation === 'pay' || item.liquidation === 'benefits-only') && item.csvProfileInclude) {
				if (ibanValidator(item.investorProfile.bankAccountNumber)) {
					item.error = true
					errors.push(item)
				} else {
					item.error = false
				}
			}
			return {
				...item,
			}
		})

		setInvestmentsBySelectedProject(requiredFields)
		if (errors.length > 0) {
			return setErrorsUsers(true)
		}
		setLiquidateValidationModal(false)

		dispatch(
			registerFinishedProject({
				...selectedProject,
				totalDuration: selectedProject.totalDuration(
					selectedProject.finishDateUpdated,
					selectedProject.startDateUpdated,
				),
				investmentsId: investmentsBySelectedProject.map((item) => item._id),
				depositDate: format(selectedProject.depositDate, 'yyyy-MM-dd'),
				newInfoInvestment: investmentsBySelectedProject,
			}),
		)
	}

	return (
		<LiquidatedProjectContext.Provider
			value={{
				alert,
				errorFinishedProjectRegister,
				errorUsers,
				finishedProjectRegisterData,
				handleCloseLiquidatedProjectModal,
				handleCloseUpdateProject,
				handleLiquidatedProjectOnBlur,
				handleSubmitData,
				investmentsBySelectedProject,
				isBenefitComplete,
				liquidatedProjectInitialState,
				liquidatedProjectModal,
				liquidatedProjectModalInfo,
				loadingFinishedProjectRegister,
				loadingProjectFinishedList,
				projectFinishedListData,
				projectList,
				resetState,
				scrollRef,
				selectedProject,
				setAlert,
				setErrorsUsers,
				setInvestmentsBySelectedProject,
				setIsBenefitComplete,
				setProjectList,
				setSelectedProject,
				setUpdateProjectInfo,
				setUpdateProjectModal,
				successFinishedProjectRegister,
				updateProjectInfo,
				updateProjectModal,
				liquidateValidationModal,
				setLiquidateValidationModal,
				handleLiquidateValidation,
			}}
		>
			{children}
		</LiquidatedProjectContext.Provider>
	)
}
