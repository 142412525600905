import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from '../../CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from './styles/EmailChangeModalStyles'

const useStyles = makeStyles(styles)

const EmailChangeModal = ({ open, handleCloseModal, message, modalTitle, handleCancel, handleConfirm }) => {
	const classes = useStyles()

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted
			onClose={handleCloseModal}
			aria-labelledby='modal-title'
			aria-describedby='modal-description'
		>
			<DialogTitle id='modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>{modalTitle}</h4>
			</DialogTitle>

			<DialogContent id='modal-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<Typography>{message}</Typography>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={handleCancel} block>
							No, Sólo el usuario
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button color={'primary'} onClick={handleConfirm} block>
							Si,Todos los perfiles
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default EmailChangeModal
