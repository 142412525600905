import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { deleteInvestorProfile } from 'local_redux/actions/investorProfileActions'
import { USER_LIST_RESET } from 'local_redux/constants/userConstants'
import { INVESTOR_PROFILE_DELETE_RESET } from 'local_redux/constants/investorProfileConstants'
import { INVESTOR_LIST_RESET } from 'local_redux/constants/investorConstant'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import styles from '../../../screens/control-panel/InvestorUsersListScreen/styles/InvestorUserDeleteModalStyles'

const useStyles = makeStyles(styles)

const DeleteProfileInvestorModal = ({
	handleCloseDeleteProfileInvestorModal,
	deleteProfileInvestorModal,
	showDeleteProfileInvestorModalInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { successInvestorProfileDelete, errorInvestorProfileDelete, loadingInvestorProfileDelete } = useSelector(
		(state) => state.investorProfileDelete,
	)

	useEffect(() => {
		let timer = ''
		if (successInvestorProfileDelete) {
			timer = setTimeout(() => {
				dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
				dispatch({ type: USER_LIST_RESET })
				dispatch({ type: INVESTOR_LIST_RESET })
				dispatch({ type: INVESTOR_PROFILE_DELETE_RESET })
				handleCloseDeleteProfileInvestorModal()
			}, 2000)
		}

		return () => clearTimeout(timer)
	}, [successInvestorProfileDelete])

	const handleDeleteUser = (e) => {
		e.preventDefault()
		dispatch(deleteInvestorProfile(showDeleteProfileInvestorModalInfo))
	}
	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={deleteProfileInvestorModal}
			keepMounted
			onClose={handleCloseDeleteProfileInvestorModal}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<form onSubmit={handleDeleteUser}>
				<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseDeleteProfileInvestorModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Eliminar Perfil Inversor</h4>
				</DialogTitle>

				<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Está seguro que quiere eliminar el perfil inversor{' '}
								<strong>{showDeleteProfileInvestorModalInfo?.businessName}</strong>?
							</Typography>
						</GridItem>
					</GridContainer>

					{errorInvestorProfileDelete && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorInvestorProfileDelete} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleCloseDeleteProfileInvestorModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button type='onSubmit' color={successInvestorProfileDelete ? 'success' : 'primary'} block>
								{loadingInvestorProfileDelete ? 'Eliminando' : successInvestorProfileDelete ? 'Eliminado' : 'Eliminar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default DeleteProfileInvestorModal
