import { useEffect, createRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { DialogTitle, DialogContent, DialogActions, makeStyles, styled, Divider, IconButton } from '@material-ui/core'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Close, Description, Delete, Visibility, Photo } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { generateTokenCode } from 'shared/helpers/generateTokenCode'
import { INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET } from 'local_redux/constants/investorProfileConstants'
import { uploadInvestorProfileDocuments } from 'local_redux/actions/investorProfileActions'
import styles from '../styles/uploadProfileDocumentsModalStyles'

const useStyles = makeStyles(styles)

const Demo = styled('div')(() => ({
  backgroundColor: '#D9E0E0',
  marginBottom: '10px',
  marginTop: '20px',
  borderRadius: '10px',
}))

const UploadProfileDocumentForm = ({
  handleCloseInvestorProfileUploadDocumentModal,
  showInvestorProfileUploadDocumentInfo,
  setDocumentName,
  setDeleteConfirmation,
}) => {
  const dispatch = useDispatch()
  const profileDniFrontFile = createRef()
  const profileDniBackFile = createRef()
  const kycContractFile = createRef()
  const classes = useStyles()

  const findDocument = (documentName) => {
    if (showInvestorProfileUploadDocumentInfo.documents) {
      const doc = showInvestorProfileUploadDocumentInfo.documents.filter((item) => item.documentName === documentName)
      return doc.length > 0 ? doc[0].documentPath : ''
    }
  }

  const [profileDniFront, setProfileDniFront] = useState(findDocument('profileDniFront'))
  const [profileDniBack, setProfileDniBack] = useState(findDocument('profileDniBack'))
  const [kycContract, setKycContract] = useState(findDocument('kycContract'))

  const {
    loadingInvestorProfileUploadDocuments,
    successInvestorProfileUploadDocuments,
    errorInvestorProfileUploadDocuments,
  } = useSelector((state) => state.investorProfileUploadDocuments)
  const { userInfo } = useSelector((state) => state.userLogin)

  useEffect(() => {
    let timeOut
    if (successInvestorProfileUploadDocuments) {
      timeOut = setTimeout(() => {
        handleCloseInvestorProfileUploadDocumentModal()
      }, 1500)
    }

    return () => clearTimeout(timeOut)
  }, [successInvestorProfileUploadDocuments])

  useEffect(() => {
    return dispatch({ type: INVESTOR_PROFILE_UPLOAD_DOCUMENTS_RESET })
  }, [dispatch])

  const selectFileHandler = (currentRef) => {
    currentRef.current.click()
  }

  const handleFileChange = (e) => {
    e.preventDefault()

    const handleFile = {
      profileDniFront: setProfileDniFront,
      profileDniBack: setProfileDniBack,
      kycContract: setKycContract,
    }
    handleFile[e.target.name](e.target.files)
  }

  const handleFileRemove = (name) => {
    const handleFile = {
      profileDniFront: setProfileDniFront,
      profileDniBack: setProfileDniBack,
      kycContract: setKycContract,
    }
    handleFile[name](undefined)
  }

  const handleUploadDocuments = (e) => {
    e.preventDefault()

    const files = {
      profileDniFront: typeof profileDniFront !== 'object' ? '' : profileDniFront[0],
      profileDniBack: typeof profileDniBack !== 'object' ? '' : profileDniBack[0],
      kycContract: typeof kycContract !== 'object' ? '' : kycContract[0],
      profileId: showInvestorProfileUploadDocumentInfo._id,
    }

    dispatch(uploadInvestorProfileDocuments(files))
  }

  return (
    <>
      <DialogTitle id='project-modal-upload-title' disableTypography className={classes.modalHeader}>
        <GridContainer>
          <GridItem xs={10}>
            <h4>Documentos de Perfil</h4>
          </GridItem>
          <GridItem xs={2} className={classes.closeButtonContainer}>
            <Button
              justIcon
              className={classes.closeButton}
              key='close'
              aria-label='Close'
              color='transparent'
              onClick={handleCloseInvestorProfileUploadDocumentModal}
            >
              <Close className={classes.modalClose} />
            </Button>
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <DialogContent id='project-modal-upload-description'>
        <form onSubmit={handleUploadDocuments} id={'upload-documents-profile'}>
          <GridContainer style={{ marginBottom: '20px' }}>
            <GridItem xs={12} style={{ marginBottom: '40px' }}>
              <Typography>
                En esta sección usted puede gestionar documentos relacionados con el perfil de
                <b> {showInvestorProfileUploadDocumentInfo.businessName}</b>
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <Demo>
                <List dense={true}>
                  <ListItem
                    className={classes.listItem}
                    secondaryAction={
                      <>
                        {!profileDniFront ? (
                          <IconButton
                            aria-label='add'
                            size='small'
                            onClick={() => selectFileHandler(profileDniFrontFile)}
                          >
                            <Photo fontSize='inherit' />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              aria-label='visibility'
                              size='small'
                              onClick={() =>
                                window.open(
                                  typeof profileDniFront === 'object'
                                    ? URL.createObjectURL(profileDniFront[0])
                                    : `${axios.defaults.baseURL}/${profileDniFront}?code=${generateTokenCode(
                                        userInfo.token
                                      )}`
                                )
                              }
                            >
                              <Visibility fontSize='inherit' />
                            </IconButton>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              onClick={() => {
                                if (typeof profileDniFront === 'object') {
                                  handleFileRemove('profileDniFront')
                                } else {
                                  setDeleteConfirmation(true)
                                  setDocumentName('Frente DNI')
                                }
                              }}
                            >
                              <Delete
                                fontSize='inherit'
                                color={!findDocument('profileDniFront') ? 'action' : 'error'}
                              />
                            </IconButton>
                          </>
                        )}
                      </>
                    }
                  >
                    <ListItemText className={classes.listItemText} primary='Frente DNI' />
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={classes.listItem}
                    secondaryAction={
                      <>
                        {!profileDniBack ? (
                          <IconButton
                            aria-label='add'
                            size='small'
                            onClick={() => selectFileHandler(profileDniBackFile)}
                          >
                            <Photo fontSize='inherit' />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              aria-label='visibility'
                              size='small'
                              onClick={() =>
                                window.open(
                                  typeof profileDniBack === 'object'
                                    ? URL.createObjectURL(profileDniBack[0])
                                    : `${axios.defaults.baseURL}/${profileDniBack}?code=${generateTokenCode(
                                        userInfo.token
                                      )}`
                                )
                              }
                            >
                              <Visibility fontSize='inherit' />
                            </IconButton>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              onClick={() => {
                                if (typeof profileDniBack === 'object') {
                                  handleFileRemove('profileDniBack')
                                } else {
                                  setDeleteConfirmation(true)
                                  setDocumentName('Dorso DNI')
                                }
                              }}
                            >
                              <Delete fontSize='inherit' color={!findDocument('profileDniBack') ? 'action' : 'error'} />
                            </IconButton>
                          </>
                        )}
                      </>
                    }
                  >
                    <ListItemText className={classes.listItemText} primary='Dorso DNI' />
                  </ListItem>
                  <Divider />
                  <ListItem
                    className={classes.listItem}
                    secondaryAction={
                      <>
                        {!kycContract ? (
                          <IconButton aria-label='add' size='small' onClick={() => selectFileHandler(kycContractFile)}>
                            <Description fontSize='inherit' />
                          </IconButton>
                        ) : (
                          <>
                            <IconButton
                              aria-label='visibility'
                              size='small'
                              onClick={() =>
                                window.open(
                                  typeof kycContract === 'object'
                                    ? URL.createObjectURL(kycContract[0])
                                    : `${axios.defaults.baseURL}/${kycContract}?code=${generateTokenCode(
                                        userInfo.token
                                      )}`
                                )
                              }
                            >
                              <Visibility fontSize='inherit' />
                            </IconButton>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              onClick={() => {
                                if (typeof kycContract === 'object') {
                                  handleFileRemove('kycContract')
                                } else {
                                  setDeleteConfirmation(true)
                                  setDocumentName('Contrato KyC')
                                }
                              }}
                            >
                              <Delete fontSize='inherit' color={!findDocument('kycContract') ? 'action' : 'error'} />
                            </IconButton>
                          </>
                        )}
                      </>
                    }
                  >
                    <ListItemText className={classes.listItemText} primary='Contrato KYC' />
                  </ListItem>
                </List>
              </Demo>
            </GridItem>
            <div className='fileinput'>
              <input
                type='file'
                name='profileDniFront'
                id='profileDniFront'
                accept='.png, .jpg, .jpeg'
                capture='camera'
                ref={profileDniFrontFile}
                onChange={handleFileChange}
              />
            </div>
            <div className='fileinput'>
              <input
                type='file'
                name='profileDniBack'
                accept='.png, .jpg, .jpeg'
                id='profileDniBack'
                ref={profileDniBackFile}
                onChange={handleFileChange}
              />
            </div>
            <div className='fileinput'>
              <input
                type='file'
                name='kycContract'
                accept='.doc, .docx'
                id='kycContract'
                ref={kycContractFile}
                onChange={handleFileChange}
              />
            </div>
          </GridContainer>
          {errorInvestorProfileUploadDocuments && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorInvestorProfileUploadDocuments} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              type='onSubmit'
              block
              form={'upload-documents-profile'}
              disabled={loadingInvestorProfileUploadDocuments}
              color={successInvestorProfileUploadDocuments ? 'success' : 'primary'}
            >
              {loadingInvestorProfileUploadDocuments
                ? 'Guardando'
                : successInvestorProfileUploadDocuments
                ? 'Guardado'
                : 'Guardar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </>
  )
}

export default UploadProfileDocumentForm
