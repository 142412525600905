import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { es } from 'date-fns/locale'
import { differenceInDays, format, formatDistanceToNow } from 'date-fns'
import classNames from 'classnames'
import { Divider, MenuItem, MenuList, ClickAwayListener, Paper, Grow, Popper } from '@material-ui/core'
import { AccessTime, CalendarMonth, PublishedWithChanges, PersonPin, NotificationsNone } from '@mui/icons-material'
import Badge from '@mui/material/Badge'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import NotificationModal from './NotificationModal'
import { SocketContext } from 'contexts/socketContext'
import { closeModal } from 'shared/helpers/modal-helper'
import { openModal } from 'shared/helpers/modal-helper'
import { getNotifications } from 'local_redux/actions/notificationActions'

const Notifications = ({
  classes,
  openNotification,
  handleClickNotification,
  handleCloseNotification,
  dropdownItem,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { socket, online } = useContext(SocketContext)

  const [notifications, setNotifications] = useState([])
  const [notificationModal, setNotificationModal] = useState(false)
  const [showNotificationInfo, setShowNotificationInfo] = useState({})

  const { userInfo } = useSelector((state) => state.userLogin)
  const { successNotificationList, notificationListData } = useSelector((state) => state.notificationList)

  useEffect(() => {
    dispatch(getNotifications())
  }, [])
  useEffect(() => {
    if (successNotificationList && notificationListData.length > 0) {
      setNotifications(notificationListData)
    }
  }, [successNotificationList, notificationListData])
  useEffect(() => {
    if (userInfo && online) {
      let ownUser = {
        _id: userInfo._id,
        name: userInfo.name,
      }
      socket.emit('user-connected', ownUser)
      socket.on('new-notification', (payload) => {
        setNotifications((prev) => [payload, ...prev])
      })
    }
  }, [socket, online])

  const getHowLongAgo = (date) => {
    const distance = differenceInDays(new Date(), new Date(date))

    if (distance === 0) {
      return formatDistanceToNow(new Date(date), { addSuffix: true, locale: es })
    }

    if (distance === 1) {
      return 'ayer'
    }

    if (distance > 1 && distance <= 7) {
      return 'esta semana'
    }

    return format(new Date(date), "dd MMM'.'  yyyy", { locale: es })
  }
  const handleCloseModal = () => {
    closeModal(setShowNotificationInfo, setNotificationModal)
  }
  const changeToView = (data) => {
    let notif = notifications.filter((item) => item._id !== data._id)
    setNotifications(notif)
  }

  return (
    <>
      <Button
        color='transparent'
        aria-label='Notifications'
        justIcon
        aria-owns={openNotification ? 'profile-menu-list' : null}
        aria-haspopup='true'
        onClick={handleClickNotification}
        className={classes.buttonLink}
        muiClasses={{
          label: 'Notifications',
        }}
      >
        <Badge badgeContent={notifications.length} color='primary'>
          <NotificationsNone className={classes.headerLinksSvg + ' ' + (classes.links + ' ' + classes.links)} />
        </Badge>
      </Button>
      <Popper
        open={Boolean(openNotification)}
        anchorEl={openNotification}
        transition
        disablePortal
        placement='bottom'
        className={classNames({
          [classes.popperClose]: !openNotification,
          [classes.popperResponsive]: true,
          [classes.popperNav]: true,
        })}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id='profile-menu-list' style={{ transformOrigin: '0 0 0' }}>
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseNotification}>
                <MenuList role='menu'>
                  {notifications.length > 0 ? (
                    notifications.map((item, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          handleCloseNotification(null)
                          changeToView(item)
                          openModal(item, setShowNotificationInfo, setNotificationModal)
                        }}
                        className={dropdownItem}
                      >
                        {!item.viewed && (
                          <GridContainer direction='row' alignItems='center'>
                            <GridItem xs={1} className={classes.notificationIconContainerMargin}>
                              {!item.type || item.type === '' || item.type === 'liquidated' ? (
                                <AccessTime className={classes.notificationsIcons} />
                              ) : item.type === 'delayed' || item.type === 'projectsToBeCompleted' ? (
                                <CalendarMonth className={classes.notificationsIcons} />
                              ) : item.type === 'changeState' ? (
                                <PublishedWithChanges className={classes.notificationsIcons} />
                              ) : (
                                item.type === 'byAdmin' && <PersonPin className={classes.notificationsIcons} />
                              )}
                            </GridItem>
                            <GridItem xs={10} className={classes.notificationsTextContainer}>
                              <b>{item.title}</b>
                              <p>{item.message.length > 30 ? item.message.slice(0, 30) + '...' : item.message}</p>
                              <span>Notificación enviada: {getHowLongAgo(item.created)}</span>
                            </GridItem>
                          </GridContainer>
                        )}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      onClick={() => {
                        handleCloseNotification(null)
                      }}
                      className={dropdownItem}
                    >
                      Sin notificaciones nuevas
                    </MenuItem>
                  )}
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      navigate('/admin/notifications')
                      handleCloseNotification(null)
                    }}
                    className={dropdownItem}
                    style={{ textAlign: 'center', marginTop: '10px', fontWeight: 500 }}
                  >
                    <span>Ver todas las Notificaciones</span>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {notificationModal && (
        <NotificationModal
          showNotificationInfo={showNotificationInfo}
          handleCloseNotificationModal={handleCloseModal}
          notificationModal={notificationModal}
        />
      )}
    </>
  )
}

export default Notifications
