const styles = {
  gridSearch: {
    marginTop: '3rem',
    textAlign: 'center',
  },
  iconSearch: {
    '& svg': {
      fontSize: '130px',
      color: '#999',
    },
  },
}

export default styles
