import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import SweetAlert from 'react-bootstrap-sweetalert'
import business from 'config/business/business'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import CardProjectInfo from 'components/CardProjectInfo/CardProjctInfo'
import InvestmentList from 'components/InvestmentsList/InvestmentsList'
import Loader from 'components/Loader/Loader'
import SelectProfilesByInvestor from 'components/SelectProfilesByInvestor/SelectProfilesByInvestor'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import {
	replaceStringToNum,
	matchCurrencyToDecimal,
	matchNumberToCurrency,
} from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import amountInWords from 'shared/amountInWords/amountInWords'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import {
	INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET,
	INVESTMENT_REGISTER_RESET,
} from 'local_redux/constants/investmentConstants'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import { registerInvestment } from 'local_redux/actions/investmentActions'
import { investorsProfileByUser } from 'local_redux/actions/investorProfileActions'
import { registerLog } from 'local_redux/actions/logActions'
import styles from './styles/investmentModalStyles'

const useStyles = makeStyles(styles)

const InvestmentModal = ({ handleCloseInvestmentModal, investmentModal, showInvestmentInfo }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()

	const [infoModalData, setModalData] = useState({ ...showInvestmentInfo, profile: {} })
	const [totalInvertible, setTotalInvertible] = useState(0)
	const [minAmountError, setMinAmountError] = useState(false)
	const [maxAmountError, setMaxAmountError] = useState(false)
	const [alert, setAlert] = useState(null)

	const { errorInvestmentRegister, investmentRegisterData, investmentRegisterSuccess, loadingInvestmentRegister } =
		useSelector((state) => state.investmentRegister)
	const { successInvestorsProfileByUser, investorsProfileByUserData, loadingInvestorsProfileByUser } = useSelector(
		(state) => state.investorsProfileByUser,
	)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!successInvestorsProfileByUser) dispatch(investorsProfileByUser(userInfo._id))
	}, [])
	useEffect(() => {
		let total = 0
		infoModalData.investments.forEach((item) => {
			total = total + parseFloat(replaceStringToNum(item.amount))
		})
		let amountInvertible = matchCurrencyToDecimal(showInvestmentInfo.maxInvertible) - total
		setTotalInvertible(matchNumberToCurrency(amountInvertible))
	}, [infoModalData])
	useEffect(() => {
		if (investmentRegisterSuccess) {
			// eslint-disable-next-line no-use-before-define
			handleInvestmentLog()
			dispatch({ type: INVESTMENT_REGISTER_RESET })
			dispatch({ type: INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET })
			handleCloseInvestmentModal()
		}
	}, [investmentRegisterSuccess])
	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		errorInvestmentRegister && handleInvestmentLog(true)
	}, [errorInvestmentRegister])
	useEffect(() => {
		let text = ''
		minAmountError
			? (text = 'La inversión mínima por proyecto es de 10.000 euros. Disculpe las molestias.')
			: (text = `La inversión máxima para este proyecto es de ${totalInvertible} euros. Disculpe las molestias.`)

		if (minAmountError || maxAmountError) {
			setAlert(
				<SweetAlert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title='La inversión no se ha podido realizar'
					onConfirm={() => setAlert(null)}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					<div style={{ textAlign: 'left' }}>{text}</div>
				</SweetAlert>,
			)
		}
	}, [minAmountError, maxAmountError])
	useEffect(() => {
		return () => {
			dispatch({ type: INVESTMENT_REGISTER_RESET })
			dispatch({ type: LOG_REGISTER_RESET })
		}
	}, [dispatch])

	const handleInvestmentData = (e) => {
		e.preventDefault()
		if (Number(infoModalData.amount) > matchCurrencyToDecimal(totalInvertible.toString())) {
			setMaxAmountError(true)
			return
		} else if (Number(infoModalData.amount) < business.MIN_AMOUNT_INVERTIBLE) {
			setMinAmountError(true)
			return
		} else {
			const data = {
				projectId: infoModalData._id,
				amount: infoModalData.amount,
				amountInWords: amountInWords(infoModalData.amount, 'EUROS'),
				profileId: infoModalData.profile?._id,
			}
			dispatch(registerInvestment(data))
		}
	}
	const handleInvestmentLog = (error) => {
		let profileInfo = investorsProfileByUserData.filter((investor) => investor._id == infoModalData.profile?._id)
		let logInfo = {
			investors: {
				id: profileInfo[0]._id,
				...profileInfo[0],
			},
			project: {
				id: infoModalData._id,
				...infoModalData,
			},
			user: {
				id: profileInfo[0].user._id,
				...profileInfo[0].user,
			},
		}
		if (!error) {
			logInfo.type = {
				type: 'action',
				actionType: 'INVESTMENT_REGISTER_SUCCESS',
			}
			logInfo.investment = {
				id: investmentRegisterData?._id,
				...investmentRegisterData,
			}
			dispatch(registerLog(logInfo))
		} else {
			logInfo.type = {
				type: 'error',
				error: {
					errorType: 'INVESTMENT_REGISTER_FAIL',
					errorMessage: errorInvestmentRegister,
				},
			}
			logInfo.investment = {
				amount: matchNumberToCurrency(Number(infoModalData.amount)),
			}
			dispatch(registerLog(logInfo))
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal + ' ' + classes.modalResponsive,
			}}
			open={investmentModal}
			keepMounted
			onClose={handleCloseInvestmentModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseInvestmentModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4 className={classes.modalTitle}>Invertir en este proyecto</h4>
			</DialogTitle>
			<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
				{loadingInvestorsProfileByUser ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader message={'cargando perfiles'} config={{ marginTop: '30px', alignItems: 'center' }} />
						</GridItem>
					</GridContainer>
				) : (
					<GridContainer>
						<GridItem xs={12}>
							<form onSubmit={handleInvestmentData} id='submitInvestmentForm'>
								{investorsProfileByUserData?.length ? (
									<>
										<GridContainer>
											<SelectProfilesByInvestor
												infoModalData={infoModalData}
												setModalData={setModalData}
												investmentModal={true}
											/>
											{infoModalData.profile?._id ? (
												infoModalData.profile?.canNotInvest ? (
													<GridItem xs={12}>
														<h4 style={{ textAlign: 'center' }}>
															Perfil desactivado. Por favor comuníquese urgentemente con nuestro departamento de
															relación con inversores.
														</h4>
													</GridItem>
												) : (
													<>
														<CardProjectInfo infoModalData={infoModalData} totalInvertible={totalInvertible} />
														<GridContainer>
															<GridItem xs={12} md={4}>
																<CustomInput
																	labelText={
																		minAmountError
																			? `La inversión no puede ser menor a ${business.MIN_AMOUNT_INVERTIBLE} €`
																			: maxAmountError
																			? `La inversión no puede ser mayor a ${totalInvertible}`
																			: 'Importe de la Inversión *'
																	}
																	id='investment-amount'
																	error={
																		Number(infoModalData.amount) > matchCurrencyToDecimal(totalInvertible.toString()) ||
																		minAmountError
																	}
																	step='0.01'
																	formControlProps={{
																		fullWidth: true,
																	}}
																	inputProps={{
																		value: infoModalData?.amount || '',
																		onChange: (e) => {
																			setMaxAmountError(false)
																			setMinAmountError(false)
																			setModalData({ ...infoModalData, amount: e.target.value })
																		},
																		type: 'number',
																		required: true,
																	}}
																/>
															</GridItem>
															<GridItem xs={12} md={8}>
																<CustomInput
																	labelText='Importe en letras de la Inversión '
																	id='investment-number-to-words'
																	formControlProps={{
																		fullWidth: true,
																	}}
																	inputProps={{
																		value: amountInWords(infoModalData?.amount || '', 'EUROS'),
																		type: 'text',
																		required: true,
																		disabled: true,
																	}}
																/>
															</GridItem>
														</GridContainer>
													</>
												)
											) : null}
										</GridContainer>
										{errorInvestmentRegister && (
											<GridContainer>
												<GridItem xs={12}>
													<SnackbarContent message={errorInvestmentRegister} color='danger' />
												</GridItem>
											</GridContainer>
										)}
									</>
								) : (
									!loadingInvestorsProfileByUser && (
										<GridContainer>
											<GridItem xs={9} style={{ margin: 'auto', textAlign: 'center' }}>
												<img
													src={getUrlToOpenFile(infoModalData?.coverImage, userInfo)}
													alt={`${infoModalData?.coverImage}`}
													width='100%'
													style={{ borderRadius: '4px' }}
												/>
												<h4 style={{ marginBottom: '40px' }}>{infoModalData?.projectAddress}</h4>
											</GridItem>
											<GridItem xs={12}>
												<h5>
													Para poder invertir es necesario crear un <strong>Perfil de Inversor</strong>
												</h5>
											</GridItem>
											<GridItem>
												<Button onClick={() => navigate('/admin/investor-register')} color={'primary'} block>
													Crear Perfil Inversor
												</Button>
											</GridItem>
										</GridContainer>
									)
								)}
							</form>
						</GridItem>
					</GridContainer>
				)}
				{alert}
			</DialogContent>
			{infoModalData.profile?._id && !infoModalData.profile?.canNotInvest && (
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button
								type='submit'
								form='submitInvestmentForm'
								color={investmentRegisterSuccess ? 'success' : 'primary'}
								disabled={
									totalInvertible === matchNumberToCurrency(0) || loadingInvestmentRegister || investmentRegisterSuccess
								}
								block
							>
								{loadingInvestmentRegister ? 'Invirtiendo...' : 'Invertir'}
							</Button>
						</GridItem>
						<GridItem xs={12}>
							<InvestmentList infoModalData={infoModalData} investmentModalComponent={true} />
						</GridItem>
					</GridContainer>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default InvestmentModal
