const sidebarStyle = () => ({
	img: {
		width: '35px',
		verticalAlign: 'middle',
		border: '0',
	},
	imgComplete: {
		width: '100%',
		verticalAlign: 'middle',
		border: '0',
		padding: '0 10px',
	},
	logo: {
		padding: '15px 0px',
		margin: '0',
		display: 'block',
		position: 'relative',
		zIndex: '4',
		backgroundColor: 'transparent',
		'&:after': {
			content: '""',
			position: 'absolute',
			bottom: '0',
			height: '1px',
			right: '15px',
			width: 'calc(100% - 30px)',
			backgroundColor: 'hsla(0,0%,100%,.3)',
		},
	},
	logoMini: {
		transition: 'all 300ms linear',
		opacity: 1,
		float: 'left',
		textAlign: 'center',
		width: '30px',
		display: 'inline-block',
		maxHeight: '30px',
		marginLeft: '22px',
		marginRight: '18px',
		marginTop: '7px',
		color: 'inherit',
	},
	whiteAfter: {
		'&:after': {
			backgroundColor: 'hsla(0,0%,71%,.3) !important',
		},
	},
})

export default sidebarStyle
