import React, { useContext, useState } from 'react'
import ReactExport from 'react-data-export'
import { makeStyles, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Clearfix from 'components/Clearfix/Clearfix'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import styles from '../styles/reactTableStyles'

const useStyles = makeStyles(styles)

const ExcelDefaultExport = ({ excelName, data, tableColumns }) => {
	const classes = useStyles()
	const ExcelFile = ReactExport.ExcelFile
	const [open, setOpen] = useState(false)
	const { filteredcolumns } = useContext(FilterReactTableContext)

	const handleSendExcel = (e) => {
		e.preventDefault()
		setOpen(false)
	}

	const handleCloseModal = () => {
		setOpen(false)
	}

	return (
		<>
			<div className={classes.btnExport}>
				<Button color='primary' onClick={() => setOpen(true)}>
					Exportar Excel
				</Button>
			</div>

			<Dialog
				classes={{
					root: classes.modalRoot,
					paper: classes.modal,
				}}
				open={open}
				keepMounted
				onClose={handleCloseModal}
				aria-labelledby='notice-modal-slide-title'
				aria-describedby='notice-modal-slide-description'
			>
				<form onSubmit={handleSendExcel} autoComplete='false'>
					<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
						<Button
							justIcon
							className={classes.modalCloseButton}
							key='close'
							aria-label='Close'
							color='transparent'
							onClick={handleCloseModal}
						>
							<Close className={classes.modalClose} />
						</Button>
						<h4 className={classes.modalTitle}>{excelName}</h4>
					</DialogTitle>

					<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
						<GridContainer>
							<GridItem xs={12}>
								<p>¿Está seguro que desea exportar {excelName} en formato excel? </p>
							</GridItem>
						</GridContainer>
					</DialogContent>
					<DialogActions className={classes.modalFooter}>
						<GridContainer className={classes.rootModal}>
							<Button className={classes.btnModal} onClick={() => setOpen(false)}>
								Cancelar
							</Button>
							<Clearfix />
							<ExcelFile
								element={
									<Button type='submit' color='primary'>
										Exportar Excel
									</Button>
								}
								filename={excelName}
								hideElement={false}
							>
								<ExcelFile.ExcelSheet data={data} name={excelName}>
									{tableColumns
										? tableColumns.map((column, index) => (
												<ExcelFile.ExcelColumn
													key={index}
													label={column.Header || column.header}
													value={column.accessor}
												/>
										  ))
										: filteredcolumns.map((column, index) => (
												<ExcelFile.ExcelColumn key={index} label={column.Header} value={column.accessor} />
										  ))}
								</ExcelFile.ExcelSheet>
							</ExcelFile>
							<Clearfix />
						</GridContainer>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default ExcelDefaultExport
