import { format } from 'date-fns'
import { DatePicker } from '@material-ui/pickers'
import { FilterAlt } from '@mui/icons-material'
import { makeStyles } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from './styles/timeFilterCardStyles'
const useStyles = makeStyles(styles)

const TimeFilterCard = ({ filterDate, setFilterDate, handleFilter, title }) => {
  const classes = useStyles()
  const { startDate, endDate } = filterDate

  const start = startDate ? true : false
  const end = endDate ? true : false
  let isOpen = start && !end

  return (
    <Card>
      <CardHeader color='primary' icon>
        <CardIcon color='primary'>
          <FilterAlt />
        </CardIcon>
        <h4 className={classes.cardIconTitle}>Selecciona el rango de fecha de las inversiones</h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={6} style={{ margin: '12px 0 0 0' }}>
            <DatePicker
              fullWidth
              disableFuture
              value={filterDate.startDate}
              onChange={(e) => setFilterDate({ ...filterDate, startDate: e, endDate: null })}
              label='Fecha Inicio *'
              className={classes.dateInput}
            />
          </GridItem>
          <GridItem xs={12} sm={6} style={{ margin: '12px 0 0 0' }}>
            <DatePicker
              fullWidth
              open={isOpen}
              disableFuture
              minDate={filterDate.startDate}
              minDateMessage={'Por favor revise este valor'}
              value={filterDate.endDate}
              onChange={(e) => {
                setFilterDate({ ...filterDate, endDate: e })
                handleFilter({ ...filterDate, endDate: e })
              }}
              label='Fecha Fin *'
              className={classes.dateInput}
            />
          </GridItem>
          <GridItem xs={12} style={{ marginTop: '1rem' }}>
            {filterDate.startDate && filterDate.endDate && (
              <>
                {title} <strong>{format(new Date(filterDate.startDate), 'dd/MM/yyyy')}</strong> -{' '}
                <strong>{format(new Date(filterDate.endDate), 'dd/MM/yyyy')}</strong>
              </>
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default TimeFilterCard
