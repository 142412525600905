import { createRef } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles, styled, Divider, IconButton } from '@material-ui/core'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { Description, Delete, Visibility, Photo } from '@material-ui/icons'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from './styles/uploadProfileDocumentsFormStyles'

const useStyles = makeStyles(styles)

const Demo = styled('div')(() => ({
	backgroundColor: '#D9E0E0',
	marginBottom: '10px',
	marginTop: '20px',
	padding: '10px',
}))

const InvestorProfileDocumentsForm = ({
	profileDniFront,
	setProfileDniFront,
	profileDniBack,
	setProfileDniBack,
	kycContract,
	setKycContract,
	accountOwnership,
	setAccountOwnership,
	prescriptorContractUrl,
	setPrescriptorContractUrl,
}) => {
	const profileDniFrontFile = createRef()
	const profileDniBackFile = createRef()
	const kycContractFile = createRef()
	const accountOwnershipFile = createRef()
	const prescriptorContractUrlFile = createRef()

	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	const selectFileHandler = (currentRef) => {
		currentRef.current.click()
	}

	const handleFileChange = (e) => {
		e.preventDefault()

		const handleFile = {
			profileDniFront: setProfileDniFront,
			profileDniBack: setProfileDniBack,
			kycContract: setKycContract,
			accountOwnership: setAccountOwnership,
			prescriptorContractUrl: setPrescriptorContractUrl,
		}
		handleFile[e.target.name](e.target.files)
	}

	const handleFileRemove = (name) => {
		const handleFile = {
			profileDniFront: setProfileDniFront,
			profileDniBack: setProfileDniBack,
			kycContract: setKycContract,
			accountOwnership: setAccountOwnership,
			prescriptorContractUrl: setPrescriptorContractUrl,
		}
		handleFile[name](undefined)
	}

	return (
		<>
			<GridItem xs={12}>
				<Demo className={classes.cardRoot}>
					<p className={classes.cardTitle}>Documentos</p>
					<List dense={true}>
						<ListItem
							className={classes.listItem}
							secondaryAction={
								<>
									{!profileDniFront ? (
										<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(profileDniFrontFile)}>
											<Photo fontSize='inherit' />
										</IconButton>
									) : (
										<>
											<IconButton
												aria-label='visibility'
												size='small'
												onClick={() => window.open(getUrlToOpenFile(profileDniFront, userInfo))}
											>
												<Visibility fontSize='inherit' />
											</IconButton>
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('profileDniFront')
												}}
											>
												<Delete fontSize='inherit' />
											</IconButton>
										</>
									)}
								</>
							}
						>
							<ListItemText className={classes.listItemText} primary='Frente DNI' />
						</ListItem>
						<Divider />
						<ListItem
							className={classes.listItem}
							secondaryAction={
								<>
									{!profileDniBack ? (
										<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(profileDniBackFile)}>
											<Photo fontSize='inherit' />
										</IconButton>
									) : (
										<>
											<IconButton
												aria-label='visibility'
												size='small'
												onClick={() => window.open(getUrlToOpenFile(profileDniBack, userInfo))}
											>
												<Visibility fontSize='inherit' />
											</IconButton>
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('profileDniBack')
												}}
											>
												<Delete fontSize='inherit' />
											</IconButton>
										</>
									)}
								</>
							}
						>
							<ListItemText className={classes.listItemText} primary='Dorso DNI' />
						</ListItem>
						<Divider />
						<ListItem
							className={classes.listItem}
							secondaryAction={
								<>
									{!kycContract ? (
										<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(kycContractFile)}>
											<Description fontSize='inherit' />
										</IconButton>
									) : (
										<>
											<IconButton
												aria-label='visibility'
												size='small'
												onClick={() => window.open(getUrlToOpenFile(kycContract, userInfo))}
											>
												<Visibility fontSize='inherit' />
											</IconButton>
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('kycContract')
												}}
											>
												<Delete fontSize='inherit' />
											</IconButton>
										</>
									)}
								</>
							}
						>
							<ListItemText className={classes.listItemText} primary='Contrato KYC' />
						</ListItem>
						<Divider />
						<ListItem
							className={classes.listItem}
							secondaryAction={
								<>
									{!accountOwnership ? (
										<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(accountOwnershipFile)}>
											<Description fontSize='inherit' />
										</IconButton>
									) : (
										<>
											<IconButton
												aria-label='visibility'
												size='small'
												onClick={() => window.open(getUrlToOpenFile(accountOwnership, userInfo))}
											>
												<Visibility fontSize='inherit' />
											</IconButton>
											<IconButton
												aria-label='delete'
												size='small'
												onClick={() => {
													handleFileRemove('accountOwnership')
												}}
											>
												<Delete fontSize='inherit' />
											</IconButton>
										</>
									)}
								</>
							}
						>
							<ListItemText className={classes.listItemText} primary='Certificado de Titularidad' />
						</ListItem>
						<Divider />
						{userInfo && (userInfo.isPrescriber || userInfo.isSuper) && (
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{!prescriptorContractUrl ? (
											<IconButton
												aria-label='add'
												size='small'
												onClick={() => selectFileHandler(prescriptorContractUrlFile)}
											>
												<Description fontSize='inherit' />
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() => window.open(getUrlToOpenFile(prescriptorContractUrl, userInfo))}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
												<IconButton
													aria-label='delete'
													size='small'
													onClick={() => {
														handleFileRemove('prescriptorContractUrl')
													}}
												>
													<Delete fontSize='inherit' />
												</IconButton>
											</>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary='Contrato de Prescriptor' />
							</ListItem>
						)}
					</List>
				</Demo>
			</GridItem>
			<div className='fileinput'>
				<input
					type='file'
					name='profileDniFront'
					id='profileDniFront'
					accept='.png, .jpg, .jpeg'
					capture='camera'
					ref={profileDniFrontFile}
					onChange={handleFileChange}
				/>
			</div>
			<div className='fileinput'>
				<input
					type='file'
					name='profileDniBack'
					accept='.png, .jpg, .jpeg'
					id='profileDniBack'
					ref={profileDniBackFile}
					onChange={handleFileChange}
				/>
			</div>
			<div className='fileinput'>
				<input
					type='file'
					name='kycContract'
					accept='.doc, .docx'
					id='kycContract'
					ref={kycContractFile}
					onChange={handleFileChange}
				/>
			</div>
			<div className='fileinput'>
				<input
					type='file'
					name='accountOwnership'
					accept='.pdf,'
					id='accountOwnership'
					ref={accountOwnershipFile}
					onChange={handleFileChange}
				/>
			</div>
			<div className='fileinput'>
				<input
					type='file'
					name='prescriptorContractUrl'
					accept='.pdf,'
					id='prescriptorContractUrl'
					ref={prescriptorContractUrlFile}
					onChange={handleFileChange}
				/>
			</div>
		</>
	)
}

export default InvestorProfileDocumentsForm
