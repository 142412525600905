const styles = {
	title: {
		fontSize: 12,
		color: '#333333',
		'& svg': {
			fontSize: 12,
		},
	},
	textContainer: {
		justifyContent: 'space-between',
		marginTop: '5px',
	},
	startDateTitle: {
		fontSize: '10px',
	},
	endDateTitle: {
		fontSize: '10px',
		textAlign: 'end',
	},
	smallText: {
		fontSize: '10px',
	},
	completeText: {
		paddingBottom: '10px',
		paddingTop: '10px',
	},
}

export default styles
