export const stateReducer = (newState, action, prevState) => {
	let filter = ''
	newState?.filters.forEach(function (item) {
		filter = `${filter}${item.id}=${item.value}&`
	})
	switch (action.type) {
		case 'setToken':
			return { ...prevState, customPage: `${action.payload}${filter ? '&' + filter : ''}` }
		case 'setFilter':
			return { ...newState, customPage: `?page=${prevState.pageIndex}&limit=${prevState.pageSize}&${filter}` }
		default:
			return newState
	}
}
