import { styled } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

const Demo = styled('div')(() => ({
  backgroundColor: '#D9E0E0',
  marginBottom: '10px',
  marginTop: '20px',
  borderRadius: '10px',
  width: '100%',
  padding: '16px',
}))

const DocumentsContainer = ({ title, children }) => {
  return (
    <GridContainer style={{ padding: '30px 10px' }}>
      <Demo>
        <GridItem xs={12}>
          <b>{title}</b>
        </GridItem>
        <GridItem xs={12} style={{ marginTop: '40px' }}>
          {children}
        </GridItem>
      </Demo>
    </GridContainer>
  )
}

export default DocumentsContainer
