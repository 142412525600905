import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	...customSelectStyle,
	pagPosition: {
		position: 'sticky',
		top: '0',
		left: '0',
	},
	formControlMargins: {
		margin: '3px 0 !important',
	},
	hoverCell: {
		'&:hover': {
			color: 'black',
			fontWeight: '400',
		},
	},
	gridContainer: {
		justifyContent: 'center',
	},
	widthMiniSmall: {
		width: '15px !important',
	},
	widthSmall: {
		minWidth: '100px !important',
	},
	widthMedium: {
		minWidth: '135px !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	btnExport: {
		marginBottom: '10px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	rootModal: {
		overflow: 'hidden',
		justifyContent: 'flex-end',
		padding: '0px 20px',
	},
	btnModal: {
		marginRight: '10px',
	},
	loader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	...modalStyle(theme),
})

export default styles
