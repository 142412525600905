const styles = {
  root: {
    display: 'block',
    marginTop: '-100px',
  },
  confirmBtnCssClass: {
    backgroundColor: '#c0bbac',
    color: '#fff',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#fff',
    },
  },
}

export default styles
