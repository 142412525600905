import { styled } from '@material-ui/styles'
import { Slider } from '@mui/material'

const PrettoSlider = styled(Slider)({
	color: '#fff',
	height: 8,
	'& .MuiSlider-track': {
		border: 'none',
		background: '#b29d8a',
	},
	'& .MuiSlider-rail': {
		backgroundColor: '#fff',
	},
	'& .MuiSlider-thumb': {
		height: 20,
		width: 20,
		backgroundColor: '#725b43',
		border: '2px solid #725b43',
		'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
			boxShadow: 'inherit',
		},
		'&:before': {
			display: 'none',
		},
	},
	'& .MuiSlider-valueLabel': {
		lineHeight: 1.2,
		fontSize: 12,
		background: 'unset',
		padding: '0 5px',
		width: 'auto',
		height: 20,
		zIndex: 3000,
		backgroundColor: 'transparent',
		color: '#000',
		transformOrigin: 'bottom left',
		transform: 'translate(100%, 60%) rotate(-180deg) scale(1)',
		'&:before': { display: 'none' },
		'&.MuiSlider-valueLabelOpen': {
			transform: 'translate(100%, 70%) rotate(-180deg) scale(1)',
		},
		'& > *': {
			transform: 'rotate(180deg)',
		},
	},
})

const SliderFilter = (props) => {
	return <PrettoSlider {...props} />
}

export default SliderFilter
