import { useState, useEffect, useRef } from 'react'
import { InputAdornment, makeStyles, Tooltip } from '@material-ui/core'
import { CircularProgress } from '@mui/material'
import { Close } from '@mui/icons-material'
import CustomInput from 'components/CustomInput/CustomInput'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import styles from '../styles/addressAutoCompleteStyles'

const useStyles = makeStyles(styles)

const AddressAutoComplete = ({
	dispatchReset = () => {},
	handleChange = () => {}, // Additional onChange
	handleOptionsSelected = () => {},
	isLoadingOptions = false,
	name = '', // Input name
	options = [], // List of options to render
	setState = () => {},
	state = {},
	textError = '', // Error message
	isDisabled,
	showSuggestionsWithFocus, // Show hints on focus. help when you don't know the value to enter
}) => {
	const classes = useStyles()
	const autocompleteRef = useRef()

	const [inputValue, setInputValue] = useState('')
	const [showSuggestions, setShowSuggestions] = useState(false)

	//if the input is deactivated, we will also remove its value in the state and in the input value
	useEffect(() => {
		if (isDisabled && !isLoadingOptions) {
			setShowSuggestions(false)
			setInputValue('')
			setState((prev) => ({ ...prev, [name]: '' }))
		}
	}, [isDisabled])
	// Close the list when clicking outside the component
	useEffect(() => {
		const handleClick = (event) => {
			if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
				setShowSuggestions(false)
			}
		}
		document.addEventListener('click', handleClick)
		return () => {
			document.removeEventListener('click', handleClick)
		}
	}, [])
	// Verify that when closing the list of options the value of the state is the same as that of the input, otherwise it returns to its initial value
	useEffect(() => {
		if (!showSuggestions && inputValue !== state[name]) {
			setInputValue('')
			dispatchReset() // If there is an error message, reset the reducer
		}
	}, [showSuggestions])

	//clicking on an option in the list sets the state and input values ​​equal, then closes the list of options
	const handleSuggestionClick = (suggetion) => {
		setState(suggetion)
		setInputValue(suggetion)
		handleOptionsSelected(suggetion)
		setShowSuggestions(false)
	}
	//When the input is completely empty, the values ​​of the state and the input are equal to empty string
	const clearValuesHandle = () => {
		setInputValue('')
		setState((prev) => ({ ...prev, [name]: '' }))
		handleChange('')
	}

	return (
		<GridContainer>
			<GridItem xs={12}>
				<div className={classes.autocomplete} ref={autocompleteRef}>
					<form autoComplete='off'>
						<CustomInput
							error={Boolean(textError)}
							helperText={textError}
							labelText={name}
							id={`${name} autoComplete`}
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								onFocus: () => {
									showSuggestionsWithFocus && setShowSuggestions(true)
								},
								onChange: (e) => {
									setShowSuggestions(true)
									e?.target?.value?.length === 0 && clearValuesHandle()
									setInputValue(e?.target?.value)
									handleChange(e?.target?.value)
								},
								type: 'text',
								disabled: isDisabled,
								name: `${name} autoComplete`,
								value: inputValue?.label,
								endAdornment:
									inputValue?.length > 0 ? (
										<InputAdornment position='end'>
											<Tooltip title={isLoadingOptions ? 'Cargando' : 'Limpiar'}>
												{isLoadingOptions ? (
													<CircularProgress color='inherit' size={20} />
												) : (
													<Close
														fontSize={'small'}
														style={{
															cursor: 'pointer',
														}}
														onClick={() => {
															clearValuesHandle()
														}}
													/>
												)}
											</Tooltip>
										</InputAdornment>
									) : (
										isLoadingOptions && (
											<InputAdornment position='end'>
												<Tooltip title={'Cargando'}>
													<CircularProgress color='inherit' size={20} />
												</Tooltip>
											</InputAdornment>
										)
									),
							}}
						/>
					</form>
					{showSuggestions && !textError && (
						<ul className={classes.suggestions} style={{ width: `${autocompleteRef.current.offsetWidth}px` }}>
							{options.map((suggestion, index) => {
								return (
									<li onClick={() => handleSuggestionClick(suggestion)} key={index}>
										{suggestion.label}
									</li>
								)
							})}
						</ul>
					)}
				</div>
			</GridItem>
		</GridContainer>
	)
}

export default AddressAutoComplete
