import { makeStyles } from '@material-ui/core'
import logoComplete from 'assets/img/logo-chapnikandgiesen-oficial-sm.png'
import styles from '../styles/brandStyles'

const useStyles = makeStyles(styles)

const Brand = () => {
  const classes = useStyles()

  return (
    <div className={classes.logo + ' ' + classes.whiteAfter}>
      <a href='/'>
        <img src={logoComplete} alt='logo' className={classes.imgComplete} />
      </a>
    </div>
  )
}

export default Brand
