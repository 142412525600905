import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, makeStyles } from '@material-ui/core'
import { matchDocumentName } from 'shared/helpers/functions-helpers'
import DeleteProfileDocumentForm from './components/DeleteProfileDocumentForm'
import UploadProfileDocumentForm from './components/UploadProfileDocumentForm'
import styles from './styles/uploadProfileDocumentsModalStyles'

const useStyles = makeStyles(styles)

const UploadProfileDocumentsModal = ({
	handleCloseInvestorProfileUploadDocumentModal,
	investorProfileUploadDocumentModal,
	showInvestorProfileUploadDocumentInfo,
	investorProfiles,
	setInvestorProfiles,
}) => {
	const classes = useStyles()

	const [deleteConfirmation, setDeleteConfirmation] = useState(false)
	const [documentName, setDocumentName] = useState('')

	const { successInvestorProfileUploadDocuments, investorProfileUploadDocumentsData } = useSelector(
		(state) => state.investorProfileUploadDocuments,
	)
	const { successInvestorProfileDeleteDocument, investorProfileDeleteDocumentData } = useSelector(
		(state) => state.investorProfileDeleteDocument,
	)

	useEffect(() => {
		if (successInvestorProfileUploadDocuments) {
			const dataUpdated = investorProfiles.map((item) => {
				if (item._id === investorProfileUploadDocumentsData.profileId) {
					item.documents = [item.documents, investorProfileUploadDocumentsData.documents]
						.flat(3)
						.filter((item) => item !== undefined)
				}
				return item
			})

			setInvestorProfiles(dataUpdated)
		}
	}, [successInvestorProfileUploadDocuments])

	useEffect(() => {
		if (successInvestorProfileDeleteDocument) {
			const dataUpdated = investorProfiles.map((item) => {
				if (item._id === investorProfileDeleteDocumentData.profileId) {
					item.documents = item.documents.filter(
						(document) => document.documentName !== matchDocumentName(documentName),
					)
				}
				return item
			})
			setInvestorProfiles(dataUpdated)
		}
	}, [successInvestorProfileDeleteDocument])

	const handleDeleteProfileDocuments = (documentName) => {
		setDeleteConfirmation(true)
		setDocumentName(documentName)
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={investorProfileUploadDocumentModal}
			keepMounted
			onClose={handleCloseInvestorProfileUploadDocumentModal}
		>
			{deleteConfirmation ? (
				<DeleteProfileDocumentForm
					showInvestorProfileUploadDocumentInfo={showInvestorProfileUploadDocumentInfo}
					setDeleteConfirmation={setDeleteConfirmation}
					documentName={documentName}
					setDocumentName={setDocumentName}
					handleCloseInvestorProfileUploadDocumentModal={handleCloseInvestorProfileUploadDocumentModal}
				/>
			) : (
				<UploadProfileDocumentForm
					showInvestorProfileUploadDocumentInfo={showInvestorProfileUploadDocumentInfo}
					handleCloseInvestorProfileUploadDocumentModal={handleCloseInvestorProfileUploadDocumentModal}
					handleDeleteProfileDocuments={handleDeleteProfileDocuments}
					documentName={documentName}
					setDocumentName={setDocumentName}
					setDeleteConfirmation={setDeleteConfirmation}
				/>
			)}
		</Dialog>
	)
}

export default UploadProfileDocumentsModal
