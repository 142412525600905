import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { matchDocumentName } from 'shared/helpers/functions-helpers'
import { INVESTOR_PROFILE_DELETE_DOCUMENT_RESET } from 'local_redux/constants/investorProfileConstants'
import { deleteInvestorProfileDocument } from 'local_redux/actions/investorProfileActions'
import styles from '../styles/uploadProfileDocumentsModalStyles'

const useStyles = makeStyles(styles)

const DeleteProfileDocumentForm = ({
  setDeleteConfirmation,
  documentName,
  setDocumentName,
  showInvestorProfileUploadDocumentInfo,
  handleCloseInvestorProfileUploadDocumentModal,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    loadingInvestorProfileDeleteDocument,
    successInvestorProfileDeleteDocument,
    errorInvestorProfileDeleteDocument,
  } = useSelector((state) => state.investorProfileDeleteDocument)

  useEffect(() => {
    let timeOut
    if (successInvestorProfileDeleteDocument) {
      timeOut = setTimeout(() => {
        setDocumentName('')
        handleCloseInvestorProfileUploadDocumentModal()
      }, 1500)
    }

    return () => clearTimeout(timeOut)
  }, [successInvestorProfileDeleteDocument])

  useEffect(() => {
    return () => dispatch({ type: INVESTOR_PROFILE_DELETE_DOCUMENT_RESET })
  }, [dispatch])

  const handleDeleteDocument = (e) => {
    e.preventDefault()

    dispatch(
      deleteInvestorProfileDocument({
        profileId: showInvestorProfileUploadDocumentInfo._id,
        documentName: matchDocumentName(documentName),
      })
    )
  }

  return (
    <DialogContent>
      <DialogTitle id='project-modal-delete-title' disableTypography className={classes.modalHeader}>
        <GridContainer style={{ display: 'flex', justifyContent: 'space-around' }}>
          <GridItem xs={10}>
            <h4>Eliminar documento de perfil</h4>
          </GridItem>
          <GridItem xs={2} className={classes.closeButtonContainer}>
            <Button
              justIcon
              className={classes.closeButton}
              key='close'
              aria-label='Close'
              color='transparent'
              onClick={handleCloseInvestorProfileUploadDocumentModal}
            >
              <Close className={classes.modalClose} />
            </Button>
          </GridItem>
        </GridContainer>
      </DialogTitle>
      <form onSubmit={handleDeleteDocument} id='delete-profile-documents'>
        <DialogContent id='project-modal-delete-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                Está seguro que quiere eliminar el documento <b>{documentName}</b> relacionado con el perfil{' '}
                <b>{showInvestorProfileUploadDocumentInfo.businessName}</b>
              </Typography>
            </GridItem>
          </GridContainer>
        </DialogContent>
        {errorInvestorProfileDeleteDocument && (
          <GridContainer>
            <GridItem xs={12}>
              <SnackbarContent message={errorInvestorProfileDeleteDocument} color='danger' />
            </GridItem>
          </GridContainer>
        )}
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={12}>
              <Button onClick={() => setDeleteConfirmation(false)}>Cancelar</Button>
              <Button
                type='submit'
                form='delete-profile-documents'
                // onClick={() => handleDeleteDocument(showUploadDocumentInfo?.endofprojectdocumentsList[0]._id)}
                disabled={loadingInvestorProfileDeleteDocument}
                color={successInvestorProfileDeleteDocument ? 'success' : 'primary'}
              >
                {loadingInvestorProfileDeleteDocument
                  ? 'Eliminando'
                  : successInvestorProfileDeleteDocument
                  ? 'Eliminado'
                  : 'Eliminar'}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </DialogContent>
  )
}

export default DeleteProfileDocumentForm
