import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import ReactTablePagination from './components/ReactTablePagination'

const CustomReactTable = ({
	columns,
	data,
	displayButtons = false,
	keepHeader = true,
	fetchData,
	nextPage,
	prevPage,
	totalPages,
	success,
	warningText = 'No se encontró información en la base de datos',
}) => {
	return (
		<>
			{data.length < 1 && success ? (
				<CustomWarningMessage text={warningText} />
			) : (
				<ReactTablePagination
					data={data}
					columns={columns}
					fetchData={fetchData}
					nextPage={nextPage}
					prevPage={prevPage}
					totalPages={totalPages}
					displayButtons={displayButtons}
					keepHeader={keepHeader}
				/>
			)}
		</>
	)
}

export default CustomReactTable
