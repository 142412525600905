import { Box, makeStyles, Typography } from '@material-ui/core'
import classNames from 'classnames'
import styles from './styles/customBoxStyles'

const useStyles = makeStyles(styles)

const CustomBox = ({ title, children, className = '' }) => {
	const classes = useStyles()

	return (
		<Box className={classNames(classes.cardRoot, className)}>
			<div className={classes.cardTitle}>
				<Typography className={classes.customBoxTitle}>{title}</Typography>
			</div>
			{children}
		</Box>
	)
}

export default CustomBox