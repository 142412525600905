export const types = {
	date: 'date',
	text: 'text',
	number: 'number',
	currency: 'currency',
}
export const sizes = {
	xs: 'xs', //100px
	sm: 'sm', //150px
	md: 'md', //200px
	lg: 'lg', //350px
}
export const defaultDateFormat = 'dd/MM/yyyy'
export const defaultAlignCell = 'left'
export const defaultNullDate = 'Sin Fecha'
export const defaultNullValue = 'No establecido'
export const defaultEmptyDataText = 'No se encontraron datos.'
export const defaultType = types.text
export const defaultSize = sizes.sm
export const defaultNameToSaveFilter = 'filter-screen'
export const actionText = 'actions'
