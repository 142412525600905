const userPermissionsInitialState = {
  isAdmin: false,
  isContabilidad: false,
  isInformation: false,
  isInvestorRelations: false,
  isManagement: false,
  isMarketing: false,
  isSuper: false,
}

export default userPermissionsInitialState
