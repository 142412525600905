import { useSelector } from 'react-redux'
import axios from 'axios'
import { Divider, IconButton, makeStyles } from '@material-ui/core'
import { Delete, Photo, Visibility } from '@material-ui/icons'
import { List, ListItem, ListItemText } from '@mui/material'
import { generateTokenCode } from 'shared/helpers/generateTokenCode'
import isValidUrl from 'shared/helpers/valid-url'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from './styles/documentsHanlerStyles'

const useStyles = makeStyles(styles)

const DocumentsHandler = ({ fields }) => {
	const classes = useStyles()
	const { userInfo } = useSelector((state) => state.userLogin)

	const selectFileHandler = (currentRef) => {
		currentRef.current.click()
	}

	const handleFileChange = (e) => {
		e.preventDefault()
		const handleAddFile = fields.find((item) => item.field === e.target.name)
		handleAddFile.setValue(e.target.files)
	}

	const handleFileRemove = (field) => {
		const handleRemoveFile = fields.find((item) => item.field === field)
		handleRemoveFile.setValue('')
	}

	return (
		<>
			<List dense={true}>
				{fields &&
					fields.map((item, i) => (
						<div key={i}>
							<ListItem
								className={classes.listItem}
								secondaryAction={
									<>
										{item.onlyView ? (
											<IconButton
												aria-label='visibility'
												size='small'
												onClick={() => {
													window.open(
														item.isDocument
															? isValidUrl(item.value)
																? item.value
																: item.value.startsWith('/')
																? getUrlToOpenFile(item.value, userInfo)
																: `${axios.defaults.baseURL}/${item.value}?code=${generateTokenCode(userInfo.token)}`
															: // : `${axios.defaults.baseURL}/${item.value}}`
															  getUrlToOpenFile(item.value, userInfo),
													)
												}}
											>
												<Visibility fontSize='inherit' />
											</IconButton>
										) : !item.value && item.value.length < 1 && !item.onlyView ? (
											<IconButton aria-label='add' size='small' onClick={() => selectFileHandler(item.reference)}>
												{item.uploadIcon ? item.uploadIcon : <Photo fontSize='inherit' />}
											</IconButton>
										) : (
											<>
												<IconButton
													aria-label='visibility'
													size='small'
													onClick={() => {
														window.open(
															typeof item.value === 'object'
																? URL.createObjectURL(item.value[0])
																: item.isDocument
																? isValidUrl(item.value)
																	? item.value
																	: item.value.startsWith('/')
																	? getUrlToOpenFile(item.value, userInfo)
																	: `${axios.defaults.baseURL}/${item.value}?code=${generateTokenCode(userInfo.token)}`
																: // : `${axios.defaults.baseURL}/${item.value}}`,
																  getUrlToOpenFile(item.value, userInfo),
														)
													}}
												>
													<Visibility fontSize='inherit' />
												</IconButton>
												<IconButton
													aria-label='delete'
													size='small'
													onClick={() => {
														handleFileRemove(item.field)
													}}
												>
													<Delete fontSize='inherit' color={'error'} />
												</IconButton>
											</>
										)}
									</>
								}
							>
								<ListItemText className={classes.listItemText} primary={item.name} />
							</ListItem>
							<div className='fileinput'>
								<input
									type='file'
									name={item.field}
									id={item.field}
									accept={item.accept}
									capture='camera'
									ref={item.reference}
									onChange={handleFileChange}
								/>
							</div>
							<Divider variant='fullWidth' className={classes.divider} />
						</div>
					))}
			</List>
		</>
	)
}

export default DocumentsHandler
