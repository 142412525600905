import { defaultEmptyDataText } from '../helpers/values'
import CustomWarningMessage from 'components/CustomWarningMessage/CustomWarningMessage'
import Loader from 'components/Loader/Loader'

const EmptyPlaceholder = ({ emptyText, isLoading, isSuccess, isError, error }) => {
	return (
		<div style={{ width: '100%', marginTop: '20px' }}>
			<div style={{ textAlign: 'center' }}>
				{isLoading ? (
					<Loader config={{ justifyContent: 'center', alignItems: 'center' }} />
				) : (
					<CustomWarningMessage text={isError ? error : isSuccess && emptyText ? emptyText : defaultEmptyDataText} />
				)}
			</div>
		</div>
	)
}

export default EmptyPlaceholder
