import { Card, Divider, FormControlLabel, makeStyles, Switch } from '@material-ui/core'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import styles from './styles/userPermissionsStyles'

const useStyles = makeStyles(styles)

const UserPermissions = ({ setPermissions, permissions, modal }) => {
  const classes = useStyles()

  return (
    <Card className={classes.cardRoot}>
      <CardHeader className={classes.CardHeader}>Permisos</CardHeader>
      <CardBody>
        <GridContainer className={classes.root}>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isSuper}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isAdmin: e.target.checked, isSuper: e.target.checked })
                  }}
                  value='Super Admin'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Super Admin'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isAdmin}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isAdmin: e.target.checked })
                  }}
                  value='Admin'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Admin'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isMarketing}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isMarketing: e.target.checked })
                  }}
                  value='Marketing'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Marketing'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isContabilidad}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isContabilidad: e.target.checked })
                  }}
                  value='Contabilidad'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Contabilidad'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isInvestorRelations}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isInvestorRelations: e.target.checked })
                  }}
                  value='Investor Relations'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Investor Relations'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isManagement}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isManagement: e.target.checked })
                  }}
                  value='Management'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Management'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
          <GridItem xs={12} sm={!modal ? 4 : 6}>
            <FormControlLabel
              control={
                <Switch
                  checked={permissions.isInformation}
                  onChange={(e) => {
                    setPermissions({ ...permissions, isInformation: e.target.checked })
                  }}
                  value='Información'
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label='Información'
            />
            <Divider variant='fullWidth' className={classes.dividerSwitch} />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default UserPermissions
