import { drawerWidth, drawerMiniWidth, transition, boxShadow } from 'assets/jss/material-dashboard-pro-react'

const styles = (theme) => ({
	drawerPaper: {
		border: 'none',
		position: 'fixed',
		top: '0',
		bottom: '0',
		left: '0',
		zIndex: '1032',
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		// overflow: 'auto',
		...boxShadow,
		width: drawerWidth,
		[theme.breakpoints.up('md')]: {
			width: drawerWidth,
			position: 'fixed',
			height: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			width: drawerWidth,
			...boxShadow,
			position: 'fixed',
			display: 'block',
			top: '0',
			height: '100vh',
			right: '0',
			left: 'auto',
			zIndex: '1032',
			visibility: 'visible',
			overflowY: 'visible',
			borderTop: 'none',
			textAlign: 'left',
			paddingRight: '0px',
			paddingLeft: '0',
			transform: `translate3d(${drawerWidth}px, 0, 0)`,
			...transition,
		},
		'&:before,&:after': {
			position: 'absolute',
			zIndex: '3',
			width: '100%',
			height: '100%',
			content: '""',
			display: 'block',
			top: '0',
		},
	},
	whiteBackground: {
		color: '#000',
		'&:after': {
			background: '#c0bbac',
			opacity: '.93',
		},
	},
	drawerPaperMini: {
		[theme.breakpoints.up('md')]: {
			width: drawerMiniWidth + 'px!important',
		},
	},
	sidebarWrapper: {
		position: 'relative',
		height: 'calc(100vh - 75px)',
		overflow: 'auto',
		overflowX: 'hidden',
		width: '275px',
		zIndex: '4',
		overflowScrolling: 'touch',
		transitionProperty: 'top, bottom, width',
		transitionDuration: '.2s, .2s, .35s',
		transitionTimingFunction: 'linear, linear, ease',
		color: 'inherit',
		paddingBottom: '30px',
		'&::-webkit-scrollbar': {
			width: '6px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: 'gray',
			borderRadius: '5px',
		},
	},
	drawerPaperMini: {
		[theme.breakpoints.up('md')]: {
			width: drawerMiniWidth + 'px!important',
		},
	},
	/*  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  }, */
})

export default styles
