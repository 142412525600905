import { createRef, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles, Badge } from '@material-ui/core'
import { Assignment, Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Clearfix from 'components/Clearfix/Clearfix'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import InputsLegalPersonUpdateByAdmin from './components/InputsLegalPersonUpdateByAdmin'
import InputsPhysicalPersonUpdateByAdmin from './components/InputsPhysicalPersonUpdateByAdmin'
import { matchGender } from 'shared/matchData/matchData'
import {
	INVESTOR_PROFILE_UPDATE_RESET,
	INVESTORS_PROFILE_BY_USER_RESET,
} from 'local_redux/constants/investorProfileConstants'
import { updateInvestorProfile, getInvestorsProfileById } from 'local_redux/actions/investorProfileActions'
import InvestorProfileDocumentsForm from 'components/InvestorProfileDocumentsForm/InvestorProfileDocumentsForm'
import { findDocument, findDocumentUrl } from 'shared/helpers/functions-helpers'
import styles from './styles/updateProfileByAdminStyles'

const useStyles = makeStyles(styles)

const UpdateProfileByAdmin = ({ investorsProfileListData }) => {
	const fileInput = createRef()
	const dispatch = useDispatch()
	const classes = useStyles()
	const search = useLocation().search
	const profileId = new URLSearchParams(search).get('profileId')

	const [infoModalData, setModalData] = useState({})
	const [errorTaxResidenceFile, setErrorTaxResidenceFile] = useState('')
	const [prescribers, setPrescribers] = useState([])
	const [profileDniFront, setProfileDniFront] = useState('')
	const [profileDniBack, setProfileDniBack] = useState('')
	const [kycContract, setKycContract] = useState('')
	const [accountOwnership, setAccountOwnership] = useState('')
	const [prescriptorContractUrl, setPrescriptorContractUrl] = useState('')

	const { loadingInvestorProfileUpdate, errorInvestorProfileUpdate, successInvestorProfileUpdate } = useSelector(
		(state) => state.investorProfileUpdate,
	)
	const { successInvestorProfileById, investorProfileByIdData } = useSelector((state) => state.investorProfileById)

	useEffect(() => {
		dispatch(getInvestorsProfileById(profileId))
	}, [])
	useEffect(() => {
		if (successInvestorProfileById) {
			setModalData({
				...investorProfileByIdData,
				location: investorProfileByIdData.location || 'Madrid',
				province: investorProfileByIdData.province || 'Madrid',
				postalCode: investorProfileByIdData.postalCode || 28001,
			})

			setProfileDniFront(findDocument(investorProfileByIdData, 'profileDniFront'))
			setProfileDniBack(findDocument(investorProfileByIdData, 'profileDniBack'))
			setKycContract(findDocument(investorProfileByIdData, 'kycContract'))
			setAccountOwnership(findDocument(investorProfileByIdData, 'accountOwnership'))
			setPrescriptorContractUrl(findDocumentUrl(investorProfileByIdData, 'prescriptorContractUrl'))
		}
	}, [successInvestorProfileById])

	useEffect(() => {
		if (investorsProfileListData) {
			const prescribers = investorsProfileListData.filter((investor) => investor.isPrescriber)
			setPrescribers(prescribers)
		}
	}, [investorsProfileListData])

	useEffect(() => {
		let timeOut = ''
		if (successInvestorProfileUpdate) {
			timeOut = setTimeout(() => {
				dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
				dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
				setErrorTaxResidenceFile('')
			}, 2000)
		}

		return () => clearTimeout(timeOut)
	}, [successInvestorProfileUpdate])

	useEffect(() => {
		return () => dispatch({ type: INVESTOR_PROFILE_UPDATE_RESET })
	}, [dispatch])

	const isLegal = infoModalData?.personType === 'legal'

	const handleSelectFile = () => {
		fileInput.current.click()
	}
	const handleChangeFile = (e) => {
		e.preventDefault()

		let pdfFile = e.target.files[0]

		setModalData({ ...infoModalData, residenceCertificate: pdfFile })
	}
	const handleRemoveFile = () => {
		setModalData({ ...infoModalData, residenceCertificate: '' })
	}

	const updateInvestorProfileHandler = useCallback(
		(e) => {
			e.preventDefault()

			if (infoModalData.country !== 'España' && !infoModalData.residenceCertificate) {
				return setErrorTaxResidenceFile('Certificado de residencia fiscal obligatorio, salvo España')
			}
			const matchData = {
				...infoModalData,
				gender: matchGender(infoModalData.gender),
				profileDniFront: typeof profileDniFront !== 'object' ? '' : profileDniFront[0],
				profileDniBack: typeof profileDniBack !== 'object' ? '' : profileDniBack[0],
				kycContract: typeof kycContract !== 'object' ? '' : kycContract[0],
				accountOwnership: typeof accountOwnership !== 'object' ? '' : accountOwnership[0],
				prescriptorContractUrl: typeof prescriptorContractUrl !== 'string' ? '' : prescriptorContractUrl,
				filesToDelete: {
					profileDniFront: profileDniFront === undefined,
					profileDniBack: profileDniBack === undefined,
					kycContract: kycContract === undefined,
					accountOwnership: accountOwnership === undefined,
					prescriptorContractUrl: prescriptorContractUrl === undefined,
				},
			}

			dispatch(updateInvestorProfile(matchData))
		},
		[profileDniBack, profileDniFront, kycContract, accountOwnership, infoModalData, prescriptorContractUrl],
	)

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Assignment />
						</CardIcon>
					</CardHeader>
					<CardBody>
						{infoModalData?._id && (
							<form onSubmit={updateInvestorProfileHandler}>
								{isLegal ? (
									<InputsLegalPersonUpdateByAdmin
										userInfo={infoModalData}
										setUserInfo={setModalData}
										investorsProfileListData={prescribers}
										handleChangeFile={handleChangeFile}
										fileInput={fileInput}
										handleSelectFile={handleSelectFile}
										setErrorTaxResidenceFile={setErrorTaxResidenceFile}
									/>
								) : (
									<InputsPhysicalPersonUpdateByAdmin
										userInfo={infoModalData}
										setUserInfo={setModalData}
										investorsProfileListData={prescribers}
										handleChangeFile={handleChangeFile}
										fileInput={fileInput}
										handleSelectFile={handleSelectFile}
										setErrorTaxResidenceFile={setErrorTaxResidenceFile}
									/>
								)}
								<GridContainer spacing={3}>
									<GridItem xs={12}>
										<InvestorProfileDocumentsForm
											showInvestorProfileUploadDocumentInfo={infoModalData}
											setInvestorProfileUploadDocumentInfo={setModalData}
											profileDniFront={profileDniFront}
											setProfileDniFront={setProfileDniFront}
											profileDniBack={profileDniBack}
											setProfileDniBack={setProfileDniBack}
											kycContract={kycContract}
											setKycContract={setKycContract}
											accountOwnership={accountOwnership}
											setAccountOwnership={setAccountOwnership}
											prescriptorContractUrl={prescriptorContractUrl}
											setPrescriptorContractUrl={setPrescriptorContractUrl}
										/>
									</GridItem>
									{infoModalData.residenceCertificate && (
										<GridItem xs={12}>
											<Badge
												badgeContent={
													<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveFile()}>
														<Close />
													</Button>
												}
												className={classes.fileBadge}
											>
												<div className={classes.fileDiv}>
													{typeof infoModalData.residenceCertificate === 'object'
														? infoModalData.residenceCertificate.name.length > 20
															? infoModalData.residenceCertificate.name.slice(1, 10) +
															  '...' +
															  infoModalData.residenceCertificate.name.slice(
																	infoModalData.residenceCertificate.name.length - 6,
																	infoModalData.residenceCertificate.name.length,
															  )
															: infoModalData.residenceCertificate.name
														: infoModalData.residenceCertificate.length > 20
														? infoModalData.residenceCertificate.slice(1, 10) +
														  '...' +
														  infoModalData.residenceCertificate.slice(
																infoModalData.residenceCertificate.length - 6,
																infoModalData.residenceCertificate.length,
														  )
														: infoModalData.residenceCertificate}
												</div>
											</Badge>
										</GridItem>
									)}
									{errorTaxResidenceFile && !infoModalData.residenceCertificate && (
										<GridItem xs={12} sm={12}>
											<SnackbarContent message={errorTaxResidenceFile} color='warning' />
										</GridItem>
									)}
									{errorInvestorProfileUpdate && (
										<GridItem xs={12} sm={12}>
											<SnackbarContent message={errorInvestorProfileUpdate} color='danger' />
										</GridItem>
									)}
								</GridContainer>
								<GridContainer>
									<GridItem xs={12}>
										<Button type='submit' color={successInvestorProfileUpdate ? 'success' : 'primary'} block>
											{loadingInvestorProfileUpdate
												? 'Actualizando'
												: successInvestorProfileUpdate
												? 'Perfil Actualizado'
												: 'Actualizar Perfil'}
										</Button>
										<Clearfix />
									</GridItem>
								</GridContainer>
							</form>
						)}
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default UpdateProfileByAdmin
