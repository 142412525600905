const styles = {
  cardRoot: {
    border: '4px solid #f2f1eb',
    borderRadius: '10px',
    padding: '20px',
    position: 'relative',
  },
  cardTitle: {
    position: 'absolute',
    top: '-18px',
    padding: '2px',
    borderRadius: '5px',
    left: '50px',
    backgroundColor: 'white',
  },
  customBoxTitle: { fontSize: '16px', fontWeight: '600', padding: '0px 15px', color: '#00000099' },
}

export default styles
