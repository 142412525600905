import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IconButton } from '@material-ui/core'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Edit from '@material-ui/icons/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import InvestorProfileUpdateModalByAdmin from 'components/InvestorProfileUpdateModalByAdmin/InvestorProfileUpdateModalByAdmin'
import DeleteProfileInvestorModal from './components/DeleteProfileInvestorModal'
import { matchPersonType } from 'shared/matchData/matchData'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { getInvestorsProfileList } from 'local_redux/actions/investorProfileActions'

const InvestorProfileListByUser = ({ profiles }) => {
	const dispatch = useDispatch()

	const [investorProfileModal, setInvestorProfileModal] = useState(false)
	const [showInvestorUpdateInfo, setShowInvestorUpdateInfo] = useState({})
	const [deleteProfileInvestorModal, setDeleteProfileInvestorModal] = useState(false)
	const [showDeleteProfileInvestorModalInfo, setShowDeleteProfileInvestorModalInfo] = useState({})

	const { successInvestorsProfileList, investorsProfileListData } = useSelector((state) => state.investorsProfileList)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!successInvestorsProfileList) {
			dispatch(getInvestorsProfileList())
		}
	}, [successInvestorsProfileList])

	const handleCloseInvestorUpdateModal = () => {
		setInvestorProfileModal(false)
		setShowInvestorUpdateInfo({})
	}
	const handleCloseDeleteProfileInvestorModal = () => {
		setDeleteProfileInvestorModal(false)
		setShowDeleteProfileInvestorModalInfo({})
	}
	return (
		<>
			<List sx={{ width: '100%', bgcolor: 'background.paper' }}>
				{profiles.map((profile) => (
					<ListItem
						key={profile._id}
						secondaryAction={
							<>
								<IconButton
									edge='end'
									aria-label='update'
									onClick={() => [setShowInvestorUpdateInfo(profile), setInvestorProfileModal(true)]}
								>
									<Edit />
								</IconButton>
								<IconButton
									edge='end'
									aria-label='delete'
									onClick={() => [setShowDeleteProfileInvestorModalInfo(profile), setDeleteProfileInvestorModal(true)]}
								>
									<DeleteIcon color='error' />
								</IconButton>
							</>
						}
					>
						<ListItemAvatar>
							<Avatar
								variant={profile.personType !== 'physical' ? 'rounded' : ''}
								// src={`${axios.defaults.baseURL}/${profile?.logo}`}
								src={getUrlToOpenFile(profile?.logo, userInfo)}
								alt='...'
							/>
						</ListItemAvatar>
						<ListItemText primary={`${profile.businessName}`} secondary={matchPersonType(profile.personType)} />
					</ListItem>
				))}
			</List>
			{investorProfileModal && (
				<InvestorProfileUpdateModalByAdmin
					handleCloseInvestorUpdateModal={handleCloseInvestorUpdateModal}
					investorProfileModal={investorProfileModal}
					showInvestorUpdateInfo={showInvestorUpdateInfo}
					investorsProfileListData={investorsProfileListData}
				/>
			)}
			{deleteProfileInvestorModal && (
				<DeleteProfileInvestorModal
					handleCloseDeleteProfileInvestorModal={handleCloseDeleteProfileInvestorModal}
					deleteProfileInvestorModal={deleteProfileInvestorModal}
					showDeleteProfileInvestorModalInfo={showDeleteProfileInvestorModalInfo}
				/>
			)}
		</>
	)
}

export default InvestorProfileListByUser
