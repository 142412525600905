import { makeStyles } from '@material-ui/core'
import { ReportGmailerrorred } from '@mui/icons-material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from './styles/customWarningMessageStyles'
const useStyles = makeStyles(styles)

const CustomWarningMessage = ({ text }) => {
  const classes = useStyles()

  return (
    <GridContainer className={classes.gridSearch}>
      <GridItem xs={12} md={12} className={classes.iconSearch}>
        <ReportGmailerrorred />
      </GridItem>
      <GridItem xs={12} md={12}>
        <p>{text}</p>
      </GridItem>
    </GridContainer>
  )
}

export default CustomWarningMessage
