const styles = () => ({
	iconButton: {
		color: '#757575 !important',
		borderRadius: '33px !important',
		lineHeight: '20px',
		textDecoration: 'none !important',
		margin: 0,
		padding: '10px !important',
		backgroundColor: '#f2f2f2 !important',
		boxShadow:
			'0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12)',
		'& svg': {
			width: 22,
			height: 22,
		},
		'&:hover,&:focus': {
			textDecoration: 'none',
			background: '#f9f6f6 !important',
			boxShadow:
				'0 14px 26px -50px rgba(153, 153, 153, 0.42), 0 2px 5px 1px rgba(0, 0, 0, 0.12), 0 0px 10px 2px rgba(153, 153, 153, 0.2)',
		},
	},
	menu: {
		'& .MuiPaper-root': {
			maxHeight: '90vh',
			maxWidth: '90%',
			border: '0',
			margin: '2px 0 0',
			padding: '5px 0',
			borderRadius: '3px',
			boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
			listStyle: 'none',
			minWidth: '160px',
			fontSize: '14px',
			textAlign: 'left',
			backgroundColor: '#FFF',
			backgroundClip: 'padding-box',
		},
		'& .MuiList-root': {
			padding: '0 !important',
		},
	},
	menuItem: {
		clear: 'both',
		color: '#333 !important',
		height: '100%',
		margin: '0 5px !important',
		display: 'block !important',
		padding: '10px 20px !important',
		fontSize: '13px !important',
		minHeight: 'unset !important',
		transition: 'all 150ms linear',
		fontFamily: 'Roboto, Helvetica, Arial, sans-serif !important',
		fontWeight: '400 !important',
		lineHeight: '1.5em !important',
		whiteSpace: 'nowrap !important',
		borderRadius: '2px !important',
		'&:hover': {
			boxShadow: '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(209, 205, 194, 0.4)',
			backgroundColor: '#d1cdc2 !important',
			color: '#FFF !important',
		},
	},
})

export default styles
