import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ListItem, ListItemText, makeStyles } from '@material-ui/core'
import { ExitToApp } from '@mui/icons-material'
import { logout } from 'local_redux/actions/userActions'
import styles from '../styles/sidebarStyle'

const useStyles = makeStyles(styles)

const LogoutOption = ({ itemText, collapseItemText }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <ListItem className={`${classes.item} ${classes.collapseItem}`} onClick={() => dispatch(logout())}>
      <NavLink to='/' className={`${classes.itemLink} ${classes.collapseItemLink}`}>
        <span className={classes.collapseItemMini}>
          <ExitToApp />
        </span>
        <ListItemText primary='Salir' disableTypography={true} className={`${itemText} ${collapseItemText}`} />
      </NavLink>
    </ListItem>
  )
}

export default LogoutOption
