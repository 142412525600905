import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles } from '@material-ui/core/styles'
import styles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'

const ModalConfirmationInvestment = ({ title, text, setAlert }) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  return (
    <SweetAlert
      success
      style={{ display: 'block', marginTop: '-100px' }}
      title={title}
      onConfirm={() => setAlert(null)}
      confirmBtnCssClass={classes.button + ' ' + classes.success}
      cancelBtnCssClass={classes.button + ' ' + classes.danger}
    >
      <div style={{ textAlign: 'center' }}>{text}</div>
    </SweetAlert>
  )
}

export default ModalConfirmationInvestment
