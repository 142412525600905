import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import WorkPhotosSlider from './components/WorkPhotosSlider'
import styles from './styles/videoEvolutionProjectStyles'

const useStyles = makeStyles(styles)

const VideoEvolutionProjectModal = ({ setOpenVideo, openVideo, dataVideo }) => {
	const classes = useStyles()

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openVideo}
			keepMounted
			onClose={() => setOpenVideo(false)}
			aria-labelledby='investment-modal-download-title'
			aria-describedby='investment-modal-download-description'
		>
			<DialogTitle id='investment-modal-download-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenVideo(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>
					Video de Evolución del Proyecto <strong>{dataVideo.projectAddress}</strong>
				</h4>
			</DialogTitle>
			<DialogContent id='investment-modal-download-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<WorkPhotosSlider images={dataVideo.images} classes={classes} />
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: '50px' }}>
						<Button color='primary' onClick={() => setOpenVideo(false)}>
							Salir
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default VideoEvolutionProjectModal
