import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemText, Collapse, Icon, makeStyles } from '@material-ui/core'
import { List } from '@mui/material'
import {
  AddCircle,
  AssignmentInd,
  AttachFile,
  EuroSymbol,
  List as ListIcon,
  ListAlt,
  SafetyDivider,
  PersonAddAlt1,
  People,
} from '@mui/icons-material'
import styles from '../styles/sidebarStyle'

const useStyles = makeStyles(styles)

const InvestorOptions = ({
  userInfo,
  itemText,
  collapseItemText,
  setAdminCollapse,
  adminCollapse,
  preRegisterCollapse,
  setPreRegisterCollapse,
}) => {
  const classes = useStyles()

  const [investorProfile, setInvestorProfile] = useState(false)

  return (
    <>
      <ListItem className={classes.collapseItem}>
        <NavLink to={'/admin/projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
          <Icon className={classes.itemIcon}>{<ListIcon />}</Icon>
          <ListItemText primary={'Proyectos para Invertir'} disableTypography={true} className={itemText} />
        </NavLink>
      </ListItem>
      <ListItem className={classes.collapseItem}>
        <NavLink to={'/admin/my-investments'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
          <Icon className={classes.itemIcon}>{<EuroSymbol />}</Icon>
          <ListItemText primary={'Mis Inversiones'} disableTypography={true} className={itemText} />
        </NavLink>
      </ListItem>
      {userInfo.isPrescriber && (
        <>
          <ListItem className={classes.collapseItem}>
            <NavLink
              to={'#!'}
              className={classes.itemLink + ' ' + classes.collapseItemLink}
              onClick={(e) => {
                e.preventDefault()
                setAdminCollapse(!adminCollapse)
              }}
            >
              <span className={classes.collapseItemMini}>
                <SafetyDivider />
              </span>
              <ListItemText
                primary={'Prescripción'}
                secondary={
                  <b
                    className={
                      classes.caret + ' ' + classes.userCaret + ' ' + (adminCollapse ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={collapseItemText}
              />
            </NavLink>
            <Collapse in={adminCollapse} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={collapseItemText}>
                  <NavLink
                    to={'/admin/material-prescribers'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <AttachFile />
                    </span>
                    <ListItemText
                      primary='Material para Prescriptores'
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>

                <ListItem className={collapseItemText}>
                  <NavLink to={'/admin/my-prescribers'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                    <span className={classes.collapseItemMini}>
                      <ListIcon />
                    </span>
                    <ListItemText
                      primary='Inversiones de mis Inversores'
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
          <ListItem className={classes.collapseItem}>
            <NavLink
              to={'#!'}
              className={classes.itemLink + ' ' + classes.collapseItemLink}
              onClick={(e) => {
                e.preventDefault()
                setPreRegisterCollapse(!preRegisterCollapse)
              }}
            >
              <span className={classes.collapseItemMini}>
                <People />
              </span>
              <ListItemText
                primary={'Pre-Registros'}
                secondary={
                  <b
                    className={
                      classes.caret + ' ' + classes.userCaret + ' ' + (preRegisterCollapse ? classes.caretActive : '')
                    }
                  />
                }
                disableTypography={true}
                className={collapseItemText}
              />
            </NavLink>
            <Collapse in={preRegisterCollapse} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={collapseItemText}>
                  <NavLink
                    to={'/admin/pre-register-investor'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <PersonAddAlt1 />
                    </span>
                    <ListItemText
                      primary='Pre-registrar inversor'
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>

                <ListItem className={collapseItemText}>
                  <NavLink
                    to={'/admin/pre-investor-list'}
                    className={classes.itemLink + ' ' + classes.collapseItemLink}
                  >
                    <span className={classes.collapseItemMini}>
                      <ListIcon />
                    </span>
                    <ListItemText
                      primary='Listado de Pre-Registros'
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </>
      )}
      <ListItem className={classes.collapseItem}>
        <NavLink
          to={'#!'}
          className={classes.itemLink + ' ' + classes.collapseItemLink}
          onClick={(e) => {
            e.preventDefault()
            setInvestorProfile(!investorProfile)
          }}
        >
          <span className={classes.collapseItemMini}>
            <AssignmentInd />
          </span>

          <ListItemText
            primary={'Administrar Mis Perfiles'}
            secondary={
              <b
                className={classes.caret + ' ' + classes.userCaret + ' ' + (investorProfile ? classes.caretActive : '')}
              />
            }
            disableTypography={true}
            className={collapseItemText}
          />
        </NavLink>
        <Collapse in={investorProfile} unmountOnExit>
          <List className={classes.list + ' ' + classes.collapseList}>
            <ListItem className={collapseItemText}>
              <NavLink to={'/admin/investor-register'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
                <span className={classes.collapseItemMini}>
                  <AddCircle />
                </span>
                <ListItemText primary='Registrar Perfil' disableTypography={true} className={collapseItemText} />
              </NavLink>
            </ListItem>
            <ListItem className={collapseItemText}>
              <NavLink
                to={'/admin/investor-profile-my-list'}
                className={classes.itemLink + ' ' + classes.collapseItemLink}
              >
                <span className={classes.collapseItemMini}>
                  <ListAlt />
                </span>
                <ListItemText
                  primary='Mis Perfiles de Inversor'
                  disableTypography={true}
                  className={collapseItemText}
                />
              </NavLink>
            </ListItem>
          </List>
        </Collapse>
      </ListItem>
    </>
  )
}

export default InvestorOptions
