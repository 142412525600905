import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import AuthLayout from 'layouts/Auth/Auth'
import AdminLayout from 'layouts/Admin/Admin'
import PublicLayout from 'layouts/Public/Public'
import authRoutes from 'routes/authRoutes'
import publicRoutes from 'routes/publicRoutes'
import routes from 'routes/routes'

const App = () => {
	const { userInfo } = useSelector((state) => state.userLogin)

	const checkIsDefaultPassword = (routes) => {
		if (userInfo?.isDefaultPassword) {
			return routes.filter((route) => {
				return route.path === '/user-page'
			})
		}

		return routes
	}
	const getAuthRoutes = () => {
		return authRoutes.map((prop, key) => {
			if (prop.layout === '/auth') {
				return <Route path={prop.layout + prop.path} element={prop.component} key={key} />
			} else {
				return null
			}
		})
	}
	const getPublicRoutes = () => {
		return publicRoutes.map((prop, key) => {
			if (prop.layout === '/public') {
				return <Route path={prop.layout + prop.path} element={prop.component} key={key} />
			} else {
				return null
			}
		})
	}

	const getAdminRoutes = () => {
		const filteredRoutes = checkIsDefaultPassword(routes)
		return filteredRoutes.map((prop, key) => {
			if (prop.layout === '/admin') {
				return <Route path={prop.layout + prop.path} element={prop.component} key={key} />
			} else {
				return null
			}
		})
	}

	return (
		<Router>
			<Routes>
				<Route path='/auth' element={<AuthLayout />}>
					{getAuthRoutes()}
				</Route>
				<Route path='/admin' element={<AdminLayout />}>
					{getAdminRoutes()}
					<Route path='*' element={<Navigate to='/admin/user-page' replace />} />
				</Route>
				<Route path='/public' element={<PublicLayout />}>
					{getPublicRoutes()}
					<Route path='*' element={<Navigate to='/admin/user-page' replace />} />
				</Route>
				<Route path='*' element={<Navigate to='/auth/login' replace />} />
			</Routes>
		</Router>
	)
}

export default App
