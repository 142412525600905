import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { ListItem, ListItemText, Collapse, makeStyles } from '@material-ui/core'
import { List } from '@mui/material'
import styles from '../styles/userDropdownStyles'

const useStyles = makeStyles(styles)

const UserDropdown = ({ userInfo, miniActive, isMiniActive }) => {
	const classes = useStyles()

	const [userName, setUserName] = useState('')
	const [openAvatar, setOpenAvatar] = useState(false)

	useEffect(() => {
		setUserName(`${userInfo.name} ${userInfo?.lastName || ''}`)
	}, [userInfo])

	const photo = cx({
		[classes.photo]: true,
		[classes.roundPhoto]: !['legal'].includes(userInfo.personType),
	})
	const isMini = miniActive && isMiniActive

	const itemText = cx({
		[classes.itemText]: true,
		[classes.itemTextMini]: isMini,
	})
	const collapseItemText = cx({
		[classes.collapseItemText]: true,
		[classes.collapseItemTextMini]: isMini,
	})

	return (
		<div className={classes.user + ' ' + classes.whiteAfter}>
			<div className={photo}>
				<img src={getUrlToOpenFile(userInfo?.avatar, userInfo)} className={classes.avatarImg} alt={userName} />
			</div>
			<List className={classes.list}>
				<ListItem className={classes.item + ' ' + classes.userItem}>
					<NavLink
						to={'#'}
						className={classes.itemLink + ' ' + classes.userCollapseButton}
						onClick={() => setOpenAvatar(!openAvatar)}
					>
						<ListItemText
							primary={userName.length > 21 ? userName.slice(0, 21) + '...' : userName}
							secondary={
								<b
									className={classes.caret + ' ' + classes.userCaret + ' ' + (openAvatar ? classes.caretActive : '')}
								/>
							}
							disableTypography={true}
							className={itemText + ' ' + classes.userItemText}
						/>
					</NavLink>
					<Collapse in={openAvatar} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							<ListItem className={classes.collapseItem}>
								<NavLink to='/admin/user-page' className={classes.itemLink + ' ' + classes.userCollapseLinks}>
									<span className={classes.collapseItemMini}>MP</span>
									<ListItemText primary='Mis Datos' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
						</List>
					</Collapse>
				</ListItem>
			</List>
		</div>
	)
}

export default UserDropdown
