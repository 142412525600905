import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import styles from '../styles/reactTableStyles'

const useStyles = makeStyles(styles)

const TableActions = ({ theadRef, headerGroups, getTableBodyProps, page, prepareRow, columnSize, firstTrRef }) => {
	const classes = useStyles()

	return (
		<>
			<thead
				id='thead'
				ref={theadRef}
				className={'rt-thead -header'}
				style={{ zIndex: window.innerWidth < 960 ? 1031 : 1033, background: '#fff' }}
			>
				{headerGroups.map((headerGroup) => (
					<tr ref={firstTrRef} id='first-tr' {...headerGroup.getHeaderGroupProps()} className='rt-tr'>
						{headerGroup.headers.map((column, key) => {
							columnSize[key] = column.size
							return (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className={classnames(
										'rt-th rt-resizable-header',
										{
											'-cursor-pointer': true,
											'-sort-asc': column.isSorted && !column.isSortedDesc,
											'-sort-desc': column.isSorted && column.isSortedDesc,
										},
										column.size !== undefined &&
											(column.size == 'small'
												? classes.widthSmall
												: column.size == 'big'
												? classes.widthBig
												: classes.widthMedium),
									)}
								>
									<div className='rt-resizable-header-content'>{column.render('Header')}</div>
									<div>
										{headerGroup.headers.length - 1 === key && headerGroup.headers.Header === 'Acciones'
											? null
											: column.canFilter
											? column.render('Filter')
											: null}
									</div>
								</th>
							)
						})}
					</tr>
				))}
			</thead>
			<tbody
				id={'tbody'}
				style={{ fontSize: '0.72rem', fontWeight: 400 }}
				{...getTableBodyProps()}
				className='rt-tbody'
			>
				{page.map((row, i) => {
					prepareRow(row)
					return (
						<tr
							{...row.getRowProps()}
							className={
								classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 }) + ` ${classes.hoverCell}`
							}
						>
							{row.cells.map((cell, i) => {
								return (
									<td
										{...cell.getCellProps()}
										className={classnames(
											'rt-td',
											headerGroups[0]?.headers[i]?.size !== undefined &&
												(headerGroups[0]?.headers[i]?.size == 'small'
													? classes.widthSmall
													: headerGroups[0]?.headers[i]?.size == 'big'
													? classes.widthBig
													: classes.widthMedium),
										)}
									>
										{cell.render('Cell')}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		</>
	)
}

export default TableActions
