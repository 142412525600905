import { createContext, useState } from 'react'

export const FilterReactVirtualizedTableContext = createContext()
export const FilterReactVirtualizedTableProvider = ({ children }) => {
	const [filteredRows, setFilteredRows] = useState({})
	const [filteredColumns, setFilteredColumns] = useState({})

	const saveFilteredDataRows = ({ tableId, value }) => {
		setFilteredRows((prev) => ({
			...prev,
			[tableId]: value,
		}))
	}
	const saveFilteredDataColumns = ({ tableId, value }) => {
		setFilteredColumns((prev) => ({
			...prev,
			[tableId]: value?.filter((e) => e.accessor !== 'actions'),
		}))
	}
	const getFilteredDataRows = ({ tableId }) => {
		return filteredRows[tableId] || []
	}
	const getFilteredDataColumns = ({ tableId }) => {
		return filteredColumns[tableId] || []
	}
	const resetFilteredData = ({ tableId }) => {
		const newFilteredRows = { ...filteredRows }
		const newFilteredColumns = { ...filteredColumns }

		delete newFilteredRows[tableId]
		delete newFilteredColumns[tableId]
		setFilteredRows(newFilteredRows)
		setFilteredRows(newFilteredColumns)
	}

	return (
		<FilterReactVirtualizedTableContext.Provider
			value={{
				getFilteredDataRows,
				saveFilteredDataRows,
				getFilteredDataColumns,
				saveFilteredDataColumns,
				resetFilteredData,
				filteredRows,
			}}
		>
			{children}
		</FilterReactVirtualizedTableContext.Provider>
	)
}
