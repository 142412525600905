import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import CustomInput from 'components/CustomInput/CustomInput'
import Button from '../CustomButtons/Button'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import SnackbarContent from '../Snackbar/SnackbarContent'
import { deleteUser } from 'local_redux/actions/userActions'
import { USER_LIST_RESET, USER_DELETE_RESET } from 'local_redux/constants/userConstants'
import { INVESTOR_LIST_RESET } from 'local_redux/constants/investorConstant'
import styles from './styles/deleteUserModalStyles'

const useStyles = makeStyles(styles)

const DeleteUserModal = ({ handleCloseDeleteUserModal, deleteUserModal, showDeleteUserInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [phrase, setPhrase] = useState('')

  const { successUserDelete, errorUserDelete, loadingUserDelete } = useSelector((state) => state.userDelete)

  useEffect(() => {
    if (successUserDelete) {
      setTimeout(() => {
        dispatch({ type: USER_LIST_RESET })
        dispatch({ type: INVESTOR_LIST_RESET })
        dispatch({ type: USER_DELETE_RESET })
        handleCloseDeleteUserModal()
      }, 1000)
    }
  }, [successUserDelete])

  useEffect(() => {
    dispatch({ type: USER_DELETE_RESET })
  }, [])

  const handleDeleteUser = (e) => {
    e.preventDefault()
    dispatch(deleteUser(showDeleteUserInfo?._id))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteUserModal}
      keepMounted
      onClose={handleCloseDeleteUserModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <form onSubmit={handleDeleteUser}>
        <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseDeleteUserModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>Eliminar Usuario</h4>
        </DialogTitle>

        <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Está seguro de que desea eliminar al usuario <strong>{showDeleteUserInfo.email}</strong>?
              </Typography>
              <br />
              <Typography>Una vez que un usuario se elimina de forma permanente, no se puede recuperar.</Typography>
              <br />
              <Typography>Escriba la siguiente frase para habilitar el botón Eliminar Usuario.</Typography>
              <p className={classes.deletePhrase}>absolutamente seguro</p>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                inputProps={{
                  autoFocus: true,
                  placeholder: 'Escriba la frase aquí',
                  type: 'text',
                  autoComplete: 'off',
                  onChange: (e) => setPhrase(e.target.value.toLowerCase()),
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
          </GridContainer>
          {errorUserDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorUserDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={6}>
              <Button onClick={handleCloseDeleteUserModal} block>
                Cancelar
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button
                disabled={phrase !== 'absolutamente seguro'}
                type='onSubmit'
                color={successUserDelete ? 'success' : 'primary'}
                block
              >
                {loadingUserDelete ? 'Eliminando' : successUserDelete ? 'Eliminado' : 'Eliminar'}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DeleteUserModal
