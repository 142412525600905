import { FormControl, Select, MenuItem, makeStyles } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import styles from '../styles/reactTableStyles'

const useStyles = makeStyles(styles)

const TableHeader = ({
	pageOptions,
	pageIndex,
	customGoToPage,
	pageSize,
	customPageSize,
	customPrevManage,
	canPreviousPage,
	customNextManage,
	canNextPage,
	displayButtons,
}) => {
	const classes = useStyles()

	const numberOfRowsData = !displayButtons ? [5, 10, 20, 25, 50, 100, 500] : [Infinity]
	const valuesValidates = (pageOptions) => {
		let options = []
		for (let i = 0; i < pageOptions.length; i++) {
			options.push(pageOptions[i])
		}
		return options.length ? options : [0]
	}

	return (
		<div className='-pagination'>
			<div className='-previous'>
				<button type='button' onClick={customPrevManage} disabled={!canPreviousPage} className='-btn'>
					Anterior
				</button>
			</div>
			<div className='-center'>
				<GridContainer className={classes.gridContainer}>
					<GridItem xs={12} sm={6} md={4}>
						{valuesValidates && (
							<FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
								<Select
									MenuProps={{
										className: classes.selectMenu,
									}}
									classes={{
										select: classes.select,
									}}
									value={pageIndex}
									onChange={customGoToPage}
									inputProps={{
										name: 'pageSelect',
										id: 'page-select',
									}}
								>
									{valuesValidates(pageOptions).map((prop) => {
										return (
											<MenuItem
												key={prop}
												classes={{
													root: classes.selectMenuItem,
													selected: classes.selectMenuItemSelected,
												}}
												value={prop}
											>
												Página {prop + 1}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						)}
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<FormControl fullWidth className={classes.selectFormControl + ' ' + classes.formControlMargins}>
							<Select
								MenuProps={{
									className: classes.selectMenu,
								}}
								classes={{
									select: classes.select,
								}}
								value={pageSize}
								onChange={customPageSize}
								inputProps={{
									name: 'numberOfRows',
									id: 'number-of-rows',
								}}
							>
								{numberOfRowsData.map((prop) => {
									return (
										<MenuItem
											key={prop}
											classes={{
												root: classes.selectMenuItem,
												selected: classes.selectMenuItemSelected,
											}}
											value={prop}
										>
											{prop} lineas
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</GridItem>
				</GridContainer>
			</div>
			<div className='-next'>
				<button type='button' onClick={() => customNextManage()} disabled={!canNextPage} className='-btn'>
					Siguiente
				</button>
			</div>
		</div>
	)
}

export default TableHeader
