import { useEffect, useRef } from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import CustomInput from 'components/CustomInput/CustomInput'
import styles from '../styles/reactTableStyles'

const useStyles = makeStyles(styles)

const ColumnFilter = (props) => {
	const classes = useStyles()
	const inputRef = useRef(null)

	useEffect(() => {
		if (props.column.filterValue && props.state.pageIndex !== 0) {
			props.state.pageIndex = 0
		}
	}, [props.column.filterValue])
	const handleChange = (e) => {
		const inputValue = e.target.value
		let formattedDate = inputValue.replace(/[^0-9]/g, '')

		if (formattedDate.length > 4) {
			formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(2, 4)}/${formattedDate.slice(4)}`
		} else if (formattedDate.length > 2) {
			formattedDate = `${formattedDate.slice(0, 2)}/${formattedDate.slice(2)}`
		}
		props.column.setFilter(formattedDate.length > 0 ? formattedDate : null)
		inputRef.current.focus() // mueve el cursor al final del input
	}

	return (
		<>
			{props.column.type === 'date' ? (
				<TextField
					ref={inputRef}
					value={props.column.filterValue}
					placeholder='Buscar...'
					onChange={handleChange}
					required={true}
					className={classes.areaField}
				/>
			) : (
				<CustomInput
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: props.column.filterValue || '',
						onChange: (e) => {
							if (document.getElementById('mainPanel').scrollTop >= 296.14) {
								document.getElementById('mainPanel').scroll(0, 296.14)
							}
							props.column.setFilter(e.target.value || undefined)
						},
						placeholder: 'Buscar...',
					}}
				/>
			)}
		</>
	)
}

export default ColumnFilter
