import { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import styles from './styles/optionsMenuTableStyles'
import { Tooltip } from '@mui/material'
const useStyles = makeStyles(styles)

const OptionsMenuTable = ({ options, icon, text }) => {
	const classes = useStyles()

	const [anchorEl, setAnchorEl] = useState(null)
	const open = useMemo(() => Boolean(anchorEl), [anchorEl])
	if (!icon) icon = <MoreVertIcon />
	// const open = Boolean(anchorEl)
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<Tooltip id='tooltip-top' title={text ? text : ''} placement='top'>
			<IconButton
				aria-label='more'
				id='options-button'
				aria-controls={open ? 'long-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup='true'
				onClick={handleClick}
				className={classes.iconButton}
			>
				{icon}
			</IconButton>
			<Menu
				id='long-menu'
				elevation={0}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				MenuListProps={{
					'aria-labelledby': 'long-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				className={classes.menu}
			>
				{options.map((option, key) => (
					<MenuItem
						key={key}
						onClick={handleClose}
						disabled={Boolean(option?.props?.disabled)}
						className={classes.menuItem}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</Tooltip>
	)
}

export default OptionsMenuTable
