const styles = {
	container: {
		width: '100%',
	},
	input: {
		border: 'none',
		outline: 'none',
		width: '100%',
	},
	error: {
		border: 'solid 1px red',
		outline: 'red',
	},
}

export default styles
