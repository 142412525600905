import { makeStyles } from '@material-ui/core'
import logo from 'assets/img/logo-white.png'
import styles from '../styles/brandStyles'

const useStyles = makeStyles(styles)

const MiniBrand = () => {
  const classes = useStyles()

  return (
    <div className={classes.logo + ' ' + classes.whiteAfter}>
      <a href='/' className={classes.logoMini}>
        <img src={logo} alt='logo' className={classes.img} />
      </a>
    </div>
  )
}

export default MiniBrand
