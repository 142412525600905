import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'

const styles = {
  ...customCheckboxRadioSwitch,
  ...buttonStyle,
  root: {
    padding: '10px',
  },
  cardRoot: { backgroundColor: '#ededed', boxShadow: 'none' },
  CardHeader: { fontWeight: 700 },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: '#3C4858',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
  },
  '@media (min-width: 600px)': {
    dividerSwitch: {
      display: 'none !important',
    },
  },
}

export default styles
