import axios from 'axios'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import DateFnsUtils from '@date-io/date-fns'
import es from 'date-fns/locale/es'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Meta from 'components/Meta/Meta'
import App from 'App'
import { FilterReactTableProvider } from 'contexts/filterReactTableContext'
import { FilterReactVirtualizedTableProvider } from 'contexts/filterReactVirtualizedTableContext'
import { axiosResponseFunctionMiddleware } from 'shared/helpers/axios-response-function'
import store from 'local_redux/store'
import 'assets/scss/material-ui-react.scss?v=1.0.0'

axios.defaults.baseURL = process.env.REACT_APP_API
axios.interceptors.response.use((response) => response, axiosResponseFunctionMiddleware)
const theme = createTheme()

ReactDOM.render(
	<>
		<Meta />
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
					<FilterReactVirtualizedTableProvider>
						<FilterReactTableProvider>
							<App />
						</FilterReactTableProvider>
					</FilterReactVirtualizedTableProvider>
				</MuiPickersUtilsProvider>
			</ThemeProvider>
		</Provider>
	</>,
	document.getElementById('root'),
)
