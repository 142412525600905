import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import styles from '../styles/notificationModalStyles'
import { getNotificationById } from 'local_redux/actions/notificationActions'
import { format } from 'date-fns'

const useStyles = makeStyles(styles)

const NotificationModal = ({ handleCloseNotificationModal, notificationModal, showNotificationInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(getNotificationById(showNotificationInfo._id))
  }, [])

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={notificationModal}
      keepMounted
      onClose={handleCloseNotificationModal}
      aria-labelledby='user-notification-title'
      aria-describedby='user-notification-description'
    >
      <form>
        <DialogTitle id='user-notification-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseNotificationModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>{showNotificationInfo.title}</h4>
        </DialogTitle>

        <DialogContent id='user-notification-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography variant='body1' textAlign='end' style={{ fontSize: '14px' }}>
                <b> Notificado el {format(new Date(showNotificationInfo.created), 'dd-MM-yyyy')}</b>
              </Typography>
              <Typography style={{ marginTop: '10px' }}>{showNotificationInfo.message}</Typography>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={12}>
              <Button onClick={handleCloseNotificationModal} block>
                Ok
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default NotificationModal
