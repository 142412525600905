import { makeStyles } from '@material-ui/core'
import { Email, Phone } from '@material-ui/icons'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import { useEffect, useState } from 'react'
import { matchProjectStatus } from 'shared/matchData/matchData'
import styles from './styles/lasInvestmentsByProfileStyles'

const useStyles = makeStyles(styles)

const LastInvestmentsByProfile = ({ filterState }) => {
  const classes = useStyles()
  const [data, setData] = useState([])

  useEffect(() => {
    const lastInvestments = filterState.investments?.map((item) => {
      return {
        id: item._id,
        projectAddress: item.project.projectAddress,
        incomeRecivedDate: item.incomeRecivedDate
          ? item.incomeRecivedDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1')
          : 'No Recibido',
        amount: item.amount,
        status: matchProjectStatus(item.project.status),
      }
    })

    setData(lastInvestments)
  }, [filterState])

  return (
    <>
      {filterState && (
        <GridItem xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a style={{ color: '#b8b1a3' }} href={`tel:${filterState.investments[0]?.investorProfile?.phone}`}>
            <Phone style={{ color: '#b8b1a3', margin: '0 5px' }} />
          </a>
          <a style={{ color: '#b8b1a3' }} href={`mailto:${filterState.investments[0]?.investorProfile?.email}`}>
            <Email style={{ color: '#b8b1a3' }} />
          </a>
        </GridItem>
      )}
      <GridItem xs={12}>
        {data && data.length > 0 ? (
          <ReactTable
            columns={[
              {
                Header: 'Proyecto',
                accessor: 'projectAddress',
                size: 'medium',
                Filter: false,
              },
              {
                Header: 'Fecha inversión',
                accessor: 'incomeRecivedDate',
                size: 'medium',
                Filter: false,
              },
              {
                Header: 'Importe',
                accessor: 'amount',
                size: 'small',
                Filter: false,
              },
              {
                Header: 'Estado Inversión',
                accessor: 'status',
                size: 'medium',
                Filter: false,
              },
            ]}
            displayButtons={true}
            data={data}
          />
        ) : (
          <Loader classNames={classes.loader} />
        )}
      </GridItem>
    </>
  )
}

export default LastInvestmentsByProfile
