import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	...modalStyle(theme),
	portrait: {
		height: '100%',
		width: 'auto',
		zIndex: 10,
	},
	landscape: {
		height: 'auto',
		width: '100%',
		zIndex: 10,
	},
	videoContainer: {
		aspectRatio: '4/3',
		width: '100%',
		maxWidth: '90vw',
		position: 'relative',
		backgroundColor: 'black',
	},
	loaderContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		gap: '5px',
	},
	imageBody: {
		width: '100%',
		height: '100%',
		position: 'relative',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		transition: 'all 0.3s ease-in-out',
	},
	blurBackground: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex: 5,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		transform: 'scale(1.2)',
		filter: 'blur(20px)',
	},
	btnPlay: {
		textAlign: 'center',
		position: 'absolute',
		top: '40%',
		left: '44%',
		zIndex: 11,
	},
	iconBtn: {
		background: 'rgba(0,0,0,0.3)',
		'&:hover': {
			background: 'rgba(0,0,0,0.7)',
		},
	},
	icon: {
		fontSize: '5rem',
		color: '#fff',
		'&:hover': {
			opacity: 0.9,
		},
	},
	countText: {
		position: 'absolute',
		zIndex: 11,
		top: '10px',
		right: '10px',
		backgroundColor: '#c0bbac',
		color: 'white',
		fontWeight: 500,
		padding: '0 15px',
		borderRadius: '10px',
	},
	'@media screen and (max-width: 680px)': {
		btnPlay: {
			left: '44%',
			top: '40%',
		},
		icon: {
			fontSize: '3rem',
		},
	},
	'@media screen and (max-width: 480px)': {
		btnPlay: {
			left: '44%',
			top: '40%',
		},
	},
	'@media screen and (max-width: 600px)': {
		modal: {
			width: '100%',
		},
	},
})

export default styles
