import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	FormControl,
	Select as Selectable,
	MenuItem,
	InputLabel,
	CircularProgress,
	makeStyles,
} from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import { getRetentionList } from 'local_redux/actions/retentionActions'
import styles from './styles/retentionStyles'

const useStyles = makeStyles(styles)

const Retention = ({ setInfo, info, setErrorTaxResidenceFile }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { loadingRetentionList, successRetentionList, retentionListData } = useSelector((state) => state.retentionList)
	useEffect(() => {
		if (!successRetentionList) {
			dispatch(getRetentionList())
		}
	}, [successRetentionList])

	return (
		<>
			<GridItem xs={12} sm={5} style={{ margin: '14px 0 0 0' }}>
				{loadingRetentionList ? (
					<CircularProgress size={'20px'} style={{ color: '#333333' }} />
				) : (
					successRetentionList && (
						<FormControl fullWidth>
							<InputLabel htmlFor='select-country'>Pais de Residencia Fiscal *</InputLabel>
							<Selectable
								MenuProps={{
									className: classes.selectMenu,
								}}
								className={classes.select}
								value={info?.country}
								onChange={(e) => {
									setErrorTaxResidenceFile('')
									setInfo({
										...info,
										country: e.target.value,
										retention: `${retentionListData.find((item) => item.country === e.target.value).retention}%`,
									})
								}}
								inputProps={{
									name: 'select-country',
									id: 'select-country',
								}}
							>
								<MenuItem
									disabled
									classes={{
										root: classes.selectMenuItem,
									}}
								>
									Selecciona uno
								</MenuItem>
								{retentionListData.map((entityType, index) => (
									<MenuItem
										value={entityType?.country || ''}
										key={index}
										classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
									>
										{entityType.country}
									</MenuItem>
								))}
							</Selectable>
						</FormControl>
					)
				)}
			</GridItem>
		</>
	)
}

export default Retention
