import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { AppBar, Toolbar, Hidden, makeStyles } from '@material-ui/core'
import { Alert, Snackbar } from '@mui/material'
import { Menu, MoreVert, ViewList } from '@material-ui/icons'
import AdminNavbarLinks from './AdminNavbarLinks'
import Button from 'components/CustomButtons/Button'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle'

const useStyles = makeStyles(styles)

const AdminNavbar = ({ color, miniActive, sidebarMinimize, handleDrawerToggle, brandText }) => {
	const classes = useStyles()
	const navigate = useNavigate()

	const [alert, setAlert] = useState(true)
	const [mfaMessage, setMfaMessage] = useState('Se recomienda habilitar la autenticación multifactor.')

	const { userInfo } = useSelector((state) => state.userLogin)

	const appBarClasses = cx({
		[' ' + classes[color]]: color,
	})

	useEffect(() => {
		if (userInfo && userInfo.mfaSecretConfig?.base32) {
			setAlert(false)
		} else {
			if (userInfo?.role === 'investor') {
				setMfaMessage('Se recomienda habilitar la autenticación multifactor.')
			} else {
				setMfaMessage(
					'Debe activar la autenticación multifactor antes del 15/12/2024 para poder seguir accediendo a ShareHolders.',
				)
			}
			setAlert(true)
		}
	}, [userInfo])

	return (
		<AppBar className={classes.appBar + appBarClasses}>
			<Toolbar className={classes.container}>
				<Hidden smDown implementation='css'>
					<div className={classes.sidebarMinimize}>
						{miniActive ? (
							<Button justIcon round color='white' onClick={sidebarMinimize}>
								<ViewList className={classes.sidebarMiniIcon} />
							</Button>
						) : (
							<Button justIcon round color='white' onClick={sidebarMinimize}>
								<MoreVert className={classes.sidebarMiniIcon} />
							</Button>
						)}
					</div>
				</Hidden>
				<div className={classes.flex}>
					{/* Here we create navbar brand, based on route name */}
					<Button href='#' className={classes.title} color='transparent'>
						{brandText}
					</Button>
				</div>
				<Hidden smDown implementation='css'>
					<AdminNavbarLinks />
				</Hidden>
				<Hidden mdUp implementation='css'>
					<Button
						className={classes.appResponsive}
						color='transparent'
						justIcon
						aria-label='open drawer'
						onClick={handleDrawerToggle}
					>
						<Menu />
					</Button>
				</Hidden>
			</Toolbar>
			<Snackbar
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				open={alert}
				onClose={() => {
					setAlert(false)
				}}
			>
				<Alert
					onClose={() => {
						setAlert(false)
					}}
					severity='warning'
					sx={{ width: '100%' }}
				>
					<b>Multi Factor Authentication</b>: {mfaMessage}{' '}
					{window.location.pathname !== '/admin/security' && (
						<span onClick={() => navigate('/admin/security')} style={{ color: 'blue', cursor: 'pointer' }}>
							Seguridad MFA
						</span>
					)}
				</Alert>
			</Snackbar>
		</AppBar>
	)
}

AdminNavbar.propTypes = {
	color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
	brandText: PropTypes.string,
	miniActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	sidebarMinimize: PropTypes.func,
}

export default AdminNavbar
