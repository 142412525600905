const styles = () => ({
	formControl: {
		width: '100%',
		margin: '2px 0 0 0',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
})

export default styles
