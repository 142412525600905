import { createContext, useState } from 'react'

export const FilterReactTableContext = createContext()

export const FilterReactTableProvider = ({ children }) => {
  const [filteredRows, setfilteredRows] = useState([])
  const [filteredcolumns, setFilteredColumns] = useState([])

  return (
    <FilterReactTableContext.Provider value={{ filteredRows, setfilteredRows, filteredcolumns, setFilteredColumns }}>
      {children}
    </FilterReactTableContext.Provider>
  )
}
