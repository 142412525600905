import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from '../../CustomButtons/Button'
import { matchPersonType } from 'shared/matchData/matchData'
import { sendPrescriptorContrat } from 'local_redux/actions/investmentActions'
import { SEND_PRESCRIPTOR_CONTRACT_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/prescriberConfirmModal'

const useStyles = makeStyles(styles)

const PrescriberConfirmModal = ({ open, handleCloseModal, user, setUserInfo }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const { loadingPrescriptorContract, errorPrescriptorContract, successPrescriptorContract } = useSelector(
		(state) => state.prescriptorContract,
	)

	useEffect(() => {
		return () => dispatch({ type: SEND_PRESCRIPTOR_CONTRACT_RESET })
	}, [])
	useEffect(() => {
		if (successPrescriptorContract) {
			setUserInfo({ ...user, isPrescriber: true })
			setTimeout(() => {
				dispatch({ type: SEND_PRESCRIPTOR_CONTRACT_RESET })
				handleCloseModal()
			}, 500)
		}
	}, [successPrescriptorContract])

	const submit = () => {
		dispatch(sendPrescriptorContrat(user))
	}
	const onClose = () => {
		if (loadingPrescriptorContract) {
			return
		} else {
			handleCloseModal()
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted={false}
			onClose={onClose}
			aria-labelledby='modal-delete-title'
			aria-describedby='modal-delete-description'
			styles={{ pointerEvent: loadingPrescriptorContract ? 'none' : 'all' }}
		>
			<DialogTitle id='modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={onClose}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Enviar contrato de Prescriptor</h4>
			</DialogTitle>
			<DialogContent>
				<p>
					¿Desea enviar el <b>Contrato de Prescriptor - {matchPersonType(user.personType)}</b> al perfil inversor de{' '}
					<b>{user.businessName}</b> al la cuenta de correo electrónico <b>{user.email}</b>?
				</p>
				{errorPrescriptorContract && <p>No se pudo enviar el contrato al inversor.</p>}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button
							onClick={handleCloseModal}
							disabled={successPrescriptorContract || loadingPrescriptorContract}
							block
						>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='button'
							disabled={successPrescriptorContract || loadingPrescriptorContract}
							onClick={submit}
							color={successPrescriptorContract ? 'success' : 'primary'}
							block
						>
							{loadingPrescriptorContract ? 'Enviando contrato...' : successPrescriptorContract ? 'Enviado' : 'Enviar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default PrescriberConfirmModal
