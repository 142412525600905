import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import styles from './styles/editableInputStyles'
import { Tooltip } from '@mui/material'

const useStyles = makeStyles(styles)

const EditableInput = ({
	additionalText = '',
	isDisabled = false,
	error,
	onBlur = () => {},
	setValue: setInitialValue = () => {},
	type = 'text',
	value: inicialValue,
	tooltipTitle = '',
}) => {
	const classes = useStyles()

	const [isEdit, setIsEdit] = useState(false),
		[value, setValue] = useState(inicialValue)

	useEffect(() => {
		setValue(inicialValue)
	}, [inicialValue])

	return (
		<div
			className={classes.container}
			onDoubleClick={() => {
				!isEdit && setIsEdit(true)
			}}
		>
			{isEdit ? (
				<input
					className={classNames(classes.input, !!error ? classes.error : '')}
					value={value}
					disabled={isDisabled}
					autoFocus
					onBlur={() => {
						setIsEdit(false)

						if (inicialValue !== value) {
							onBlur(value)
						}
					}}
					onChange={(e) => {
						setInitialValue(e.target.value)
						setValue(e.target.value)
					}}
					type={type}
				/>
			) : (
				<Tooltip title={tooltipTitle} disableHoverListener={!tooltipTitle}>
					<span>{`${value} ${additionalText}`.trim()}</span>
				</Tooltip>
			)}
		</div>
	)
}

export default EditableInput
