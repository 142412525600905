import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'

export const loadAllImages = async ({ images, userInfo }) => {
	const allImages = images.map(
		(image) =>
			new Promise((res) => {
				const url = image.url || image.imageUrl
				const img = new Image()
				img.onload = () => {
					res(img)
				}
				img.onerror = () => res(null)
				img.src = getUrlToOpenFile(url, userInfo)
			}),
	)
	const blobImagesLoaded = await Promise.all(allImages)
	return blobImagesLoaded.filter((e) => e)
}
