import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Place } from '@material-ui/icons'
import Typography from '@mui/material/Typography'
import GridItem from 'components/Grid/GridItem'
import { matchCurrencyToDecimal, matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from './styles/cardProjectInfoStyles'

const useStyles = makeStyles(styles)

const CardProjectInfo = ({ infoModalData, totalInvertible, investmentSum }) => {
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<>
			<GridItem xs={12} sm={5}>
				<img
					// src={`${axios.defaults.baseURL}/${infoModalData?.coverImage}`}
					src={getUrlToOpenFile(infoModalData?.coverImage, userInfo)}
					alt={`${infoModalData?.coverImage}`}
					width='90%'
					style={{ borderRadius: '4px' }}
				/>
			</GridItem>
			<GridItem xs={12} sm={7}>
				{matchCurrencyToDecimal(totalInvertible) < 1 && investmentSum ? (
					<Typography variant='body2'>
						Dinero invertido
						<span className={classes.maxInvertibleNum}>{` ${matchNumberToCurrency(investmentSum)}`}</span>
					</Typography>
				) : (
					<Typography variant='body2'>
						Usted puede invertir: <span className={classes.maxInvertibleNum}>{totalInvertible}</span>
					</Typography>
				)}

				<Typography variant='overline' className={`${classes.stats} ${classes.productStats}`}>
					<Place style={{ fontSize: '13px' }} />
					{`${infoModalData?.projectAddress}`}
				</Typography>
			</GridItem>
		</>
	)
}

export default CardProjectInfo
