import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import CardProjectInfo from 'components/CardProjectInfo/CardProjctInfo'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import InvestmentList from 'components/InvestmentsList/InvestmentsList'
import Loader from 'components/Loader/Loader'
import SelectProfilesByAdmin from 'components/SelectProfilesByAdmin/SelectProfilesByAdmin'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import {
	replaceStringToNum,
	matchCurrencyToDecimal,
	matchNumberToCurrency,
} from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import amountInWords from 'shared/amountInWords/amountInWords'
import business from 'config/business/business'
import { INVESTMENT_REGISTER_RESET } from 'local_redux/constants/investmentConstants'
import { PROJECT_BY_ID_RESET } from 'local_redux/constants/projectConstant'
import { registerInvestment } from 'local_redux/actions/investmentActions'
import { registerLog } from 'local_redux/actions/logActions'
import { getProjectById } from 'local_redux/actions/projectAction'
import styles from './styles/investentByAdminModalStyles'

const useStyles = makeStyles(styles)

const InvestmentByAdminModal = ({
	handleCloseInvestmentByAdminModal,
	investmentByAdminModal,
	showInvestmentByAdminId,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [infoModalData, setModalData] = useState({ profile: {} })
	const [auxState, setAuxState] = useState(undefined)
	const [totalInvertible, setTotalInvertible] = useState(0)
	const [investmentSum, setInvestmentSum] = useState(0)
	const [minAmountError, setMinAmountError] = useState(false)

	const { errorInvestmentRegister, investmentRegisterData, investmentRegisterSuccess, loadingInvestmentRegister } =
		useSelector((state) => state.investmentRegister)
	const { loadingProjectById, projectByIdData, successProjectById } = useSelector((state) => state.projectById)
	const { investorsProfileListData } = useSelector((state) => state.investorsProfileList)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!showInvestmentByAdminId._id) {
			dispatch(getProjectById(showInvestmentByAdminId))
		} else {
			setAuxState({ ...showInvestmentByAdminId, profile: {} })
		}
	}, [])
	useEffect(() => {
		if (successProjectById && !showInvestmentByAdminId._id) {
			setAuxState(projectByIdData)
		}
	}, [successProjectById])
	useEffect(() => {
		if (auxState) {
			let total = 0
			auxState.investments.forEach((item) => {
				total = total + parseFloat(replaceStringToNum(item.amount))
			})
			let amountInvertible = matchCurrencyToDecimal(auxState.maxInvertible) - total
			setTotalInvertible(matchNumberToCurrency(amountInvertible))
			setInvestmentSum(total)
			setModalData({ ...auxState, profile: {} })
		}
	}, [auxState])
	useEffect(() => {
		if (investmentRegisterSuccess) {
			// eslint-disable-next-line no-use-before-define
			handleInvestmentLog()
			dispatch({ type: INVESTMENT_REGISTER_RESET })
			setMinAmountError(false)
			handleCloseInvestmentByAdminModal()
		}
	}, [investmentRegisterSuccess])
	useEffect(() => {
		// eslint-disable-next-line no-use-before-define
		errorInvestmentRegister && handleInvestmentLog(true)
	}, [errorInvestmentRegister])
	useEffect(() => {
		if (!showInvestmentByAdminId._id) {
			return () => {
				dispatch({ type: PROJECT_BY_ID_RESET })
				dispatch({ type: INVESTMENT_REGISTER_RESET })
			}
		} else {
			return () => {
				dispatch({ type: INVESTMENT_REGISTER_RESET })
			}
		}
	}, [dispatch])

	const handleInvestmentData = (e) => {
		e.preventDefault()

		if (Number(infoModalData.amount) < business.MIN_AMOUNT_INVERTIBLE) {
			setMinAmountError(true)
		}
		const data = {
			projectId: infoModalData._id,
			amount: infoModalData.amount,
			amountInWords: amountInWords(infoModalData.amount, 'EUROS'),
			profileId: infoModalData.profile._id,
		}

		dispatch(registerInvestment(data))
	}
	const handleInvestmentLog = (error) => {
		let profileInfo = investorsProfileListData.filter((investor) => investor._id == infoModalData.profile._id)
		let logInfo = {
			investors: {
				id: profileInfo[0]._id,
				...profileInfo[0],
			},
			project: {
				id: infoModalData._id,
				...infoModalData,
			},
			user: {
				id: userInfo._id,
				...userInfo,
			},
		}
		if (!error) {
			logInfo.type = {
				type: 'action',
				actionType: 'INVESTMENT_REGISTER_SUCCESS',
			}
			logInfo.investment = {
				id: investmentRegisterData._id,
				...investmentRegisterData,
			}
			dispatch(registerLog(logInfo))
		} else {
			logInfo.type = {
				type: 'error',
				error: {
					errorType: 'INVESTMENT_REGISTER_FAIL',
					errorMessage: errorInvestmentRegister,
				},
			}
			logInfo.investment = {
				amount: matchNumberToCurrency(Number(infoModalData.amount)),
			}
			dispatch(registerLog(logInfo))
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal + ' ' + classes.modalResponsive,
			}}
			open={investmentByAdminModal}
			keepMounted
			onClose={handleCloseInvestmentByAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseInvestmentByAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4 className={classes.modalTitle}>Invertir en este proyecto</h4>
			</DialogTitle>
			<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
				{loadingProjectById ? (
					<GridContainer>
						<GridItem xs={12}>
							<Loader
								message={'Cargando proyecto'}
								config={{ marginTop: '30px', with: '100%', alignItems: 'center' }}
							/>
						</GridItem>
					</GridContainer>
				) : (
					<form onSubmit={handleInvestmentData} id='submitInvestmentForm'>
						<GridContainer>
							<SelectProfilesByAdmin infoModalData={infoModalData} setModalData={setModalData} investmentModal={true} />
							{infoModalData.profile._id && (
								<>
									{infoModalData.profile.canNotInvest ? (
										<GridItem xs={12}>
											<div>
												<h4 style={{ textAlign: 'center' }}>
													Este perfil está desactivado para invertir. ¿Está seguro que quiere realizar la inversión?
												</h4>

												<Button
													onClick={() => {
														setModalData((prev) => ({ ...prev, profile: { ...prev.profile, canNotInvest: false } }))
													}}
													block
												>
													Si
												</Button>
											</div>
										</GridItem>
									) : (
										<>
											<CardProjectInfo
												infoModalData={infoModalData}
												totalInvertible={totalInvertible}
												investmentSum={investmentSum}
											/>
											<GridContainer>
												<GridItem xs={12} md={4}>
													<CustomInput
														error={
															Number(infoModalData.amount) > matchCurrencyToDecimal(totalInvertible.toString()) ||
															minAmountError
														}
														labelText={'Importe de la Inversión *'}
														id='investment-amount'
														step='0.01'
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															value: infoModalData?.amount || '',
															onChange: (e) => {
																setMinAmountError(false)
																setModalData({ ...infoModalData, amount: e.target.value })
															},
															type: 'number',
															required: true,
														}}
													/>
												</GridItem>
												<GridItem xs={12} md={8}>
													<CustomInput
														labelText='Importe en letras de la Inversión '
														id='investment-number-to-words'
														formControlProps={{
															fullWidth: true,
														}}
														inputProps={{
															value: amountInWords(infoModalData?.amount || '', 'EUROS'),
															type: 'text',
															required: true,
															disabled: true,
														}}
													/>
												</GridItem>
											</GridContainer>
											{minAmountError && (
												<GridItem xs={12} style={{ marginTop: '15px' }}>
													<Typography align='center'>
														Usted esta invirtiendo un monto inferior al requerido de {business.MIN_AMOUNT_INVERTIBLE}{' '}
														Euros.
													</Typography>
												</GridItem>
											)}
										</>
									)}
								</>
							)}
						</GridContainer>
						{errorInvestmentRegister && (
							<GridContainer>
								<GridItem xs={12}>
									<SnackbarContent message={errorInvestmentRegister} color='danger' />
								</GridItem>
							</GridContainer>
						)}
					</form>
				)}
			</DialogContent>
			{infoModalData.profile._id && !infoModalData.profile.canNotInvest && (
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={12}>
							<Button
								type='submit'
								form='submitInvestmentForm'
								color={investmentRegisterSuccess ? 'success' : 'primary'}
								disabled={loadingInvestmentRegister}
								block
							>
								{loadingInvestmentRegister ? 'Invirtiendo...' : 'Invertir'}
							</Button>
						</GridItem>
						<GridItem xs={12}>
							<InvestmentList infoModalData={infoModalData} investmentModalComponent={true} />
						</GridItem>
					</GridContainer>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default InvestmentByAdminModal
