import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionDetails, Avatar, makeStyles } from '@material-ui/core'
import { Typography } from '@mui/material'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/noteCardStyles'

const useStyles = makeStyles(styles)

const NoteCard = ({ note }) => {
	const classes = useStyles()
	const { userSender } = note
	const [showMore, setShowMore] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<GridItem container className={classes.noteRoot}>
			<GridItem className={classes.noteAvatar}>
				{/* <Avatar src={`${axios.defaults.baseURL}/${userSender?.avatar}`} alt='...' style={{ width: 18, height: 18 }} /> */}
				<Avatar src={getUrlToOpenFile(userSender?.avatar, userInfo)} alt='...' style={{ width: 18, height: 18 }} />
			</GridItem>
			<GridItem className={classes.noteGridContainer}>
				<div className={classes.noteContainer}>
					<Typography className={classes.noteHeader}>
						{userSender?.name.substring(
							0,
							userSender?.name.indexOf(' ') < 0 ? userSender.name.length : userSender?.name.indexOf(' '),
						)}
					</Typography>
					<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
						<AccordionDetails className={classes.accordionNoPadding}>
							<Typography className={classes.noteBody}>
								{note?.noteBody.length > 200 ? (
									<>
										{showMore ? note?.noteBody : note?.noteBody.substring(0, 200)}
										{showMore ? '' : '...'}
										<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
											{showMore ? 'Ver menos' : 'Ver mas'}
										</b>
									</>
								) : (
									note?.noteBody
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Typography className={classes.noteFooter}>{note?.fullTimestamp}</Typography>
				</div>
			</GridItem>
		</GridItem>
	)
}

export default NoteCard
