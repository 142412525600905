import { useState } from 'react'
import { makeStyles, FormControlLabel, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { Switch } from '@mui/material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import AddressAutoComplete from './components/AddressAutoComplete'
import styles from './styles/filterDownloadContractsModalStyles'

const useStyles = makeStyles(styles)

const FilterDownloadContracts = ({
	setNewProjectState,
	newProjectState,
	setNewInvestorState,
	newInvestorState,
	projectsList,
	handleOnChangeInvestorsFilter,
	loadingContractsInfoToDownload,
	handleSelectContract,
	filterDate,
	setFilterDate,
	investors,
	handleOnChangeProjectsFilter,
	projectListByDate,
	contractsInfo,
	handleSelectContractByDate,
}) => {
	const classes = useStyles()
	const { startDate, endDate } = filterDate

	const [selectedDate, setSelectedDate] = useState(true)
	const [selectedProject, setSelectedProject] = useState(false)
	const [selectedInvestor, setSelectedInvestor] = useState(false)

	const start = startDate ? true : false
	const end = endDate ? true : false

	return (
		<>
			<GridItem xs={12}>
				<GridContainer>
					<GridItem xs={12}>
						<FormControlLabel
							style={{ padding: '10px', marginLeft: 0 }}
							labelPlacement='start'
							label='Filtro por fecha'
							classes={{
								label: classes.label,
							}}
							control={
								<Switch
									checked={selectedDate}
									onChange={(e) => setSelectedDate(e.target.checked)}
									color='success'
									classes={{
										switchBase: classes.switchBase,
										thumb: classes.switchIcon,
										track: classes.switchBar,
									}}
								/>
							}
						/>
					</GridItem>
					<GridItem xs={12}>
						<FormControlLabel
							style={{ padding: '10px', marginLeft: 0 }}
							labelPlacement='start'
							label='Filtro por proyecto'
							classes={{
								label: classes.label,
							}}
							control={
								<Switch
									checked={selectedProject}
									onChange={(e) => setSelectedProject(e.target.checked)}
									color='success'
									classes={{
										switchBase: classes.switchBase,
										thumb: classes.switchIcon,
										track: classes.switchBar,
									}}
								/>
							}
						/>
					</GridItem>
					<GridItem xs={12}>
						<FormControlLabel
							style={{ padding: '10px', marginLeft: 0 }}
							labelPlacement='start'
							label='Filtro por inversor'
							classes={{
								label: classes.label,
							}}
							control={
								<Switch
									checked={selectedInvestor}
									onChange={(e) => setSelectedInvestor(e.target.checked)}
									color='success'
									classes={{
										switchBase: classes.switchBase,
										thumb: classes.switchIcon,
										track: classes.switchBar,
									}}
								/>
							}
						/>
					</GridItem>
				</GridContainer>
				{selectedDate && (
					<GridContainer>
						<GridItem xs={12} sm={6} style={{ margin: '12px 0 0 0' }}>
							<DatePicker
								fullWidth
								disableFuture
								value={filterDate.startDate}
								onChange={(e) => setFilterDate({ ...filterDate, startDate: e, endDate: null })}
								label='Fecha Inicio *'
								className={classes.dateInput}
							/>
						</GridItem>

						<GridItem xs={12} sm={6} style={{ margin: '12px 0 0 0' }}>
							<DatePicker
								fullWidth
								open={start && !end}
								disableFuture
								minDate={filterDate.startDate}
								minDateMessage={'Por favor revise este valor'}
								value={filterDate.endDate}
								onChange={(e) => {
									setFilterDate({ ...filterDate, endDate: e })
								}}
								label='Fecha Fin *'
								className={classes.dateInput}
							/>
						</GridItem>
					</GridContainer>
				)}
				{projectListByDate?.length > 0 && (
					<FormControl fullWidth>
						<InputLabel id='status'>Date</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu,
							}}
							disabled={loadingContractsInfoToDownload}
							labelId='status'
							id='status'
							value={contractsInfo?.projectName}
							onChange={handleSelectContractByDate}
						>
							{projectListByDate?.map((filter, index) => (
								<MenuItem
									value={filter.label}
									key={index + 'projectsListFilterDownload'}
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected,
									}}
								>
									{filter.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)}
				{selectedProject && (
					<AddressAutoComplete
						name={'Proyecto'}
						setState={setNewProjectState}
						state={newProjectState}
						options={projectsList}
						handleChange={handleOnChangeProjectsFilter}
						isLoadingOptions={loadingContractsInfoToDownload}
						handleOptionsSelected={handleSelectContract}
					/>
				)}
				{selectedInvestor && (
					<AddressAutoComplete
						name={'Inversor'}
						setState={setNewInvestorState}
						state={newInvestorState}
						options={investors}
						handleChange={handleOnChangeInvestorsFilter}
						isLoadingOptions={loadingContractsInfoToDownload}
						handleOptionsSelected={handleSelectContract}
					/>
				)}
			</GridItem>
		</>
	)
}
export default FilterDownloadContracts
