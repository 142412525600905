import { FormControl, Select as Selectable, MenuItem, InputLabel, makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { matchGender } from 'shared/matchData/matchData'
import styles from './styles/selectInputGenderStyles'

const useStyles = makeStyles(styles)

const SelectInputGender = ({ setUserInfo, userInfo, update }) => {
	const classes = useStyles()

	return (
		<GridContainer>
			<GridItem xs={12} className={classes.root}>
				<FormControl fullWidth>
					<InputLabel htmlFor='investor-register-gender'>Género *</InputLabel>
					<Selectable
						MenuProps={{
							className: classes.selectMenu,
						}}
						className={classes.select}
						value={!update ? userInfo.gender : matchGender(userInfo.gender, true)}
						onChange={(e) => setUserInfo({ ...userInfo, gender: e.target.value })}
						inputProps={{
							name: 'investor-register-gender',
							id: 'investor-register-gender',
						}}
					>
						<MenuItem
							disabled
							classes={{
								root: classes.selectMenuItem,
							}}
						>
							Selecciona uno
						</MenuItem>
						{['Mujer', 'Hombre'].map((entityType, index) => (
							<MenuItem
								value={entityType}
								key={index}
								classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
							>
								{entityType}
							</MenuItem>
						))}
					</Selectable>
				</FormControl>
			</GridItem>
		</GridContainer>
	)
}

export default SelectInputGender
