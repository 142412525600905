import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { makeStyles, FormControl } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import { INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET } from 'local_redux/constants/investmentConstants'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import { investorsProfileByUser } from 'local_redux/actions/investorProfileActions'
import styles from './styles/selectProfilesByInvestorStyles'

const useStyles = makeStyles(styles)

const SelectProfilesByInvestor = ({ infoModalData, setModalData, investmentModal }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { successInvestorsProfileByUser, investorsProfileByUserData } = useSelector(
		(state) => state.investorsProfileByUser,
	)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!successInvestorsProfileByUser) dispatch(investorsProfileByUser(userInfo._id))
	}, [successInvestorsProfileByUser])

	useEffect(() => {
		return () => dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
	}, [dispatch])

	return (
		<>
			<GridItem xs={12}>
				{investmentModal ? (
					<>
						{!infoModalData.profile?._id && (
							<Typography variant='body1' className={classes.title}>
								{!infoModalData.projectId && 'Selecciona el perfil con el que quieres invertir'}
							</Typography>
						)}
					</>
				) : (
					<>
						<Typography variant='body1' style={{ textAlign: 'center' }} className={classes.title}>
							Seleccioná un perfil para ver sus inversiones
						</Typography>
					</>
				)}
			</GridItem>
			{investorsProfileByUserData && (
				<GridItem xs={12} style={{ marginBottom: '40px' }}>
					<FormControl fullWidth>
						<Autocomplete
							disablePortal
							id='autocomplete-profiles'
							options={investorsProfileByUserData.map(({ _id, businessName, canNotInvest }) => ({
								_id,
								label: businessName,
								canNotInvest,
							}))}
							value={investorsProfileByUserData.find((d) => d._id === infoModalData.profile?._id)?.businessName ?? ''}
							color='primary'
							onChange={(e, newValue) => {
								dispatch({ type: INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET })
								setModalData({ ...infoModalData, profile: newValue })
							}}
							className={classes.autoCompleteFormControl}
							renderInput={(params) => (
								<TextField
									id='project-status'
									name='project-status'
									label='Perfil Inversor *'
									variant='standard'
									size='small'
									placeholder='Seleccione un perfil'
									{...params}
								/>
							)}
						/>
					</FormControl>
				</GridItem>
			)}
		</>
	)
}

export default SelectProfilesByInvestor
