import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import {
	grayColor,
	primaryColor,
	primaryBoxShadow,
	whiteColor,
	hexToRgb,
	blackColor,
} from 'assets/jss/material-dashboard-pro-react.js'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

const styles = (theme) => ({
	...customCheckboxRadioSwitch,
	infoRoot: {
		border: '1px solid #d4d3d3',
		borderRadius: '6px',
		padding: '15px 10px',
		margin: '0 0 20px',
		width: '100%',
		boxShadow: '0 1px 11px 0 rgb(0 0 0 / 14%)',
	},
	stats: {
		color: grayColor[0],
		fontSize: '12px',
		lineHeight: '22px',
		display: 'inline-flex',
		'& svg': {
			position: 'relative',
			top: '4px',
			width: '16px',
			height: '16px',
			marginRight: '3px',
		},
		'& .fab,& .fas,& .far,& .fal,& .material-icons': {
			position: 'relative',
			top: '4px',
			fontSize: '16px',
			marginRight: '3px',
		},
	},
	icon: {
		color: '#333333',
		margin: '10px auto 0',
		width: '130px',
		height: '130px',
		border: '1px solid #E5E5E5',
		borderRadius: '50%',
		lineHeight: '174px',
		'& svg': {
			width: '55px',
			height: '55px',
		},
		'& .fab,& .fas,& .far,& .fal,& .material-icons': {
			width: '55px',
			fontSize: '55px',
		},
	},
	maxInvertibleNum: {
		fontWeight: '900',
	},
	filesDiv: {
		marginBottom: '20px',
	},
	imagesDiv: {
		marginBottom: '10px',
		'& img': {
			borderRadius: '6px',
			marginBottom: '10px',
			boxShadow: '0 1px 4px 0 rgb(0 0 0 / 14%)',
		},
	},
	calendaryContainer: {
		width: '280px !important',
		margin: '0px',
		'& .css-dhopo2': {
			minHeight: '195px !important',
			padding: '0 !important',
		},
	},
	ulStyles: {
		// listStyleType: 'circle',
		padding: '0 15px 0 15px',
	},
	selectFormControl: {
		margin: '7px 0 17px 0 !important',
		'& > div': {
			'&:before': {
				borderBottomWidth: '1px !important',
				borderBottomColor: grayColor[4] + '!important',
			},
			'&:after': {
				borderBottomColor: primaryColor[0] + '!important',
			},
		},
	},
	selectMenu: {
		'& > div > ul': {
			border: '0',
			padding: '5px 0',
			margin: '0',
			boxShadow: 'none',
			minWidth: '100%',
			borderRadius: '4px',
			boxSizing: 'border-box',
			display: 'block',
			fontSize: '14px',
			textAlign: 'left',
			listStyle: 'none',
			backgroundColor: whiteColor,
			backgroundClip: 'padding-box',
		},
		'& $selectPaper $selectMenuItemSelectedMultiple': {
			backgroundColor: 'inherit',
		},
		'& > div + div': {
			maxHeight: '266px !important',
		},
	},
	selectMenuItem: {
		fontSize: '13px',
		padding: '10px 20px',
		margin: '0 5px',
		borderRadius: '2px',
		transition: 'all 150ms linear',
		display: 'block',
		clear: 'both',
		fontWeight: '400',
		lineHeight: '2',
		whiteSpace: 'nowrap',
		color: grayColor[7],
		paddingRight: '30px',
		'&:hover': {
			backgroundColor: primaryColor[0],
			color: whiteColor,
			...primaryBoxShadow,
		},
	},
	selectMenuItemSelected: {
		backgroundColor: primaryColor[0] + '!important',
		color: whiteColor,
	},
	GridRow: {
		margin: '15px',
	},
	DataTitle: {
		padding: '15px !important',
		display: 'flex',
		justifyContent: 'center',
	},
	confirmBtnCssClass: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
	selectMenuItemSelectedMultiple: {
		backgroundColor: 'transparent !important',
		'&:hover': {
			backgroundColor: primaryColor[0] + '!important',
			color: whiteColor,
			...primaryBoxShadow,
			'&:after': {
				color: whiteColor,
			},
		},
		'&:after': {
			top: '16px',
			right: '12px',
			width: '12px',
			height: '5px',
			borderLeft: '2px solid currentColor',
			transform: 'rotate(-45deg)',
			opacity: '1',
			color: grayColor[2],
			position: 'absolute',
			content: "''",
			borderBottom: '2px solid currentColor',
			transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)',
		},
	},
	selectPaper: {
		boxSizing: 'borderBox',
		borderRadius: '4px',
		padding: '0',
		minWidth: '100%',
		display: 'block',
		border: '0',
		boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
		backgroundClip: 'padding-box',
		margin: '2px 0 0',
		fontSize: '14px',
		textAlign: 'left',
		listStyle: 'none',
		backgroundColor: 'transparent',
		maxHeight: '266px',
	},
	...modalStyle(theme),
})

export default styles
