import { useEffect, useRef, useState } from 'react'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	makeStyles,
	IconButton,
	CircularProgress,
	DialogActions,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Close, Send } from '@material-ui/icons'
import Button from '../../CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { ReportGmailerrorred } from '@mui/icons-material'
import CustomInput from 'components/CustomInput/CustomInput'
import Loader from 'components/Loader/Loader'
import NoteCard from './components/NoteCard'
import { profileNotesMapper } from 'shared/mappers/profile-notes-mapper'
import { PROFILE_GET_NOTES_RESET } from 'local_redux/constants/userConstants'
import { getProfileNotesById, registerProfileNote } from 'local_redux/actions/userActions'
import { USER_GET_NOTES_RESET } from 'local_redux/constants/userConstants'
import { getUserNotesById } from 'local_redux/actions/userActions'
import { registerUserNote } from 'local_redux/actions/userActions'
import { INVESTS_GET_NOTES_RESET } from 'local_redux/constants/investmentConstants'
import { getInvestNotesById } from 'local_redux/actions/investmentActions'
import { registerInvestNote } from 'local_redux/actions/investmentActions'
import styles from './styles/userNotesModalStyles'

const useStyles = makeStyles(styles)

const NotesModal = ({ open, handleCloseModal, modalTitle, type }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const notesContainer = useRef()

	const { userInfo } = useSelector((state) => state.userLogin)
	const {
		loadingNotes,
		loadingAdd,
		currentNotes = [],
		error,
		successAdded,
	} = useSelector((state) => state[`${type}Notes`]) // please note, the type prop, is a part of the name of any "Notes" reducer

	const [data, setData] = useState([])
	const [noteBody, setNoteBody] = useState('')

	const modalTypesInterface = {
		profile: {
			getAction: getProfileNotesById,
			reset: PROFILE_GET_NOTES_RESET,
			mapper: profileNotesMapper,
			postAction: registerProfileNote,
		},
		user: {
			getAction: getUserNotesById,
			mapper: profileNotesMapper,
			reset: USER_GET_NOTES_RESET,
			postAction: registerUserNote,
		},
		invest: {
			getAction: getInvestNotesById,
			mapper: profileNotesMapper,
			reset: INVESTS_GET_NOTES_RESET,
			postAction: registerInvestNote,
		},
	}

	useEffect(() => {
		if (notesContainer.current) {
			const element = notesContainer.current
			element.lastElementChild.scrollIntoView({
				block: 'end',
				behavior: 'smooth',
			})
		}
	}, [data])

	useEffect(() => {
		setData([])
		if (currentNotes.length) {
			setData(modalTypesInterface[type].mapper([...currentNotes]))
		}
	}, [currentNotes])

	useEffect(() => {
		dispatch(modalTypesInterface[type].getAction(open?._id))

		return () => {
			if (modalTypesInterface[type].reset) dispatch({ type: modalTypesInterface[type].reset })
		}
	}, [])

	const handleCreateNote = async () => {
		if (noteBody.length) {
			await dispatch(
				modalTypesInterface[type].postAction({
					owner: open?._id,
					sender: userInfo?._id,
					body: noteBody,
				}),
			)

			setNoteBody('')
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted
			onClose={handleCloseModal}
			aria-labelledby='modal-title'
			aria-describedby='modal-description'
		>
			<DialogTitle id='modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>{modalTitle}</h4>
			</DialogTitle>
			<DialogContent id='modal-description' className={classes.modalBody}>
				<GridContainer>
					{loadingNotes && (
						<GridItem xs={12} className={classes.loader}>
							<Loader />
						</GridItem>
					)}
					{!loadingNotes && (
						<GridItem xs={12} className={classes.notesContainer}>
							{data.length === 0 && (
								<GridContainer className={classes.gridSearch}>
									<GridItem xs={12} md={12} className={classes.iconSearch}>
										<ReportGmailerrorred />
									</GridItem>
									<GridItem xs={12} md={12}>
										<p>No se encontraron notas</p>
									</GridItem>
								</GridContainer>
							)}
							{data.length > 0 && (
								<GridContainer>
									{data.map((n) => (
										<NoteCard key={n.id} note={n} />
									))}
								</GridContainer>
							)}
						</GridItem>
					)}
					{error && <Typography className={classes.error}>{error}</Typography>}
					{successAdded && <Typography className={classes.success}>Se ha añadido la nota!</Typography>}
					{!loadingNotes && (
						<GridItem xs={12} className={classes.inputContainer}>
							<GridItem xs={11} className={classes.inputGrid}>
								<CustomInput
									id='note-input'
									helperText='Presiona "Enter" para guardar comentario (opcional)'
									formControlProps={{
										fullWidth: true,
									}}
									noPaddingTop
									inputProps={{
										value: noteBody,
										onChange: (e) => {
											setNoteBody(e.target.value)
										},
										onKeyPress: (e) => {
											if (e.key === 'Enter') {
												handleCreateNote()
											}
										},
										placeholder: 'Escribe una nota...',
										type: 'text',
										required: true,
										autoFocus: true,
									}}
								/>
							</GridItem>
							<GridItem xs={1} className={classes.buttonContainer}>
								<IconButton onClick={handleCreateNote}>
									{loadingAdd ? <CircularProgress size={'20px'} style={{ color: '#333333' }} /> : <Send />}
								</IconButton>
							</GridItem>
						</GridItem>
					)}
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridItem xs={12}>
					<Button onClick={handleCloseModal}>Cerrar</Button>
				</GridItem>
			</DialogActions>
		</Dialog>
	)
}

export default NotesModal
