import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	...customSelectStyle,
	pagPosition: {
		position: 'sticky',
		top: '0',
		left: '0',
	},
	formControlMargins: {
		margin: '3px 0 !important',
	},
	gridContainer: {
		justifyContent: 'center',
	},
	hoverCell: {
		'&:hover': {
			color: 'black',
			fontWeight: '500',
		},
	},
	widthSmall: {
		minWidth: '100px !important',
	},
	widthMedium: {
		minWidth: '135px !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	widthSmall: {
		minWidth: '100px !important',
	},
	widthMedium: {
		minWidth: '135px !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	btnExport: {
		marginBottom: '10px',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	rootModal: {
		overflow: 'hidden',
		justifyContent: 'flex-end',
		padding: '0px 20px',
	},
	tableStyles: {
		display: 'flex',
		'& thead': {
			'&::-webkit-scrollbar': {
				width: '0px !important',
				height: '0px !important',
			},
			'&::-webkit-scrollbar-track': {
				background: '#e9e9e9',
				borderRadius: '8px',
			},
			'&::-webkit-scrollbar-thumb': {
				background: '#c3c0b2',
				borderRadius: '8px',
			},
			'& tr': {
				backgroundColor: '#fff',
				'& th': {
					margin: '0 !important',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'flex-start',
					flexDirection: 'column',
					'&:nth-child(1)': {
						zIndex: '10',
						position: 'sticky !important',
						width: '5em',
						left: '0',
						top: 'auto',
						borderTopWidth: '1px',
						backgroundColor: '#fff',
					},
					'& div': {
						width: '100%',
					},
				},
			},
		},
		'& tbody': {
			overflow: 'inherit !important',
			'& tr': {
				'&:hover': {
					'& td:nth-child(1)': { backgroundColor: '#f2f2f2' },
					'& td:nth-child(n+2)': { backgroundColor: 'transparent' },
				},
				'&:nth-child(odd)': {
					'& td': { backgroundColor: '#f2f1eb' },
				},
				'&:nth-child(even)': {
					'& td': { backgroundColor: '#fff' },
				},
				'& td:nth-child(1)': {
					zIndex: '10',
					position: 'sticky !important',
					width: '5em',
					left: '0',
					top: 'auto',
					borderTopWidth: '1px',
				},
			},
		},
	},
	overflowX: {
		overflowX: 'auto',
		'&::-webkit-scrollbar': {
			width: '5px',
			height: '5px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#e9e9e9',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#c3c0b2',
			borderRadius: '8px',
		},
	},
	btnModal: {
		marginRight: '10px',
	},
	selectMenuItem: {
		color: '#3C4858',
		// padding: 12px 0 7px;
		fontSize: '.75rem',
		// font-weight: 400;
		// line-height: 1.42857;
		// letter-spacing: 0;
		// text-transform: uppercase;
		// text-decoration: none;
	},
	areaField: {
		width: '100%',
		margin: '0px 0px 9px 0px',
		'& input': {
			'&,&::placeholder': {
				color: grayColor[1],
				opacity: '1',
				fontSize: '14px !important',
			},
			'&::placeholder': {
				color: grayColor[0],
				fontSize: '14px !important',
			},
		},
		paddingTop: '22px',
		'& label': {
			color: '#AAAAAA !important',
			fontSize: '14px',
		},
		'& textarea': {
			fontSize: '14px',
		},
		'& div': {
			'&:before': {
				borderColor: '#D2D2D2 !important',
				borderWidth: '1px !important',
			},
			'&:after': {
				borderColor: '#d1cdc2',
			},
		},
	},
	datePicker: {
		width: '100%',
		'& label': {
			color: '#AAAAAA !important',
			fontSize: '14px',
		},
		'& div': {
			'&:before': {
				borderColor: '#D2D2D2 !important',
				borderWidth: '1px !important',
			},
			'&:after': {
				borderColor: '#d1cdc2',
			},
		},
	},

	...modalStyle(theme),
})

export default styles
