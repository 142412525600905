import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, FormControl } from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Loader from 'components/Loader/Loader'
import { INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET } from 'local_redux/constants/investmentConstants'
import { getInvestorsProfileList } from 'local_redux/actions/investorProfileActions'
import { INVESTORS_PROFILE_LIST_RESET } from 'local_redux/constants/investorProfileConstants'
import styles from './styles/selectProfilesByAdminStyles'

const useStyles = makeStyles(styles)

const SelectProfilesByAdmin = ({ infoModalData, setModalData }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingInvestorsProfileList, investorsProfileListData } = useSelector((state) => state.investorsProfileList)

	useEffect(() => {
		dispatch(getInvestorsProfileList())
	}, [])

	useEffect(() => {
		return () => dispatch({ type: INVESTORS_PROFILE_LIST_RESET })
	}, [dispatch])

	return (
		<>
			<GridItem xs={12}>
				{!infoModalData.profile._id && (
					<Typography variant='body1' className={classes.title}>
						{!infoModalData.projectId && 'Seleccione el perfil con el que quieres invertir'}
					</Typography>
				)}
			</GridItem>
			{loadingInvestorsProfileList ? (
				<GridContainer>
					<GridItem xs={12}>
						<Loader message={'cargando perfiles'} config={{ marginTop: '30px', alignItems: 'center' }} />
					</GridItem>
				</GridContainer>
			) : (
				investorsProfileListData && (
					<GridItem xs={12} style={{ marginBottom: '40px' }}>
						<FormControl fullWidth>
							<Autocomplete
								disablePortal
								id='autocomplete-profiles'
								options={investorsProfileListData.map(({ _id, businessName, canNotInvest }) => ({
									_id,
									label: businessName,
									canNotInvest,
								}))}
								value={investorsProfileListData.find((d) => d._id === infoModalData.profile._id)?.businessName ?? ''}
								color='primary'
								onChange={(e, newValue) => {
									dispatch({ type: INVESTMENTS_BY_PROFILE_AND_PROJECT_RESET })
									setModalData({ ...infoModalData, profile: newValue })
								}}
								className={classes.autoCompleteFormControl}
								renderInput={(params) => (
									<TextField
										id='select-profiles-by-admin'
										name='select-profiles-by-admin'
										label='Perfil Inversor *'
										variant='standard'
										size='small'
										placeholder='Seleccione un perfil'
										{...params}
									/>
								)}
							/>
						</FormControl>
					</GridItem>
				)
			)}
		</>
	)
}

export default SelectProfilesByAdmin
