const ADMIN_ROLE = 'administrator'
const CONTABILIDAD_ROLE = 'isContabilidad'
const EDITOR_ROLE = 'editor'
const INVESTOR_RELATIONS_ROLE = 'isInvestorRelations'
const INVESTOR_ROLE = 'investor'
const MANAGEMENT_ROLE = 'isManagement'
const MARKETING_ROLE = 'isMarketing'
const SUPER_ROLE = 'superadministrator'

const ALL_USER_ROLES = [
  ADMIN_ROLE,
  CONTABILIDAD_ROLE,
  EDITOR_ROLE,
  INVESTOR_RELATIONS_ROLE,
  INVESTOR_ROLE,
  MANAGEMENT_ROLE,
  MARKETING_ROLE,
  SUPER_ROLE,
]

export default {
  ADMIN_ROLE,
  ALL_USER_ROLES,
  CONTABILIDAD_ROLE,
  EDITOR_ROLE,
  INVESTOR_RELATIONS_ROLE,
  INVESTOR_ROLE,
  MANAGEMENT_ROLE,
  MARKETING_ROLE,
  SUPER_ROLE,
}
