import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from '../CustomButtons/Button'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import { getInvestmentById } from 'local_redux/actions/investmentActions'
import { INVESTMENT_BY_ID_RESET } from 'local_redux/constants/investmentConstants'
import styles from './styles/detailInvestmentAdminModalStyles'

const useStyles = makeStyles(styles)

const DetailInvestmentAdminModal = ({
	handleCloseDeclineAdminModal,
	detailInvestmentAdminModal,
	showDetailInvestmentAdminInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingInvestmentById, investmentByIdData } = useSelector((state) => state.investmentById)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		dispatch(getInvestmentById(showDetailInvestmentAdminInfo._id))
	}, [])

	useEffect(() => {
		return () => dispatch({ type: INVESTMENT_BY_ID_RESET })
	}, [dispatch])

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={detailInvestmentAdminModal}
			keepMounted
			onClose={handleCloseDeclineAdminModal}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseDeclineAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>

				<h4 className={classes.modalTitle}>Detalle de Inversión</h4>
			</DialogTitle>

			<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
				{loadingInvestmentById ? (
					<>Cargando inversión</>
				) : (
					investmentByIdData && (
						<GridContainer>
							<GridItem xs={12}>
								<Typography className={classes.firstSubtitle} variant='subtitle1'>
									Datos de la inversión
								</Typography>
							</GridItem>
							<GridItem xs={12}>
								<div style={{ boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.15)', padding: '10px' }}>
									<GridContainer>
										<GridItem xs={12} sm={6}>
											<Typography variant='body1'>Importe</Typography>
											<span className={classes.maxInvertibleNum}>{investmentByIdData.amount}</span>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<Typography variant='body1'>Fecha de Inversión</Typography>
											<span className={classes.maxInvertibleNum}>
												{new Date(investmentByIdData.investmentDate).toLocaleDateString()}
											</span>
										</GridItem>
										<GridItem xs={12}>
											<Typography variant='body1'>Importe en Letras</Typography>
											<span className={classes.maxInvertibleNum}>{investmentByIdData.amountInWords}</span>
										</GridItem>
									</GridContainer>
								</div>
							</GridItem>
							<GridItem xs={12}>
								<Typography className={classes.subtitle} variant='subtitle1'>
									Datos del Proyecto
								</Typography>
							</GridItem>
							<GridItem xs={12}>
								<div style={{ boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.15)', padding: '10px' }}>
									<GridContainer>
										<GridItem xs={12} sm={6}>
											<img
												// src={`${axios.defaults.baseURL}/${investmentByIdData?.project?.coverImage}`}
												src={getUrlToOpenFile(investmentByIdData?.project?.coverImage, userInfo)}
												alt={`${investmentByIdData?.project?.coverImage}`}
												width='100%'
											/>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<GridContainer>
												<GridItem xs={12} sm={6}>
													<Typography variant='body1'>Máx Invertibe </Typography>
													<span className={classes.maxInvertibleNum}>{investmentByIdData?.project?.maxInvertible}</span>
												</GridItem>
												<GridItem xs={12} sm={6}>
													<Typography variant='body1'>Inversión total </Typography>
													<span className={classes.maxInvertibleNum}>{investmentByIdData?.project?.projectCost}</span>
												</GridItem>
												<GridItem xs={12}>
													<Typography variant='body1'>Descripción</Typography>
													<span className={classes.maxInvertibleNum}> {investmentByIdData?.project?.description}</span>
												</GridItem>
												<GridItem xs={12}>
													<Typography variant='body1'>Dirección</Typography>
													<span className={classes.maxInvertibleNum}>
														{investmentByIdData?.project?.projectAddress}
													</span>
												</GridItem>
											</GridContainer>
										</GridItem>
									</GridContainer>
								</div>
							</GridItem>
							<GridItem xs={12}>
								<Typography className={classes.subtitle} variant='subtitle1'>
									Datos del Perfil Inversor
								</Typography>
							</GridItem>
							<GridItem xs={12}>
								<div style={{ boxShadow: '-1px 1px 5px 0px rgba(0,0,0,0.15)', padding: '10px' }}>
									<GridContainer>
										<GridItem xs={12} sm={6}>
											<Typography variant='body1'>Nombre</Typography>
											<span className={classes.maxInvertibleNum}>
												{investmentByIdData?.investorProfile?.businessName}
											</span>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<Typography variant='body1'>Email</Typography>
											<span className={classes.maxInvertibleNum}>{investmentByIdData?.investorProfile?.email}</span>
										</GridItem>
										<GridItem xs={12} sm={6}>
											<Typography variant='body1'>Teléfono</Typography>
											<span className={classes.maxInvertibleNum}>{investmentByIdData?.investorProfile?.phone}</span>
										</GridItem>
									</GridContainer>
								</div>
							</GridItem>
						</GridContainer>
					)
				)}
			</DialogContent>
		</Dialog>
	)
}

export default DetailInvestmentAdminModal
