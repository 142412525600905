import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	FormControl,
	Select as Selectable,
	MenuItem,
	InputLabel,
	makeStyles,
	FormControlLabel,
	Switch,
} from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import Retention from 'components/Retention/Retention'
import Button from 'components/CustomButtons/Button'
import PrescriptorsSelect from 'components/PrescriptorsSelect/PrescriptorsSelect'
import PrescriberConfirmModal from './prescriberConfirmModal'
import { matchGender } from 'shared/matchData/matchData'
import { ibanValidator } from 'shared/helpers/functions-helpers'
import { countriesList } from 'shared/helpers/nationality-helpers'
import styles from '../styles/updateProfileByAdminStyles'
import { Autocomplete, TextField, InputLabel as InputLabelNew } from '@mui/material'

const useStyles = makeStyles(styles)

const InputsPhysicalPersonUpdateByAdmin = ({
	userInfo,
	setUserInfo,
	handleChangeFile,
	fileInput,
	handleSelectFile,
	setErrorTaxResidenceFile,
}) => {
	const classes = useStyles()

	const { retentionListData } = useSelector((state) => state.retentionList)

	const [showConfirmModal, setShowConfirmModal] = useState(false)

	useEffect(() => {
		if (userInfo) {
			const data = {
				...userInfo,
				retention: userInfo.country
					? `${retentionListData?.find((item) => item.country === userInfo.country)?.retention}%`
					: userInfo.retention
					? userInfo.retention
					: '19%',
			}

			setUserInfo(data)
		}
	}, [retentionListData, userInfo.country])

	const handleProfileType = (e) => {
		if (e.target.checked) {
			setShowConfirmModal(true)
		} else {
			setUserInfo({ ...userInfo, isPrescriber: e.target.checked })
		}
	}
	const handleIsShareholder = (e) => {
		setUserInfo({ ...userInfo, isShareholder: e.target.checked })
	}

	const handlePrescriberConfirm = () => {
		setUserInfo({ ...userInfo, isPrescriber: true })
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Nombre *'}
						id='investor-firstname'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.name,
							onChange: (e) => {
								setUserInfo({ ...userInfo, name: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Apellidos *'}
						id='investor-lastname'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.lastName,
							onChange: (e) => {
								setUserInfo({ ...userInfo, lastName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'DNI/NIE *'}
						id='investor-identification'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.nif,
							onChange: (e) => {
								setUserInfo({ ...userInfo, nif: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Teléfono *'
						id='investor-phone'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.phone,
							onChange: (e) => {
								setUserInfo({ ...userInfo, phone: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Email *'
						id='investor-email'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.email,
							onChange: (e) => {
								setUserInfo({ ...userInfo, email: e.target.value })
							},
							type: 'email',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Provincia *'
						id='investor-province'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.province || '',
							onChange: (e) => {
								setUserInfo({ ...userInfo, province: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Localidad *'
						id='investor-location'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.location || '',
							onChange: (e) => {
								setUserInfo({ ...userInfo, location: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Código postal *'
						id='investor-postalCode'
						error={
							(userInfo.postalCode && userInfo.postalCode.length < 4) ||
							(userInfo.postalCode && userInfo.postalCode.length > 5)
						}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.postalCode || '',
							onChange: (e) => {
								setUserInfo({ ...userInfo, postalCode: e.target.value })
							},
							type: 'number',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText={'Dirección *'}
						id='investor-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.address,
							onChange: (e) => {
								setUserInfo({ ...userInfo, address: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<Retention setInfo={setUserInfo} info={userInfo} setErrorTaxResidenceFile={setErrorTaxResidenceFile} />
				<GridItem xs={12} sm={2}>
					<CustomInput
						labelText={'Retención *'}
						id='investor-retention'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.retention,
							onChange: (e) => {
								setUserInfo({ ...userInfo, retention: e.target.value })
							},
							type: 'text',
							disabled: true,

							required: true,
						}}
					/>
				</GridItem>
				<GridItem
					xs={12}
					sm={5}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'bottom',
						flexDirextion: 'column',
					}}
				>
					<div className='fileinput'>
						<input
							type='file'
							name='residenceCertificate'
							accept='.pdf,'
							id='residenceCertificate'
							onChange={handleChangeFile}
							ref={fileInput}
						/>
					</div>

					<Button
						style={{ marginTop: '1.3rem' }}
						disabled={userInfo.residenceCertificate ? true : false}
						size='sm'
						block
						onClick={() => handleSelectFile()}
					>
						Certificado de Residencia Fiscal
					</Button>
				</GridItem>
				<GridItem xs={12}>
					<GridContainer>
						<GridItem xs={4}>
							<CustomInput
								labelText={'IBAN'}
								id='investor-iban'
								//helperText='Formato ES9999999999999999999999'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: userInfo.bankAccountNumber,
									onChange: (e) => {
										setUserInfo({ ...userInfo, bankAccountNumber: e.target.value })
									},
									type: 'text',
									required: true,
								}}
							/>
						</GridItem>
						<GridItem xs={4}>
							<CustomInput
								labelText={ibanValidator(userInfo.bankAccountNumber) ? 'SWIFT*' : 'SWIFT'}
								id='investor-swift'
								//helperText='Formato ES9999999999999999999999'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: userInfo.swiftCode,
									onChange: (e) => {
										setUserInfo({ ...userInfo, swiftCode: e.target.value })
									},
									type: 'text',
									required: ibanValidator(userInfo.bankAccountNumber),
								}}
							/>
						</GridItem>
						<GridItem xs={4}>
							<CustomInput
								labelText={ibanValidator(userInfo.bankAccountNumber) ? 'BIC' : 'BIC'}
								id='investor-bic'
								//helperText='Formato ES9999999999999999999999'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: userInfo.bicCode,
									onChange: (e) => {
										setUserInfo({ ...userInfo, bicCode: e.target.value })
									},
									type: 'text',
								}}
							/>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl variant='standard' style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabel id='investor-liquidated-label' sx={{ minWidth: '100%' }}>
							Liquidación
						</InputLabel>
						<Selectable
							labelId='investor-liquidated-label'
							id='investor-liquidated'
							value={userInfo.liquidation ? userInfo.liquidation : 'pay'}
							onChange={(e) => {
								setUserInfo({ ...userInfo, liquidation: e.target.value })
							}}
							label='liquidated'
							sx={{ minWidth: '100%' }}
						>
							<MenuItem value={'pay'}>Pagar</MenuItem>
							<MenuItem value={'reinvest'}>Reinvertir</MenuItem>
							<MenuItem value={'benefits-only'}>Pagar solo beneficios</MenuItem>
						</Selectable>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'País donde reside *'}
						id='fiscal-nationality'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: userInfo.fiscalNationality,
							onChange: (e) => {
								setUserInfo({ ...userInfo, fiscalNationality: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabelNew htmlFor='update-gender' style={{ fontSize: '13px', marginBottom: '3px' }}>
							Nacionalidad *
						</InputLabelNew>
						<Autocomplete
							ListboxProps={{
								className: classes.scrollbar,
							}}
							className={classes.autoComplete}
							disablePortal
							options={countriesList.map((item) => item.name.toUpperCase())}
							value={userInfo?.nationality}
							onChange={(e, value) => {
								setUserInfo({ ...userInfo, nationality: value?.toLowerCase() })
							}}
							noOptionsText={<span>{'Sin resultados'}</span>}
							getOptionLabel={(e) => e}
							renderInput={(params) => (
								<TextField {...params} placeholder='Seleccione un país' variant='standard' required={true} />
							)}
						/>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6} className={classes.gridPrescriber}>
					<PrescriptorsSelect userInfo={userInfo} setUserInfo={setUserInfo} />
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabel htmlFor='update-gender'>Género *</InputLabel>
						<Selectable
							value={matchGender(userInfo.gender, true)}
							onChange={(e) => setUserInfo({ ...userInfo, gender: e.target.value })}
							inputProps={{
								name: 'update-gender',
								id: 'update-gender',
							}}
						>
							<MenuItem disabled>Selecciona una Opción</MenuItem>
							{['Mujer', 'Hombre'].map((entityType, index) => (
								<MenuItem value={entityType} key={index}>
									{entityType}
								</MenuItem>
							))}
						</Selectable>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6} md={2} style={{ margin: '20px 0' }}>
					<FormControlLabel
						control={
							<Switch
								checked={userInfo.isPrescriber || false}
								onChange={handleProfileType}
								value='is Prescriber'
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label='Prescriptor'
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2} style={{ margin: '20px 0' }}>
					<FormControlLabel
						control={
							<Switch
								checked={userInfo.isShareholder || false}
								onChange={handleIsShareholder}
								value='is Shareholder'
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label='Accionista'
					/>
				</GridItem>
				<GridItem xs={12} sm={6} md={2} style={{ margin: '20px 0' }}>
					<FormControlLabel
						control={
							<Switch
								checked={!userInfo.canNotInvest}
								onChange={(e) => {
									setUserInfo((prev) => ({ ...prev, canNotInvest: !e.target.checked }))
								}}
								value='can not invest'
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
								}}
							/>
						}
						classes={{
							label: classes.label,
						}}
						label='Puede Invertir'
					/>
				</GridItem>
			</GridContainer>
			{showConfirmModal && (
				<PrescriberConfirmModal
					open={showConfirmModal}
					handleCloseModal={() => setShowConfirmModal(false)}
					handleSubmit={handlePrescriberConfirm}
					isLoading={false}
					user={userInfo}
					setUserInfo={(e) => setUserInfo(e)}
				/>
			)}
		</>
	)
}

export default InputsPhysicalPersonUpdateByAdmin
