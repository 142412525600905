const styles = {
	autocomplete: {
		'& li:hover': {
			backgroundColor: '#d4d4d4',
		},
	},
	suggestions: {
		boxShadow: '0px 16px 30px -18px #00000094',
		maxHeight: '250px',
		overflowY: 'auto',
		position: 'absolute',
		zIndex: '1000',
		listStyle: 'none',
		margin: '-11px 0 0 0',
		padding: '0px',
		backgroundColor: '#f7f7f7',
		borderRadius: '0 0 10px 10px',
		'& li': {
			listStyle: 'none',
			padding: '10px',
			cursor: 'pointer',
		},
		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#999',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#928661',
			borderRadius: '8px',
		},
	},
}

export default styles
