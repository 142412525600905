import { useEffect, useState } from 'react'
import { format, differenceInDays, isFuture, isToday } from 'date-fns'
import { Typography, makeStyles } from '@material-ui/core'
import { styled } from '@mui/material/styles'
import { Box, LinearProgress, linearProgressClasses, Tooltip } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import styles from './styles/projectTimeProgressBarStyles'

const useStyles = makeStyles(styles)

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 7,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#c0bbac' : '#308fe8',
	},
}))

const ProjectTimeProgressBars = ({ startDate = new Date(), endDate = new Date() }) => {
	const classes = useStyles()

	const [progressValue, setProrgessValue] = useState(0)

	useEffect(() => {
		if (!isFuture(new Date(endDate)) && !isToday(new Date(endDate))) {
			setProrgessValue(99.99)
		} else if (isToday(new Date(endDate))) {
			setProrgessValue(99.99)
		} else {
			const daysTotal = differenceInDays(new Date(endDate), new Date(startDate))
			const daysPassed = differenceInDays(new Date(), new Date(startDate))
			setProrgessValue((daysPassed / daysTotal) * 100)
		}
	}, [])

	return (
		<Box sx={{ flexGrow: 1, height: 75 }}>
			<Typography className={classes.title}>
				<AccessTimeIcon /> Línea de tiempo del proyecto
			</Typography>
			<div>
				<BorderLinearProgress variant='determinate' value={progressValue} />
				<GridContainer className={classes.textContainer}>
					<GridItem>
						<Tooltip title='Fecha estimada de inicio' placement='bottom'>
							<Typography className={classes.smallText}>Inicio Est.</Typography>
							<Typography className={classes.smallText}>{format(new Date(startDate), 'dd/MM/yyyy')}</Typography>
						</Tooltip>
					</GridItem>
					<GridItem>
						<Tooltip title='Fecha estimada de finalización' placement='bottom'>
							<Typography className={classes.endDateTitle}>Fin Est.</Typography>
							<Typography className={classes.smallText}>{format(new Date(endDate), 'dd/MM/yyyy')}</Typography>
						</Tooltip>
					</GridItem>
				</GridContainer>
			</div>
		</Box>
	)
}

export default ProjectTimeProgressBars
