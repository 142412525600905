import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import {
	ClickAwayListener,
	MenuItem,
	MenuList,
	Paper,
	Grow,
	Hidden,
	Popper,
	Divider,
	makeStyles,
} from '@material-ui/core'
import { Person } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Notifications from './components/Notifications'
import { logout } from 'local_redux/actions/userActions'
import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle'

const useStyles = makeStyles(styles)

const HeaderLinks = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [openNotification, setOpenNotification] = useState(null)
	const [openProfile, setOpenProfile] = useState(null)

	const handleClickNotification = (event) => {
		if (openNotification && openNotification.contains(event.target)) {
			setOpenNotification(null)
		} else {
			setOpenNotification(event.currentTarget)
		}
	}
	const handleCloseNotification = () => {
		setOpenNotification(null)
	}
	const handleClickProfile = (event) => {
		if (openProfile && openProfile.contains(event.target)) {
			setOpenProfile(null)
		} else {
			setOpenProfile(event.currentTarget)
		}
	}
	const handleCloseProfile = () => {
		setOpenProfile(null)
	}

	const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover)
	const managerClasses = classNames({
		[classes.managerClasses]: true,
	})

	return (
		<div className={managerClasses}>
			<Notifications
				classes={classes}
				openNotification={openNotification}
				handleClickNotification={handleClickNotification}
				handleCloseNotification={handleCloseNotification}
				dropdownItem={dropdownItem}
			/>
			<Button
				color='transparent'
				aria-label='Person'
				justIcon
				aria-owns={openProfile ? 'profile-menu-list' : null}
				aria-haspopup='true'
				onClick={handleClickProfile}
				className={classes.buttonLink}
				muiClasses={{
					label: '',
				}}
			>
				<Person className={classes.headerLinksSvg + ' ' + classes.links} />
				<Hidden mdUp implementation='css'>
					<span onClick={handleClickProfile} className={classes.linkText}>
						Mis Datos
					</span>
				</Hidden>
			</Button>
			<Popper
				open={Boolean(openProfile)}
				anchorEl={openProfile}
				transition
				disablePortal
				placement='bottom'
				className={classNames({
					[classes.popperClose]: !openProfile,
					[classes.popperResponsive]: true,
					[classes.popperNav]: true,
				})}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps} id='profile-menu-list' style={{ transformOrigin: '0 0 0' }}>
						<Paper className={classes.dropdown}>
							<ClickAwayListener onClickAway={handleCloseProfile}>
								<MenuList role='menu'>
									<NavLink to='/admin/user-page' className={classes.itemLink}>
										<MenuItem onClick={() => handleCloseProfile(null)} className={dropdownItem}>
											Mis Datos
										</MenuItem>
									</NavLink>
									<NavLink to='/admin/security' className={classes.itemLink}>
										<MenuItem className={dropdownItem}>Seguridad</MenuItem>
									</NavLink>
									<Divider light />
									<NavLink to='/' className={classes.itemLink}>
										<MenuItem onClick={() => dispatch(logout())} className={dropdownItem}>
											Salir
										</MenuItem>
									</NavLink>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}

export default HeaderLinks
