import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { deleteOwnerProfile } from 'local_redux/actions/ownersProfileActions'
import { USER_LIST_RESET } from 'local_redux/constants/userConstants'
import {
  OWNER_LIST_RESET,
  OWNER_PROFILE_DELETE_RESET,
  OWNER_PROFILE_BY_ID_RESET,
} from 'local_redux/constants/ownersProfileConstants'
import styles from '../../../screens/control-panel/InvestorUsersListScreen/styles/InvestorUserDeleteModalStyles'

const useStyles = makeStyles(styles)

const DeleteProfileInvestorModal = ({
  handleCloseDeleteProfileOwnerModal,
  deleteProfileOwnerModal,
  showDeleteProfileOwnerModalInfo,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { successOwnerProfileDelete, errorOwnerProfileDelete, loadingOwnerProfileDelete } = useSelector(
    (state) => state.ownerProfileDelete
  )

  useEffect(() => {
    let timer = ''
    if (successOwnerProfileDelete) {
      timer = setTimeout(() => {
        dispatch({ type: OWNER_PROFILE_BY_ID_RESET })
        dispatch({ type: USER_LIST_RESET })
        dispatch({ type: OWNER_LIST_RESET })
        dispatch({ type: OWNER_PROFILE_DELETE_RESET })
        handleCloseDeleteProfileOwnerModal()
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [successOwnerProfileDelete])

  const handleDeleteUser = (e) => {
    e.preventDefault()
    dispatch(deleteOwnerProfile(showDeleteProfileOwnerModalInfo))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteProfileOwnerModal}
      keepMounted
      onClose={handleCloseDeleteProfileOwnerModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <form onSubmit={handleDeleteUser}>
        <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseDeleteProfileOwnerModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>Eliminar Perfil Propietario</h4>
        </DialogTitle>

        <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Está seguro que quiere eliminar el perfil propietario{' '}
                <strong>{showDeleteProfileOwnerModalInfo?.businessName}</strong>?
              </Typography>
            </GridItem>
          </GridContainer>

          {errorOwnerProfileDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorOwnerProfileDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={6}>
              <Button onClick={handleCloseDeleteProfileOwnerModal} block>
                Cancelar
              </Button>
            </GridItem>
            <GridItem xs={6}>
              <Button type='onSubmit' color={successOwnerProfileDelete ? 'success' : 'primary'} block>
                {loadingOwnerProfileDelete ? 'Eliminando' : successOwnerProfileDelete ? 'Eliminado' : 'Eliminar'}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DeleteProfileInvestorModal
