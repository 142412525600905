const styles = {
  cardTitle: {
    padding: '0px 8px 8px',
  },
  listItem: {
    padding: '1px 16px 1px 16px !important',
  },
  listItemText: {
    '& span': {
      fontSize: '1rem',
      marginTop: '11px',
    },
  },
  divider: { marginTop: '-11px' },
}

export default styles
