import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles/cardBodyStyle'

const useStyles = makeStyles(styles)

export default function CardBody(props) {
	const classes = useStyles()
	const {
		background,
		borderBottom,
		borderTop,
		calendar,
		children,
		className,
		color,
		formHorizontal,
		plain,
		pricing,
		profile,
		signup,
		noPaddingY,
		noPaddingBottom,
		...rest
	} = props
	const cardBodyClasses = classNames({
		[classes.cardBody]: true,
		[classes.cardBodyBackground]: background,
		[classes.cardBodyPlain]: plain,
		[classes.cardBodyFormHorizontal]: formHorizontal,
		[classes.cardPricing]: pricing,
		[classes.cardSignup]: signup,
		[classes.cardBodyColor]: color,
		[classes.cardBodyProfile]: profile,
		[classes.cardBodyCalendar]: calendar,
		[classes.noPaddingY]: noPaddingY,
		[classes.noPaddingBottom]: noPaddingBottom,
		[classes.borderTop]: borderTop,
		[classes.borderBottom]: borderBottom,
		[className]: className !== undefined,
	})
	return (
		<div className={cardBodyClasses} {...rest}>
			{children}
		</div>
	)
}

CardBody.propTypes = {
	className: PropTypes.string,
	background: PropTypes.bool,
	plain: PropTypes.bool,
	formHorizontal: PropTypes.bool,
	pricing: PropTypes.bool,
	signup: PropTypes.bool,
	color: PropTypes.bool,
	profile: PropTypes.bool,
	calendar: PropTypes.bool,
	children: PropTypes.node,
	noPaddingY: PropTypes.bool,
	noPaddingBottom: PropTypes.bool,
	borderBottom: PropTypes.bool,
	borderTop: PropTypes.bool,
}
