import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from '../CustomButtons/Button'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from '../Snackbar/SnackbarContent'
import { updateInvestor } from 'local_redux/actions/investorAction'
import { INVESTOR_UPDATE_RESET } from 'local_redux/constants/investorConstant'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import styles from './styles/GeneratePasswordModalStyles'

const useStyles = makeStyles(styles)

const GeneratePasswordModal = ({
	handleCloseGenetatePasswordModal,
	genetatePasswordModal,
	showGeneratePasswordInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingInvestorUpdate, errorInvestorUpdate, succesInvestorUpdate } = useSelector(
		(state) => state.investorUpdate,
	)

	useEffect(() => {
		dispatch({ type: INVESTOR_UPDATE_RESET })
	}, [])

	useEffect(() => {
		let timer = ''
		if (succesInvestorUpdate) {
			timer = setTimeout(() => {
				dispatch({ type: INVESTOR_UPDATE_RESET })
				dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
				handleCloseGenetatePasswordModal()
			}, 2000)
		}
		return () => clearTimeout(timer)
	}, [succesInvestorUpdate])

	const handleGeneratePassword = (e) => {
		e.preventDefault()
		const userData = {
			_id: showGeneratePasswordInfo._id,
			generatePass: true,
		}

		dispatch(updateInvestor(userData))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={genetatePasswordModal}
			keepMounted
			onClose={handleCloseGenetatePasswordModal}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<form onSubmit={handleGeneratePassword}>
				<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						round
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='primary'
						onClick={handleCloseGenetatePasswordModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Generar Contraseña Aleatoria</h4>
				</DialogTitle>

				<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12} style={{ marginBottom: '20px' }}>
							<Typography>
								¿Esta seguro que desea generar una contraseña para el usuario{' '}
								<strong style={{ textTransform: 'capitalize' }}>
									{showGeneratePasswordInfo.name} {showGeneratePasswordInfo.lastName}
								</strong>
								?
							</Typography>
						</GridItem>
						<GridItem xs={12}>
							<Typography>
								Con esta acción el sistema generará una contraseña aleatoria y se le enviará al usuario al correo
								electronico <strong>{showGeneratePasswordInfo.email}</strong>.
							</Typography>
						</GridItem>
					</GridContainer>

					{errorInvestorUpdate && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorInvestorUpdate} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleCloseGenetatePasswordModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button
								type='submit'
								color={succesInvestorUpdate ? 'success' : 'primary'}
								block
								disabled={loadingInvestorUpdate}
							>
								{loadingInvestorUpdate ? 'Generando...' : succesInvestorUpdate ? 'Contraseña enviada' : 'Generar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default GeneratePasswordModal
