import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	noteRoot: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		marginBottom: '10px',
	},
	noteAvatar: {
		padding: '0 !important',
		paddingRight: '10px !important',
		paddingTop: '2.5px !important',
	},
	noteGridContainer: {
		padding: '0 !important',
		width: '90%',
	},
	noteContainer: {
		width: '100%',
		background: '#eeece2',
		borderRadius: '10px 10px 10px 0px',
		display: 'flex',
		flexDirection: 'column',
	},
	noteHeader: {
		fontSize: '10px !important',
		fontWeight: 'bold !important',
		padding: '5px 10px',
		width: '100%',
		// borderBottom: '1px solid #c0bbac',
	},
	noteBody: {
		width: '100%',
		fontSize: '14px !important',
		fontWeight: '100 !important',
		padding: '5px 10px',
		wordBreak: 'break-word',
	},
	noteFooter: {
		color: '#c0bbac',
		width: '100%',
		fontSize: '10px !important',
		fontWeight: '400 !important',
		padding: '0px 10px 3px 10px',
		textAlign: 'right',
	},
	noteTimestampPosition: {
		padding: '0 !important',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 10,
	},
	noteTimestampVisible: {
		color: '#7a7a7a',
		fontSize: '10px !important',
		fontWeight: '400 !important',
		padding: '0 10px 0 0',
		textAlign: 'right',
		opacity: 1,
		transition: 'all ease 500ms',
	},
	noteTimestampHide: {
		fontSize: '10px !important',
		fontWeight: '400 !important',
		padding: '0 10px 0 0',
		textAlign: 'right',
		opacity: 0,
		transition: 'all ease 500ms',
	},
	accordion: {
		background: 'transparent',
		padding: '0',
		width: '100%',
		boxShadow: 'none',
		padding: '0 !important',
		borderTop: 'none',
		'::before': {
			display: 'none',
		},
	},
	accordionNoPadding: {
		padding: '0 !important',
	},
	showMoreButton: {
		cursor: 'pointer',
	},
	...modalStyle(theme),
})

export default styles
