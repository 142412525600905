import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cx from 'classnames'
import { Drawer, Hidden, makeStyles } from '@material-ui/core'
import SidebarWrapper from './components/SidebarWrapper'
import Brand from './components/Brand'
import MiniBrand from './components/MiniBrand'
import UserDropdown from './components/UserDropdown'
import SidebarOptions from './components/SidebarOptions'
import { useWindowsSize } from 'shared/customHooks/useWindowsSize'
import styles from './styles/sidebarStyles'

const useStyles = makeStyles(styles)

const Sidebar = ({ miniActive, open, handleDrawerToggle }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const classes = useStyles()
	const windowsSize = useWindowsSize()
	const mainPanel = useRef()

	const [isMiniActive, setIsMiniActive] = useState(true)

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (!userInfo) {
			navigate('/')
		}
	}, [userInfo])

	useEffect(() => {
		if (windowsSize.width < 960 && open) {
			handleDrawerToggle(false)
		}
	}, [location.pathname])

	const isMini = miniActive && isMiniActive

	const drawerPaper = cx({
		[classes.drawerPaper]: true,
		[classes.whiteBackground]: true,
		[classes.drawerPaperMini]: isMini,
	})
	const sidebarWrapper = cx({
		[classes.sidebarWrapper]: true,
		[classes.drawerPaperMini]: isMini,
		[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
	})

	return (
		<div ref={mainPanel}>
			<Hidden mdUp implementation='css'>
				<Drawer
					variant='temporary'
					anchor={'right'}
					open={open}
					classes={{
						paper: drawerPaper,
					}}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					<Brand />
					{userInfo && (
						<SidebarWrapper
							className={sidebarWrapper}
							user={<UserDropdown userInfo={userInfo} miniActive={miniActive} isMiniActive={isMiniActive} />}
							links={
								userInfo.isDefaultPassword === false ? <SidebarOptions userInfo={userInfo} isMini={isMini} /> : null
							}
						/>
					)}
				</Drawer>
			</Hidden>
			<Hidden smDown implementation='css'>
				<Drawer
					onMouseOut={() => setIsMiniActive(true)}
					anchor={'left'}
					variant='permanent'
					open
					classes={{
						paper: drawerPaper,
					}}
				>
					{miniActive ? <MiniBrand /> : <Brand />}
					{userInfo && (
						<SidebarWrapper
							className={sidebarWrapper}
							user={<UserDropdown userInfo={userInfo} miniActive={miniActive} isMiniActive={isMiniActive} />}
							links={
								userInfo.isDefaultPassword === false ? <SidebarOptions userInfo={userInfo} isMini={isMini} /> : null
							}
						/>
					)}
				</Drawer>
			</Hidden>
		</div>
	)
}

Sidebar.propTypes = {
	logo: PropTypes.string,
	logoText: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	miniActive: PropTypes.bool,
	open: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
}

export default Sidebar
