const styles = {
  area: {
    width: '100%',
    marginTop: '13px',
    marginBottom: '20px',
    '& label': {
      color: '#AAAAAA !important',
      fontSize: '14px',
    },
    '& input': {
      fontSize: '14px',
      color: '#495057',
    },
    '& div': {
      '&:before': {
        borderColor: '#D2D2D2 !important',
        borderWidth: '1px !important',
      },
      '&:after': {
        borderColor: '#d1cdc2',
      },
    },
    '& .Mui-disabled': {
      color: '#000000de',
    },
  },
}

export default styles
