import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addDays, format } from 'date-fns'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Divider } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import FilterDownloadContracts from 'components/FilterDownloadContracts/FilterDownloadContracts'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import styles from './styles/DownloadInvestmentContractsModal'

const useStyles = makeStyles(styles)

const DownloadInvestmentContractsModal = ({
	openModal,
	closeModal,
	handleDownload,
	typeScreen,
	loadingContractsInfoToDownload,
	successContractsInfoToDownload,
	contractsInfoToDownload,
	errorContractsInfoToDownload,
	getContractsInfoToDownload,
	GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const [filterDate, setFilterDate] = useState({
		startDate: null,
		endDate: null,
	})
	const [filterByProject, setFilterByProject] = useState(null)
	const [filterByInvestor, setFilterByInvestor] = useState(null)
	const [contractsInfo, setContractsInfo] = useState(null)
	const [projectsList, setProjectsList] = useState([])
	const [newProjectState, setNewProjectState] = useState('')
	const [newInvestorState, setNewInvestorState] = useState('')
	const [projectListByInvestors, setProjectListByInvestors] = useState([])
	const [projectListByDate, setProjectListByDate] = useState([])

	useEffect(() => {
		return () => dispatch({ type: GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET })
	}, [])
	useEffect(() => {
		if (successContractsInfoToDownload) {
			if (!filterByInvestor && !filterByProject) return setProjectListByDate(contractsInfoToDownload)
			if (!filterByInvestor) return setProjectsList(contractsInfoToDownload)
			setProjectListByInvestors(contractsInfoToDownload)
		}
		if (errorContractsInfoToDownload) {
			setProjectsList([])
			setProjectListByDate([])
			setProjectListByInvestors([])
		}
	}, [successContractsInfoToDownload, errorContractsInfoToDownload])
	useEffect(() => {
		if (
			(filterDate.endDate === null || filterDate.startDate === null) &&
			filterByProject === null &&
			filterByInvestor === null
		)
			return
		let start = null
		let end = null
		if (filterDate.endDate !== null && filterDate.startDate !== null) {
			start = format(filterDate.startDate, 'yyyy-MM-dd')
			end = format(addDays(filterDate.endDate, 1), 'yyyy-MM-dd')
		}
		dispatch(
			getContractsInfoToDownload({
				date: { start, end },
				type: 'date',
				page: typeScreen,
				projectKey: filterByProject,
				investorKey: filterByInvestor,
			}),
		)
	}, [filterDate, filterByProject, filterByInvestor])

	const handleOnChangeProjectsFilter = (value) => {
		if (!value?.length) {
			setFilterByProject(null)
			setContractsInfo(null)
			setProjectsList([])
			return
		}
		setFilterByProject(value)
	}

	const handleOnChangeInvestorsFilter = (value) => {
		if (!value?.length) {
			setFilterByInvestor(null)
			setContractsInfo(null)
			setProjectListByInvestors([])
			return
		}
		setFilterByInvestor(value)
	}
	const handleSelectContract = (e) => {
		setContractsInfo(e.value)
	}
	const handleSelectContractByDate = (e) => {
		const projectSelected = projectListByDate.filter((item) => item.label === e.target.value)[0]
		setContractsInfo(projectSelected.value)
	}

	const handleDownloadContracts = () => {
		handleDownload({
			urls: contractsInfo.urls,
			ids: contractsInfo.ids,
			zipFileName: `${contractsInfo.projectName}`,
		})
		setContractsInfo(null)
		setFilterDate({
			startDate: null,
			endDate: null,
		})
		setProjectsList([])
		dispatch({ type: GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET })
	}
	const disabledDownloadButton = () => {
		return !contractsInfo || !contractsInfo?.urls?.length
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			keepMounted
			onClose={closeModal}
			aria-labelledby='download-investments-contracts-modal-title'
			aria-describedby='download-investments-contracts-modal-description'
		>
			<DialogTitle id='download-investments-contracts-modal-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={closeModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Descargar Contratos de inversiones</h4>
			</DialogTitle>
			<DialogContent id='download-investments-contracts-modal-description' className={classes.modalBody}>
				<GridItem xs={12}></GridItem>
				<FilterDownloadContracts
					setNewProjectState={setNewProjectState}
					newProjectState={newProjectState}
					setNewInvestorState={setNewInvestorState}
					newInvestorState={newInvestorState}
					projectsList={projectsList}
					handleOnChangeInvestorsFilter={handleOnChangeInvestorsFilter}
					loadingContractsInfoToDownload={loadingContractsInfoToDownload}
					handleSelectContract={handleSelectContract}
					dispatch={dispatch}
					filterDate={filterDate}
					setFilterDate={setFilterDate}
					investors={projectListByInvestors}
					setInvestors={setProjectListByInvestors}
					handleOnChangeProjectsFilter={handleOnChangeProjectsFilter}
					projectListByDate={projectListByDate}
					contractsInfo={contractsInfo}
					handleSelectContractByDate={handleSelectContractByDate}
				/>
				{Boolean(contractsInfo) && (
					<GridContainer>
						<GridItem xs={12} className={classes.DataTitle}>
							<strong>DATOS DE LA CONSULTA</strong>
							<Divider />
						</GridItem>
						<GridItem xs={12} className={classes.GridRow}>
							<GridContainer>
								<GridItem xs={12} sm={5}>
									<strong>{filterByInvestor ? 'Investor: ' : filterByProject ? 'Proyecto:' : 'Contratos de:'}</strong>
								</GridItem>
								<GridItem xs={12} sm={7}>
									{contractsInfo?.projectName}
								</GridItem>
							</GridContainer>
						</GridItem>
						<GridItem xs={12} className={classes.GridRow}>
							<GridContainer>
								<GridItem xs={12} sm={5}>
									<strong>Cantidad:</strong>
								</GridItem>
								<GridItem xs={12} sm={7}>
									{`${contractsInfo?.urls?.length} contrato${contractsInfo?.urls?.length > 1 ? 's' : ''}`}
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				)}
				{errorContractsInfoToDownload && (
					<GridItem xs={12}>
						<SnackbarContent message={errorContractsInfoToDownload} color='danger' />
					</GridItem>
				)}
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button color='primary' onClick={closeModal}>
							Cancelar
						</Button>
						<Button onClick={handleDownloadContracts} disabled={disabledDownloadButton()}>
							Descargar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default DownloadInvestmentContractsModal
