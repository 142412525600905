import { NumericFormat } from 'react-number-format'
import { makeStyles, TextField } from '@material-ui/core'
import classNames from 'classnames'
import styles from './styles/customCurrencyInputStyles'

const useStyles = makeStyles(styles)

const CustomCurrencyInput = ({ inputProps }) => {
	const classes = useStyles()

	return (
		<NumericFormat
			thousandSeparator='.'
			decimalSeparator=','
			decimalScale={2}
			customInput={TextField}
			prefix='€ '
			{...inputProps}
			className={classNames(classes.area, inputProps.className)}
		/>
	)
}

export default CustomCurrencyInput
